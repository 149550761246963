.card {
  font-size: .875rem;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: 'Lato', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: 'Lato', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  background-color: #fff;
  color: #000000 !important;
  overflow-x: hidden;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
a {
  color: #32a8df;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #32a8df;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: .5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}

.apexcharts-pan-icon,.apexcharts-reset-icon,.apexcharts-pan-icon,.apexcharts-zoom-icon,.apexcharts-zoomin-icon,.apexcharts-zoomout-icon {
    display: none !important;
}
.showPool_pair img {
  width: 42px;
  border-radius: 50%;
}
.showPool_pair span {
  margin-left: 10px;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " ("attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important;
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: 'Lato', sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: .5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
a {
  color: #32a8df;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #32a8df;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: .5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}
h1,
.h1 {
  font-size: 3.3125rem;
}
h2,
.h2 {
  font-size: 2.25rem;
}
h3,
.h3 {
  font-size: 1.5625rem;
}
h4,
.h4 {
  font-size: 1.125rem;
}
h5,
.h5 {
  font-size: 1.0625rem;
}
h6,
.h6 {
  font-size: 0.75rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 7rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 2.8125rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 2.125rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 80%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014 \00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fafafa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
code {
  font-size: 87.5%;
  color: #e91e63;
  word-break: break-word;
}
a>code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
  box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.25);
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
  box-shadow: none;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  order: -1;
}
.order-last {
  order: 13;
}
.order-0 {
  order: 0;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
}
.table tbody+tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.06);
}
.table .table {
  background-color: #fafafa;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: #e3c3e9;
}
.table-hover .table-primary:hover {
  background-color: #dab0e2;
}
.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: #dab0e2;
}
.table-secondary,
.table-secondary>th,
.table-secondary>td {
  background-color: #d6d8db;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: #c8cbcf;
}
.table-success,
.table-success>th,
.table-success>td {
  background-color: #cde9ce;
}
.table-hover .table-success:hover {
  background-color: #bbe1bd;
}
.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: #bbe1bd;
}
.table-info,
.table-info>th,
.table-info>td {
  background-color: #b8ecf3;
}
.table-hover .table-info:hover {
  background-color: #a2e6ef;
}
.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: #a2e6ef;
}
.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffe2b8;
}
.table-hover .table-warning:hover {
  background-color: #ffd89f;
}
.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: #ffd89f;
}
.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #fccac7;
}
.table-hover .table-danger:hover {
  background-color: #fbb3af;
}
.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: #fbb3af;
}
.table-light,
.table-light>th,
.table-light>td {
  background-color: #fdfdfe;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
  background-color: #ececf6;
}
.table-dark,
.table-dark>th,
.table-dark>td {
  background-color: #c6c8ca;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
  background-color: #b9bbbe;
}
.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fafafa;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(0, 0, 0, 0.06);
}
.table-dark {
  color: #fafafa;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md>.table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive>.table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.4375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
  border-color: #d176e1;
  outline: 0;
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:not([size]):not([multiple]) {
  height: calc(2.4375rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5625rem + 1px);
  padding-bottom: calc(0.5625rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.input-group-sm>.form-control-plaintext.form-control,
.input-group-sm>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-sm>.input-group-prepend>.form-control-plaintext.btn,
.input-group-sm>.input-group-append>.form-control-plaintext.btn,
.form-control-plaintext.form-control-lg,
.input-group-lg>.form-control-plaintext.form-control,
.input-group-lg>.input-group-prepend>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-append>.form-control-plaintext.input-group-text,
.input-group-lg>.input-group-prepend>.form-control-plaintext.btn,
.input-group-lg>.input-group-append>.form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm,
.input-group-sm>.form-control,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.input-group-sm>.input-group-append>.btn {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
select.form-control-sm:not([size]):not([multiple]),
.input-group-sm>select.form-control:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-sm>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-sm>.input-group-append>select.btn:not([size]):not([multiple]) {
  height: calc(2.125rem + 2px);
}
.form-control-lg,
.input-group-lg>.form-control,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.input-group-lg>.input-group-append>.btn {
  padding: 0.5625rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control-lg:not([size]):not([multiple]),
.input-group-lg>select.form-control:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.input-group-text:not([size]):not([multiple]),
.input-group-lg>.input-group-prepend>select.btn:not([size]):not([multiple]),
.input-group-lg>.input-group-append>select.btn:not([size]):not([multiple]) {
  height: calc(4.125rem + 2px);
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled~.form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4caf50;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: .2rem;
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #4caf50;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip,
.custom-select.is-valid~.valid-feedback,
.custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #4caf50;
}
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
  color: #4caf50;
}
.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
  background-color: #a3d7a5;
}
.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip,
.custom-control-input.is-valid~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #6ec071;
}
.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
  border-color: #4caf50;
}
.was-validated .custom-file-input:valid~.custom-file-label::before,
.custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip,
.custom-file-input.is-valid~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: .2rem;
}
.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #f44336;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip,
.custom-select.is-invalid~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip {
  display: block;
}
.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #f44336;
}
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
  color: #f44336;
}
.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
  background-color: #fbb4af;
}
.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip,
.custom-control-input.is-invalid~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
  background-color: #f77066;
}
.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
  border-color: #f44336;
}
.was-validated .custom-file-input:invalid~.custom-file-label::before,
.custom-file-input.is-invalid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip,
.custom-file-input.is-invalid~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.46875rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
  background-image: none;
  box-shadow: none;
}
.btn:not(:disabled):not(.disabled):active:focus,
.btn:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25), none;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #32a8df;
  border-color: #32a8df;
  box-shadow: none;
}
.btn-primary:hover {
  color: #fff;
  background-color: #802091;
  border-color: #771e86;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #32a8df;
  border-color: #32a8df;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #771e86;
  border-color: #6e1b7c;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: none;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: none;
}
.btn-success:hover {
  color: #fff;
  background-color: #409444;
  border-color: #3d8b40;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3d8b40;
  border-color: #39833c;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: none;
}
.btn-info:hover {
  color: #fff;
  background-color: #009aae;
  border-color: #008fa1;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #008fa1;
  border-color: #008394;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: none;
}
.btn-warning:hover {
  color: #fff;
  background-color: #d98100;
  border-color: #cc7a00;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #cc7a00;
  border-color: #bf7200;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: none;
}
.btn-danger:hover {
  color: #fff;
  background-color: #f22112;
  border-color: #ea1c0d;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ea1c0d;
  border-color: #de1b0c;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: none;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
  box-shadow: none;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-primary {
  color: #32a8df;
  background-color: transparent;
  background-image: none;
  border-color: #32a8df;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #32a8df;
  border-color: #32a8df;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #32a8df;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #32a8df;
  border-color: #32a8df;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  background-image: none;
  border-color: #4caf50;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #4caf50;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}
.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  background-image: none;
  border-color: #00bcd4;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #00bcd4;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.5);
}
.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  background-image: none;
  border-color: #ff9800;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff9800;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}
.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  background-image: none;
  border-color: #f44336;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #f44336;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #32a8df;
  background-color: transparent;
}
.btn-link:hover {
  color: #32a8df;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #999;
}
.btn-lg,
.btn-group-lg>.btn {
  padding: 1.125rem 2.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm,
.btn-group-sm>.btn {
  padding: 0.40625rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.1875rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block+.btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}
.collapse {
  display: none;
}
.collapse.show {
  display: block;
}
tr.collapse.show {
  display: table-row;
}
tbody.collapse.show {
  display: table-row-group;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
.dropup,
.dropdown {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.625rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #32a8df;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 0 1 auto;
}
.btn-group>.btn:hover,
.btn-group-vertical>.btn:hover {
  z-index: 1;
}
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}
.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn+.btn-group,
.btn-group-vertical .btn-group+.btn,
.btn-group-vertical .btn-group+.btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group>.btn:first-child {
  margin-left: 0;
}
.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after {
  margin-left: 0;
}
.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}
.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 1.6875rem;
  padding-left: 1.6875rem;
}
.btn-group.show .dropdown-toggle {
  box-shadow: none;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}
.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  margin-bottom: 0;
}
.btn-group-toggle>.btn input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}
.input-group>.form-control,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file:focus {
  z-index: 3;
}
.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
  margin-left: -1px;
}
.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group>.custom-file {
  display: flex;
  align-items: center;
}
.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group>.custom-file:not(:first-child) .custom-file-label,
.input-group>.custom-file:not(:first-child) .custom-file-label::before {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn+.btn,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-append .btn+.btn,
.input-group-append .btn+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group>.input-group-prepend>.btn,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  background-color: #32a8df;
  box-shadow: none;
}
.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-control-input:active~.custom-control-label::before {
  color: #fff;
  background-color: #dfa0ea;
  box-shadow: none;
}
.custom-control-input:disabled~.custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled~.custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  user-select: none;
  background-color: #dee2e6;
  box-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #32a8df;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  background-color: #32a8df;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background-color: #32a8df;
  border-color: #32a8df;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: rgba(156, 39, 176, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.4375rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #d2d2d2;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #d176e1;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(209, 118, 225, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: rgba(0, 0, 0, 0);
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(2.125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}
.custom-select-lg {
  height: calc(4.125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.4375rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.4375rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus~.custom-file-control {
  border-color: #d176e1;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.custom-file-input:focus~.custom-file-control::before {
  border-color: #d176e1;
}
.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.4375rem + 2px);
  padding: 0.46875rem 1rem;
  line-height: 1.3;
  color: #495057;
  background-color: transparent;
  border: 0 solid #d2d2d2;
  border-radius: 0;
  box-shadow: none;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(calc(2.4375rem + 2px) - 0 * 2);
  padding: 0.46875rem 1rem;
  line-height: 1.3;
  color: #495057;
  content: "Browse";
  background-color: transparent;
  border-left: 0 solid #d2d2d2;
  border-radius: 0 0 0 0;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fafafa;
  border-color: #dee2e6 #dee2e6 #fafafa;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #32a8df;
}
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.tab-content>.tab-pane {
  display: none;
}
.tab-content>.active {
  display: block;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar>.container,
.navbar>.container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm>.container,
  .navbar-expand-sm>.container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md>.container,
  .navbar-expand-md>.container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl>.container,
  .navbar-expand-xl>.container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand>.container,
.navbar-expand>.container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand>.container,
.navbar-expand>.container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}
.social_list {
  display: flex;
  flex-direction: row;
  padding: 0;
  justify-content: center;
}
.social_list li {
  width: auto;
}
.social_links_header {
  background-image: linear-gradient(to right, #fe1301 0%, #ff7713 100%);
  border-radius: 40px !important;
  box-sizing: border-box;
  color: #7d8082 !important;
  display: block;
  height: 45px;
  letter-spacing: 1px;
  margin: 0 auto;
  padding: 3px !important;
  text-decoration: none;
  width: 45px;
  z-index: 2;
  box-shadow: 0px 0px 1px 5px rgb(47 51 54 / 88%);
  font-size: 16px !important;
  /* margin-right: 25px; */
}
.social_links_header:hover {
	color: #fff;
}
.social_links_header i {
	align-items: center;
	background-image: linear-gradient(#2b2a2c, #4a5157);
	border-radius: 40px;
	display: flex;
	justify-content: center;
	height: 100%;
	transition: background .5s ease;
  width: 100%;
  text-shadow: 4px 6px 5px rgb(0 0 0 / 71%);
}
.social_links_header:hover i {
  background: transparent;
  color: #fff;
}
a.mobile_menu {
  color: #fff;
}
a.mobile_menu svg {
  vertical-align: middle;
  margin-right: 8px;
}
a.mobile_menu:hover {
  color: #ff7800;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
}
.card>hr {
  margin-right: 0;
  margin-left: 0;
}
.card>.list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card>.list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link+.card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header+.list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: flex;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: flex;
    flex: 1 0 0%;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: flex;
  flex-direction: column;
}
.card-group>.card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap;
  }
  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group>.card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group>.card:first-child .card-img-top,
  .card-group>.card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group>.card:first-child .card-img-bottom,
  .card-group>.card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group>.card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group>.card:last-child .card-img-top,
  .card-group>.card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group>.card:last-child .card-img-bottom,
  .card-group>.card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group>.card:only-child {
    border-radius: 0.25rem;
  }
  .card-group>.card:only-child .card-img-top,
  .card-group>.card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group>.card:only-child .card-img-bottom,
  .card-group>.card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group>.card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: 0;
  line-height: 1.25;
  color: #32a8df;
  background-color: transparent;
  border: 0 solid #dee2e6;
}
.page-link:hover {
  color: #32a8df;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(156, 39, 176, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #32a8df;
  border-color: #32a8df;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 500;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #51145c;
  background-color: #ebd4ef;
  border-color: #e3c3e9;
}
.alert-primary hr {
  border-top-color: #dab0e2;
}
.alert-primary .alert-link {
  color: #2c0b32;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #285b2a;
  background-color: #dbefdc;
  border-color: #cde9ce;
}
.alert-success hr {
  border-top-color: #bbe1bd;
}
.alert-success .alert-link {
  color: #18381a;
}
.alert-info {
  color: #00626e;
  background-color: #ccf2f6;
  border-color: #b8ecf3;
}
.alert-info hr {
  border-top-color: #a2e6ef;
}
.alert-info .alert-link {
  color: #00353b;
}
.alert-warning {
  color: #854f00;
  background-color: #ffeacc;
  border-color: #ffe2b8;
}
.alert-warning hr {
  border-top-color: #ffd89f;
}
.alert-warning .alert-link {
  color: #523100;
}
.alert-danger {
  color: #7f231c;
  background-color: #fdd9d7;
  border-color: #fccac7;
}
.alert-danger hr {
  border-top-color: #fbb3af;
}
.alert-danger .alert-link {
  color: #551713;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.1rem 0.1rem rgba(0, 0, 0, 0.1);
}
.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #32a8df;
  transition: width 0.6s ease;
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}
.media {
  display: flex;
  align-items: flex-start;
}
.media-body {
  flex: 1;
}
.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: inherit;
  border: 0 solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: inherit;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #32a8df;
  border-color: #32a8df;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}
.list-group-item-primary {
  color: #51145c;
  background-color: #e3c3e9;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #51145c;
  background-color: #dab0e2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #51145c;
  border-color: #51145c;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #285b2a;
  background-color: #cde9ce;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #285b2a;
  background-color: #bbe1bd;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #285b2a;
  border-color: #285b2a;
}
.list-group-item-info {
  color: #00626e;
  background-color: #b8ecf3;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #00626e;
  background-color: #a2e6ef;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #00626e;
  border-color: #00626e;
}
.list-group-item-warning {
  color: #854f00;
  background-color: #ffe2b8;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #854f00;
  background-color: #ffd89f;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #854f00;
  border-color: #854f00;
}
.list-group-item-danger {
  color: #7f231c;
  background-color: #fccac7;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #7f231c;
  background-color: #fbb3af;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7f231c;
  border-color: #7f231c;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  opacity: .75;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #32a8df;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #771e86;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #4caf50;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #3d8b40;
}
.badge-info {
  color: #fff;
  background-color: #00bcd4;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #008fa1;
}
.badge-warning {
  color: #212529;
  background-color: #ff9800;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #cc7a00;
}
.badge-danger {
  color: #fff;
  background-color: #f44336;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ea1c0d;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}
.modal-open {
  overflow: hidden;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  transform: translate(0, 0);
}
.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  color: #000;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.9;
}
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer> :not(:first-child) {
  margin-left: .25rem;
}
.modal-footer> :not(:last-child) {
  margin-right: .25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: rgba(97, 97, 97, 0.9);
}
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: rgba(97, 97, 97, 0.9);
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: rgba(97, 97, 97, 0.9);
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.2);
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px;
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}
.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translate3d(0, 0, 0);
  }
}
.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-next,
  .active.carousel-item-right {
    transform: translate3d(100%, 0, 0);
  }
}
.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%);
}
@supports (transform-style: preserve-3d) {
  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translate3d(-100%, 0, 0);
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
/* .carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
} */
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #32a8df !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #771e86 !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #4caf50 !important;
}
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #3d8b40 !important;
}
.bg-info {
  background-color: #00bcd4 !important;
}
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #008fa1 !important;
}
.bg-warning {
  background-color: #ff9800 !important;
}
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cc7a00 !important;
}
.bg-danger {
  background-color: #f44336 !important;
}
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ea1c0d !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #32a8df !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #4caf50 !important;
}
.border-info {
  border-color: #00bcd4 !important;
}
.border-warning {
  border-color: #ff9800 !important;
}
.border-danger {
  border-color: #f44336 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: flex !important;
}
.d-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  flex-direction: row !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.justify-content-start {
  justify-content: flex-start !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-around {
  justify-content: space-around !important;
}
.align-items-start {
  align-items: flex-start !important;
}
.align-items-end {
  align-items: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.align-items-baseline {
  align-items: baseline !important;
}
.align-items-stretch {
  align-items: stretch !important;
}
.align-content-start {
  align-content: flex-start !important;
}
.align-content-end {
  align-content: flex-end !important;
}
.align-content-center {
  align-content: center !important;
}
.align-content-between {
  align-content: space-between !important;
}
.align-content-around {
  align-content: space-around !important;
}
.align-content-stretch {
  align-content: stretch !important;
}
.align-self-auto {
  align-self: auto !important;
}
.align-self-start {
  align-self: flex-start !important;
}
.align-self-end {
  align-self: flex-end !important;
}
.align-self-center {
  align-self: center !important;
}
.align-self-baseline {
  align-self: baseline !important;
}
.align-self-stretch {
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #32a8df !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #771e86 !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}
.text-success {
  color: #4caf50 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #3d8b40 !important;
}
.text-info {
  color: #00bcd4 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #008fa1 !important;
}
.text-warning {
  color: #ff9800 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #cc7a00 !important;
}
.text-danger {
  color: #f44336 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #ea1c0d !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}
.text-muted,
.bmd-help {
  color: #6c757d !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
a:focus {
  outline: none;
}
button:focus {
  outline: none;
}
.bmd-layout-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
.bmd-layout-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.bmd-layout-header {
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: flex-start;
  width: 100%;
  max-height: 1000px;
  transform: translateZ(0);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-content {
  position: relative;
  z-index: 1;
  display: inline-block;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-spacer {
  flex-grow: 1;
}
.bmd-layout-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: transparent;
  transition-property: background-color;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@supports (pointer-events: auto) {
  .bmd-layout-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition-property: opacity;
    visibility: visible;
    pointer-events: none;
  }
}
.btn {
  position: relative;
  padding: 12px 30px;
  margin: 0.3125rem 1px;
  font-size: .75rem;
  font-weight: 400;
  line-height: 1.428571;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  border-radius: 0.2rem;
  outline: 0;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
}
.btn:focus,
.btn.focus,
.btn:active:focus,
.btn:active.focus,
.btn:active:focus:focus,
.btn:active:focus.focus,
.btn.active:focus,
.btn.active.focus {
  outline: 0;
  box-shadow: none !important;
}
.btn.btn-primary {
  color: #fff;
  background-color: #32a8df;
  border-color: #32a8df;
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary:hover {
  color: #fff;
  background-color: #9124a3;
  border-color: #701c7e;
}
.btn.btn-primary:focus,
.btn.btn-primary.focus,
.btn.btn-primary:hover {
  color: #fff;
  background-color: #9124a3;
  border-color: #701c7e;
}
.btn.btn-primary:active,
.btn.btn-primary.active,
.open>.btn.btn-primary.dropdown-toggle,
.show>.btn.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #9124a3;
  border-color: #701c7e;
  box-shadow: 0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12);
}
.btn.btn-primary:active:hover,
.btn.btn-primary:active:focus,
.btn.btn-primary:active.focus,
.btn.btn-primary.active:hover,
.btn.btn-primary.active:focus,
.btn.btn-primary.active.focus,
.open>.btn.btn-primary.dropdown-toggle:hover,
.open>.btn.btn-primary.dropdown-toggle:focus,
.open>.btn.btn-primary.dropdown-toggle.focus,
.show>.btn.btn-primary.dropdown-toggle:hover,
.show>.btn.btn-primary.dropdown-toggle:focus,
.show>.btn.btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #9124a3;
  border-color: #3f1048;
}
.open>.btn.btn-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #32a8df;
}
.open>.btn.btn-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #9124a3;
}
.btn.btn-primary.disabled:focus,
.btn.btn-primary.disabled.focus,
.btn.btn-primary:disabled:focus,
.btn.btn-primary:disabled.focus {
  background-color: #32a8df;
  border-color: #32a8df;
}
.btn.btn-primary.disabled:hover,
.btn.btn-primary:disabled:hover {
  background-color: #32a8df;
  border-color: #32a8df;
}
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary:hover {
  box-shadow: 0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2);
}
.btn.btn-primary.btn-link {
  background-color: transparent;
  color: #32a8df;
  box-shadow: none;
}
.btn.btn-primary.btn-link:hover,
.btn.btn-primary.btn-link:focus,
.btn.btn-primary.btn-link:active {
  background-color: transparent;
  color: #32a8df;
}
.btn.btn-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: #fafafa;
  border-color: #ccc;
  box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14), 0 3px 1px -2px rgba(250, 250, 250, 0.2), 0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
}
.btn.btn-secondary:focus,
.btn.btn-secondary.focus,
.btn.btn-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
}
.btn.btn-secondary:active,
.btn.btn-secondary.active,
.open>.btn.btn-secondary.dropdown-toggle,
.show>.btn.btn-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #adadad;
  box-shadow: 0 2px 2px 0 rgba(250, 250, 250, 0.14), 0 3px 1px -2px rgba(250, 250, 250, 0.2), 0 1px 5px 0 rgba(250, 250, 250, 0.12);
}
.btn.btn-secondary:active:hover,
.btn.btn-secondary:active:focus,
.btn.btn-secondary:active.focus,
.btn.btn-secondary.active:hover,
.btn.btn-secondary.active:focus,
.btn.btn-secondary.active.focus,
.open>.btn.btn-secondary.dropdown-toggle:hover,
.open>.btn.btn-secondary.dropdown-toggle:focus,
.open>.btn.btn-secondary.dropdown-toggle.focus,
.show>.btn.btn-secondary.dropdown-toggle:hover,
.show>.btn.btn-secondary.dropdown-toggle:focus,
.show>.btn.btn-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f2f2f2;
  border-color: #8c8c8c;
}
.open>.btn.btn-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #fafafa;
}
.open>.btn.btn-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f2f2f2;
}
.btn.btn-secondary.disabled:focus,
.btn.btn-secondary.disabled.focus,
.btn.btn-secondary:disabled:focus,
.btn.btn-secondary:disabled.focus {
  background-color: #fafafa;
  border-color: #ccc;
}
.btn.btn-secondary.disabled:hover,
.btn.btn-secondary:disabled:hover {
  background-color: #fafafa;
  border-color: #ccc;
}
.btn.btn-secondary:focus,
.btn.btn-secondary:active,
.btn.btn-secondary:hover {
  box-shadow: 0 14px 26px -12px rgba(250, 250, 250, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(250, 250, 250, 0.2);
}
.btn.btn-secondary.btn-link {
  background-color: transparent;
  color: #fafafa;
  box-shadow: none;
}
.btn.btn-secondary.btn-link:hover,
.btn.btn-secondary.btn-link:focus,
.btn.btn-secondary.btn-link:active {
  background-color: transparent;
  color: #fafafa;
}
.btn.btn-info {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info:hover {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
}
.btn.btn-info:focus,
.btn.btn-info.focus,
.btn.btn-info:hover {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
}
.btn.btn-info:active,
.btn.btn-info.active,
.open>.btn.btn-info.dropdown-toggle,
.show>.btn.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #00aec5;
  border-color: #008697;
  box-shadow: 0 2px 2px 0 rgba(0, 188, 212, 0.14), 0 3px 1px -2px rgba(0, 188, 212, 0.2), 0 1px 5px 0 rgba(0, 188, 212, 0.12);
}
.btn.btn-info:active:hover,
.btn.btn-info:active:focus,
.btn.btn-info:active.focus,
.btn.btn-info.active:hover,
.btn.btn-info.active:focus,
.btn.btn-info.active.focus,
.open>.btn.btn-info.dropdown-toggle:hover,
.open>.btn.btn-info.dropdown-toggle:focus,
.open>.btn.btn-info.dropdown-toggle.focus,
.show>.btn.btn-info.dropdown-toggle:hover,
.show>.btn.btn-info.dropdown-toggle:focus,
.show>.btn.btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #00aec5;
  border-color: #004b55;
}
.open>.btn.btn-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #00bcd4;
}
.open>.btn.btn-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #00aec5;
}
.btn.btn-info.disabled:focus,
.btn.btn-info.disabled.focus,
.btn.btn-info:disabled:focus,
.btn.btn-info:disabled.focus {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn.btn-info.disabled:hover,
.btn.btn-info:disabled:hover {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.btn.btn-info:focus,
.btn.btn-info:active,
.btn.btn-info:hover {
  box-shadow: 0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2);
}
.btn.btn-info.btn-link {
  background-color: transparent;
  color: #00bcd4;
  box-shadow: none;
}
.btn.btn-info.btn-link:hover,
.btn.btn-info.btn-link:focus,
.btn.btn-info.btn-link:active {
  background-color: transparent;
  color: #00bcd4;
}
.btn.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success:hover {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
}
.btn.btn-success:focus,
.btn.btn-success.focus,
.btn.btn-success:hover {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
}
.btn.btn-success:active,
.btn.btn-success.active,
.open>.btn.btn-success.dropdown-toggle,
.show>.btn.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #47a44b;
  border-color: #39843c;
  box-shadow: 0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12);
}
.btn.btn-success:active:hover,
.btn.btn-success:active:focus,
.btn.btn-success:active.focus,
.btn.btn-success.active:hover,
.btn.btn-success.active:focus,
.btn.btn-success.active.focus,
.open>.btn.btn-success.dropdown-toggle:hover,
.open>.btn.btn-success.dropdown-toggle:focus,
.open>.btn.btn-success.dropdown-toggle.focus,
.show>.btn.btn-success.dropdown-toggle:hover,
.show>.btn.btn-success.dropdown-toggle:focus,
.show>.btn.btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #47a44b;
  border-color: #255627;
}
.open>.btn.btn-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #4caf50;
}
.open>.btn.btn-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #47a44b;
}
.btn.btn-success.disabled:focus,
.btn.btn-success.disabled.focus,
.btn.btn-success:disabled:focus,
.btn.btn-success:disabled.focus {
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn.btn-success.disabled:hover,
.btn.btn-success:disabled:hover {
  background-color: #4caf50;
  border-color: #4caf50;
}
.btn.btn-success:focus,
.btn.btn-success:active,
.btn.btn-success:hover {
  box-shadow: 0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2);
}
.btn.btn-success.btn-link {
  background-color: transparent;
  color: #4caf50;
  box-shadow: none;
}
.btn.btn-success.btn-link:hover,
.btn.btn-success.btn-link:focus,
.btn.btn-success.btn-link:active {
  background-color: transparent;
  color: #4caf50;
}
.btn.btn-warning {
  color: #fff;
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning:hover {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
}
.btn.btn-warning:focus,
.btn.btn-warning.focus,
.btn.btn-warning:hover {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
}
.btn.btn-warning:active,
.btn.btn-warning.active,
.open>.btn.btn-warning.dropdown-toggle,
.show>.btn.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f08f00;
  border-color: #c27400;
  box-shadow: 0 2px 2px 0 rgba(255, 152, 0, 0.14), 0 3px 1px -2px rgba(255, 152, 0, 0.2), 0 1px 5px 0 rgba(255, 152, 0, 0.12);
}
.btn.btn-warning:active:hover,
.btn.btn-warning:active:focus,
.btn.btn-warning:active.focus,
.btn.btn-warning.active:hover,
.btn.btn-warning.active:focus,
.btn.btn-warning.active.focus,
.open>.btn.btn-warning.dropdown-toggle:hover,
.open>.btn.btn-warning.dropdown-toggle:focus,
.open>.btn.btn-warning.dropdown-toggle.focus,
.show>.btn.btn-warning.dropdown-toggle:hover,
.show>.btn.btn-warning.dropdown-toggle:focus,
.show>.btn.btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #f08f00;
  border-color: #804c00;
}
.open>.btn.btn-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #ff9800;
}
.open>.btn.btn-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f08f00;
}
.btn.btn-warning.disabled:focus,
.btn.btn-warning.disabled.focus,
.btn.btn-warning:disabled:focus,
.btn.btn-warning:disabled.focus {
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn.btn-warning.disabled:hover,
.btn.btn-warning:disabled:hover {
  background-color: #ff9800;
  border-color: #ff9800;
}
.btn.btn-warning:focus,
.btn.btn-warning:active,
.btn.btn-warning:hover {
  box-shadow: 0 14px 26px -12px rgba(255, 152, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 152, 0, 0.2);
}
.btn.btn-warning.btn-link {
  background-color: transparent;
  color: #ff9800;
  box-shadow: none;
}
.btn.btn-warning.btn-link:hover,
.btn.btn-warning.btn-link:focus,
.btn.btn-warning.btn-link:active {
  background-color: transparent;
  color: #ff9800;
}
.btn.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger:hover {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
}
.btn.btn-danger:focus,
.btn.btn-danger.focus,
.btn.btn-danger:hover {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
}
.btn.btn-danger:active,
.btn.btn-danger.active,
.open>.btn.btn-danger.dropdown-toggle,
.show>.btn.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f33527;
  border-color: #e11b0c;
  box-shadow: 0 2px 2px 0 rgba(244, 67, 54, 0.14), 0 3px 1px -2px rgba(244, 67, 54, 0.2), 0 1px 5px 0 rgba(244, 67, 54, 0.12);
}
.btn.btn-danger:active:hover,
.btn.btn-danger:active:focus,
.btn.btn-danger:active.focus,
.btn.btn-danger.active:hover,
.btn.btn-danger.active:focus,
.btn.btn-danger.active.focus,
.open>.btn.btn-danger.dropdown-toggle:hover,
.open>.btn.btn-danger.dropdown-toggle:focus,
.open>.btn.btn-danger.dropdown-toggle.focus,
.show>.btn.btn-danger.dropdown-toggle:hover,
.show>.btn.btn-danger.dropdown-toggle:focus,
.show>.btn.btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #f33527;
  border-color: #a21309;
}
.open>.btn.btn-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #f44336;
}
.open>.btn.btn-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #f33527;
}
.btn.btn-danger.disabled:focus,
.btn.btn-danger.disabled.focus,
.btn.btn-danger:disabled:focus,
.btn.btn-danger:disabled.focus {
  background-color: #f44336;
  border-color: #f44336;
}
.btn.btn-danger.disabled:hover,
.btn.btn-danger:disabled:hover {
  background-color: #f44336;
  border-color: #f44336;
}
.btn.btn-danger:focus,
.btn.btn-danger:active,
.btn.btn-danger:hover {
  box-shadow: 0 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}
.btn.btn-danger.btn-link {
  background-color: transparent;
  color: #f44336;
  box-shadow: none;
}
.btn.btn-danger.btn-link:hover,
.btn.btn-danger.btn-link:focus,
.btn.btn-danger.btn-link:active {
  background-color: transparent;
  color: #f44336;
}
.btn.btn-rose {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose:hover {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
}
.btn.btn-rose:focus,
.btn.btn-rose.focus,
.btn.btn-rose:hover {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
}
.btn.btn-rose:active,
.btn.btn-rose.active,
.open>.btn.btn-rose.dropdown-toggle,
.show>.btn.btn-rose.dropdown-toggle {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #b8124a;
  box-shadow: 0 2px 2px 0 rgba(233, 30, 99, 0.14), 0 3px 1px -2px rgba(233, 30, 99, 0.2), 0 1px 5px 0 rgba(233, 30, 99, 0.12);
}
.btn.btn-rose:active:hover,
.btn.btn-rose:active:focus,
.btn.btn-rose:active.focus,
.btn.btn-rose.active:hover,
.btn.btn-rose.active:focus,
.btn.btn-rose.active.focus,
.open>.btn.btn-rose.dropdown-toggle:hover,
.open>.btn.btn-rose.dropdown-toggle:focus,
.open>.btn.btn-rose.dropdown-toggle.focus,
.show>.btn.btn-rose.dropdown-toggle:hover,
.show>.btn.btn-rose.dropdown-toggle:focus,
.show>.btn.btn-rose.dropdown-toggle.focus {
  color: #fff;
  background-color: #ea2c6d;
  border-color: #7b0c32;
}
.open>.btn.btn-rose.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #e91e63;
}
.open>.btn.btn-rose.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #ea2c6d;
}
.btn.btn-rose.disabled:focus,
.btn.btn-rose.disabled.focus,
.btn.btn-rose:disabled:focus,
.btn.btn-rose:disabled.focus {
  background-color: #e91e63;
  border-color: #e91e63;
}
.btn.btn-rose.disabled:hover,
.btn.btn-rose:disabled:hover {
  background-color: #e91e63;
  border-color: #e91e63;
}
.btn.btn-rose:focus,
.btn.btn-rose:active,
.btn.btn-rose:hover {
  box-shadow: 0 14px 26px -12px rgba(233, 30, 99, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(233, 30, 99, 0.2);
}
.btn.btn-rose.btn-link {
  background-color: transparent;
  color: #e91e63;
  box-shadow: none;
}
.btn.btn-rose.btn-link:hover,
.btn.btn-rose.btn-link:focus,
.btn.btn-rose.btn-link:active {
  background-color: transparent;
  color: #e91e63;
}
.btn,
.btn.btn-default {
  color: #fff;
  background-color: #999999;
  border-color: #999999;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn:hover,
.btn.btn-default:hover {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
}
.btn:focus,
.btn.focus,
.btn:hover,
.btn.btn-default:focus,
.btn.btn-default.focus,
.btn.btn-default:hover {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
}
.btn:active,
.btn.active,
.open>.btn.dropdown-toggle,
.show>.btn.dropdown-toggle,
.btn.btn-default:active,
.btn.btn-default.active,
.open>.btn.btn-default.dropdown-toggle,
.show>.btn.btn-default.dropdown-toggle {
  color: #fff;
  background-color: #919191;
  border-color: #7a7a7a;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2), 0 1px 5px 0 rgba(153, 153, 153, 0.12);
}
.btn:active:hover,
.btn:active:focus,
.btn:active.focus,
.btn.active:hover,
.btn.active:focus,
.btn.active.focus,
.open>.btn.dropdown-toggle:hover,
.open>.btn.dropdown-toggle:focus,
.open>.btn.dropdown-toggle.focus,
.show>.btn.dropdown-toggle:hover,
.show>.btn.dropdown-toggle:focus,
.show>.btn.dropdown-toggle.focus,
.btn.btn-default:active:hover,
.btn.btn-default:active:focus,
.btn.btn-default:active.focus,
.btn.btn-default.active:hover,
.btn.btn-default.active:focus,
.btn.btn-default.active.focus,
.open>.btn.btn-default.dropdown-toggle:hover,
.open>.btn.btn-default.dropdown-toggle:focus,
.open>.btn.btn-default.dropdown-toggle.focus,
.show>.btn.btn-default.dropdown-toggle:hover,
.show>.btn.btn-default.dropdown-toggle:focus,
.show>.btn.btn-default.dropdown-toggle.focus {
  color: #fff;
  background-color: #919191;
  border-color: #595959;
}
.open>.btn.dropdown-toggle.bmd-btn-icon,
.open>.btn.btn-default.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: #999999;
}
.open>.btn.dropdown-toggle.bmd-btn-icon:hover,
.open>.btn.btn-default.dropdown-toggle.bmd-btn-icon:hover {
  background-color: #919191;
}
.btn.disabled:focus,
.btn.disabled.focus,
.btn:disabled:focus,
.btn:disabled.focus,
.btn.btn-default.disabled:focus,
.btn.btn-default.disabled.focus,
.btn.btn-default:disabled:focus,
.btn.btn-default:disabled.focus {
  background-color: #999999;
  border-color: #999999;
}
.btn.disabled:hover,
.btn:disabled:hover,
.btn.btn-default.disabled:hover,
.btn.btn-default:disabled:hover {
  background-color: #999999;
  border-color: #999999;
}
.btn:focus,
.btn:active,
.btn:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default:hover {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.btn.btn-link,
.btn.btn-default.btn-link {
  background-color: transparent;
  color: #999999;
  box-shadow: none;
}
.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:active,
.btn.btn-default.btn-link:hover,
.btn.btn-default.btn-link:focus,
.btn.btn-default.btn-link:active {
  background-color: transparent;
  color: #999999;
}
.btn.btn-white,
.btn.btn-white:focus,
.btn.btn-white:hover {
  background-color: #ffffff;
  color: #999999;
}
.btn.btn-white.btn-link {
  color: #ffffff;
  background: transparent;
  box-shadow: none;
}
.btn.btn-link:hover,
.btn.btn-link:focus,
.btn.btn-link:active {
  text-decoration: none !important;
}
.btn.btn-disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.btn.btn-disabled:hover {
  box-shadow: none;
}
.btn.btn-raised.btn-link,
.btn-group-raised .btn.btn-link {
  box-shadow: none;
}
.btn.btn-raised.btn-link.active,
.btn-group-raised .btn.btn-link.active {
  box-shadow: none;
}
.btn.btn-raised.btn-link:hover,
.btn.btn-raised.btn-link:focus,
.btn.btn-raised.btn-link:active,
.btn-group-raised .btn.btn-link:hover,
.btn-group-raised .btn.btn-link:focus,
.btn-group-raised .btn.btn-link:active {
  box-shadow: none;
}
fieldset[disabled][disabled] .btn.btn-raised,
.btn.btn-raised.disabled,
.btn.btn-raised:disabled,
.btn.btn-raised[disabled],
fieldset[disabled][disabled] .btn-group-raised .btn,
.btn-group-raised .btn.disabled,
.btn-group-raised .btn:disabled,
.btn-group-raised .btn[disabled] {
  box-shadow: none;
}
.btn.btn-outline,
.btn.btn-outline-primary,
.btn.btn-outline-secondary,
.btn.btn-outline-info,
.btn.btn-outline-success,
.btn.btn-outline-warning,
.btn.btn-outline-danger {
  border-color: currentColor;
  border-style: solid;
  border-width: 1px;
}
.btn.btn-outline {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:focus,
.btn.btn-outline.focus,
.btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline:active,
.btn.btn-outline.active,
.open>.btn.btn-outline.dropdown-toggle,
.show>.btn.btn-outline.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline:active:hover,
.btn.btn-outline:active:focus,
.btn.btn-outline:active.focus,
.btn.btn-outline.active:hover,
.btn.btn-outline.active:focus,
.btn.btn-outline.active.focus,
.open>.btn.btn-outline.dropdown-toggle:hover,
.open>.btn.btn-outline.dropdown-toggle:focus,
.open>.btn.btn-outline.dropdown-toggle.focus,
.show>.btn.btn-outline.dropdown-toggle:hover,
.show>.btn.btn-outline.dropdown-toggle:focus,
.show>.btn.btn-outline.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.4);
  border-color: rgba(0, 0, 0, 0.87);
}
.open>.btn.btn-outline.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline.disabled:focus,
.btn.btn-outline.disabled.focus,
.btn.btn-outline:disabled:focus,
.btn.btn-outline:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.disabled:hover,
.btn.btn-outline:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline:focus,
.bg-inverse .btn.btn-outline.focus,
.bg-inverse .btn.btn-outline:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline:active,
.bg-inverse .btn.btn-outline.active,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline:active:hover,
.bg-inverse .btn.btn-outline:active:focus,
.bg-inverse .btn.btn-outline:active.focus,
.bg-inverse .btn.btn-outline.active:hover,
.bg-inverse .btn.btn-outline.active:focus,
.bg-inverse .btn.btn-outline.active.focus,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline.disabled:focus,
.bg-inverse .btn.btn-outline.disabled.focus,
.bg-inverse .btn.btn-outline:disabled:focus,
.bg-inverse .btn.btn-outline:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline.disabled:hover,
.bg-inverse .btn.btn-outline:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline.btn-link {
  background-color: transparent;
}
.btn.btn-outline-primary {
  color: #32a8df;
  background-color: transparent;
  border-color: #32a8df;
}
.btn.btn-outline-primary:hover {
  color: #32a8df;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #32a8df;
}
.btn.btn-outline-primary:focus,
.btn.btn-outline-primary.focus,
.btn.btn-outline-primary:hover {
  color: #32a8df;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #32a8df;
}
.btn.btn-outline-primary:active,
.btn.btn-outline-primary.active,
.open>.btn.btn-outline-primary.dropdown-toggle,
.show>.btn.btn-outline-primary.dropdown-toggle {
  color: #32a8df;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #32a8df;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-primary:active:hover,
.btn.btn-outline-primary:active:focus,
.btn.btn-outline-primary:active.focus,
.btn.btn-outline-primary.active:hover,
.btn.btn-outline-primary.active:focus,
.btn.btn-outline-primary.active.focus,
.open>.btn.btn-outline-primary.dropdown-toggle:hover,
.open>.btn.btn-outline-primary.dropdown-toggle:focus,
.open>.btn.btn-outline-primary.dropdown-toggle.focus,
.show>.btn.btn-outline-primary.dropdown-toggle:hover,
.show>.btn.btn-outline-primary.dropdown-toggle:focus,
.show>.btn.btn-outline-primary.dropdown-toggle.focus {
  color: #32a8df;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #32a8df;
}
.open>.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-primary.disabled:focus,
.btn.btn-outline-primary.disabled.focus,
.btn.btn-outline-primary:disabled:focus,
.btn.btn-outline-primary:disabled.focus {
  background-color: transparent;
  border-color: #32a8df;
}
.btn.btn-outline-primary.disabled:hover,
.btn.btn-outline-primary:disabled:hover {
  background-color: transparent;
  border-color: #32a8df;
}
.bg-inverse .btn.btn-outline-primary {
  color: #32a8df;
  background-color: transparent;
  border-color: #32a8df;
}
.bg-inverse .btn.btn-outline-primary:hover {
  color: #32a8df;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary:focus,
.bg-inverse .btn.btn-outline-primary.focus,
.bg-inverse .btn.btn-outline-primary:hover {
  color: #32a8df;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary:active,
.bg-inverse .btn.btn-outline-primary.active,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle {
  color: #32a8df;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-primary:active:hover,
.bg-inverse .btn.btn-outline-primary:active:focus,
.bg-inverse .btn.btn-outline-primary:active.focus,
.bg-inverse .btn.btn-outline-primary.active:hover,
.bg-inverse .btn.btn-outline-primary.active:focus,
.bg-inverse .btn.btn-outline-primary.active.focus,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.focus {
  color: #32a8df;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-primary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-primary.disabled:focus,
.bg-inverse .btn.btn-outline-primary.disabled.focus,
.bg-inverse .btn.btn-outline-primary:disabled:focus,
.bg-inverse .btn.btn-outline-primary:disabled.focus {
  background-color: transparent;
  border-color: #32a8df;
}
.bg-inverse .btn.btn-outline-primary.disabled:hover,
.bg-inverse .btn.btn-outline-primary:disabled:hover {
  background-color: transparent;
  border-color: #32a8df;
}
.btn.btn-outline-primary.btn-link {
  background-color: transparent;
}
.btn.btn-outline-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:focus,
.btn.btn-outline-secondary.focus,
.btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary:active,
.btn.btn-outline-secondary.active,
.open>.btn.btn-outline-secondary.dropdown-toggle,
.show>.btn.btn-outline-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.2);
  border-color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-secondary:active:hover,
.btn.btn-outline-secondary:active:focus,
.btn.btn-outline-secondary:active.focus,
.btn.btn-outline-secondary.active:hover,
.btn.btn-outline-secondary.active:focus,
.btn.btn-outline-secondary.active.focus,
.open>.btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.btn.btn-outline-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(153, 153, 153, 0.4);
  border-color: rgba(0, 0, 0, 0.87);
}
.open>.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-secondary.disabled:focus,
.btn.btn-outline-secondary.disabled.focus,
.btn.btn-outline-secondary:disabled:focus,
.btn.btn-outline-secondary:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.disabled:hover,
.btn.btn-outline-secondary:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary {
  color: rgba(0, 0, 0, 0.87);
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary:focus,
.bg-inverse .btn.btn-outline-secondary.focus,
.bg-inverse .btn.btn-outline-secondary:hover {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary:active,
.bg-inverse .btn.btn-outline-secondary.active,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-secondary:active:hover,
.bg-inverse .btn.btn-outline-secondary:active:focus,
.bg-inverse .btn.btn-outline-secondary:active.focus,
.bg-inverse .btn.btn-outline-secondary.active:hover,
.bg-inverse .btn.btn-outline-secondary.active:focus,
.bg-inverse .btn.btn-outline-secondary.active.focus,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.focus {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-secondary.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-secondary.disabled:focus,
.bg-inverse .btn.btn-outline-secondary.disabled.focus,
.bg-inverse .btn.btn-outline-secondary:disabled:focus,
.bg-inverse .btn.btn-outline-secondary:disabled.focus {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.bg-inverse .btn.btn-outline-secondary.disabled:hover,
.bg-inverse .btn.btn-outline-secondary:disabled:hover {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.87);
}
.btn.btn-outline-secondary.btn-link {
  background-color: transparent;
}
.btn.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
}
.btn.btn-outline-info:focus,
.btn.btn-outline-info.focus,
.btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
}
.btn.btn-outline-info:active,
.btn.btn-outline-info.active,
.open>.btn.btn-outline-info.dropdown-toggle,
.show>.btn.btn-outline-info.dropdown-toggle {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #00bcd4;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-info:active:hover,
.btn.btn-outline-info:active:focus,
.btn.btn-outline-info:active.focus,
.btn.btn-outline-info.active:hover,
.btn.btn-outline-info.active:focus,
.btn.btn-outline-info.active.focus,
.open>.btn.btn-outline-info.dropdown-toggle:hover,
.open>.btn.btn-outline-info.dropdown-toggle:focus,
.open>.btn.btn-outline-info.dropdown-toggle.focus,
.show>.btn.btn-outline-info.dropdown-toggle:hover,
.show>.btn.btn-outline-info.dropdown-toggle:focus,
.show>.btn.btn-outline-info.dropdown-toggle.focus {
  color: #00bcd4;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #00bcd4;
}
.open>.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-info.disabled:focus,
.btn.btn-outline-info.disabled.focus,
.btn.btn-outline-info:disabled:focus,
.btn.btn-outline-info:disabled.focus {
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info.disabled:hover,
.btn.btn-outline-info:disabled:hover {
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info {
  color: #00bcd4;
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info:focus,
.bg-inverse .btn.btn-outline-info.focus,
.bg-inverse .btn.btn-outline-info:hover {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info:active,
.bg-inverse .btn.btn-outline-info.active,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-info:active:hover,
.bg-inverse .btn.btn-outline-info:active:focus,
.bg-inverse .btn.btn-outline-info:active.focus,
.bg-inverse .btn.btn-outline-info.active:hover,
.bg-inverse .btn.btn-outline-info.active:focus,
.bg-inverse .btn.btn-outline-info.active.focus,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-info.dropdown-toggle.focus {
  color: #00bcd4;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-info.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-info.disabled:focus,
.bg-inverse .btn.btn-outline-info.disabled.focus,
.bg-inverse .btn.btn-outline-info:disabled:focus,
.bg-inverse .btn.btn-outline-info:disabled.focus {
  background-color: transparent;
  border-color: #00bcd4;
}
.bg-inverse .btn.btn-outline-info.disabled:hover,
.bg-inverse .btn.btn-outline-info:disabled:hover {
  background-color: transparent;
  border-color: #00bcd4;
}
.btn.btn-outline-info.btn-link {
  background-color: transparent;
}
.btn.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
}
.btn.btn-outline-success:focus,
.btn.btn-outline-success.focus,
.btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
}
.btn.btn-outline-success:active,
.btn.btn-outline-success.active,
.open>.btn.btn-outline-success.dropdown-toggle,
.show>.btn.btn-outline-success.dropdown-toggle {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #4caf50;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-success:active:hover,
.btn.btn-outline-success:active:focus,
.btn.btn-outline-success:active.focus,
.btn.btn-outline-success.active:hover,
.btn.btn-outline-success.active:focus,
.btn.btn-outline-success.active.focus,
.open>.btn.btn-outline-success.dropdown-toggle:hover,
.open>.btn.btn-outline-success.dropdown-toggle:focus,
.open>.btn.btn-outline-success.dropdown-toggle.focus,
.show>.btn.btn-outline-success.dropdown-toggle:hover,
.show>.btn.btn-outline-success.dropdown-toggle:focus,
.show>.btn.btn-outline-success.dropdown-toggle.focus {
  color: #4caf50;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #4caf50;
}
.open>.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-success.disabled:focus,
.btn.btn-outline-success.disabled.focus,
.btn.btn-outline-success:disabled:focus,
.btn.btn-outline-success:disabled.focus {
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success.disabled:hover,
.btn.btn-outline-success:disabled:hover {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success {
  color: #4caf50;
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success:focus,
.bg-inverse .btn.btn-outline-success.focus,
.bg-inverse .btn.btn-outline-success:hover {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success:active,
.bg-inverse .btn.btn-outline-success.active,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-success:active:hover,
.bg-inverse .btn.btn-outline-success:active:focus,
.bg-inverse .btn.btn-outline-success:active.focus,
.bg-inverse .btn.btn-outline-success.active:hover,
.bg-inverse .btn.btn-outline-success.active:focus,
.bg-inverse .btn.btn-outline-success.active.focus,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-success.dropdown-toggle.focus {
  color: #4caf50;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-success.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-success.disabled:focus,
.bg-inverse .btn.btn-outline-success.disabled.focus,
.bg-inverse .btn.btn-outline-success:disabled:focus,
.bg-inverse .btn.btn-outline-success:disabled.focus {
  background-color: transparent;
  border-color: #4caf50;
}
.bg-inverse .btn.btn-outline-success.disabled:hover,
.bg-inverse .btn.btn-outline-success:disabled:hover {
  background-color: transparent;
  border-color: #4caf50;
}
.btn.btn-outline-success.btn-link {
  background-color: transparent;
}
.btn.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
}
.btn.btn-outline-warning:focus,
.btn.btn-outline-warning.focus,
.btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
}
.btn.btn-outline-warning:active,
.btn.btn-outline-warning.active,
.open>.btn.btn-outline-warning.dropdown-toggle,
.show>.btn.btn-outline-warning.dropdown-toggle {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #ff9800;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-warning:active:hover,
.btn.btn-outline-warning:active:focus,
.btn.btn-outline-warning:active.focus,
.btn.btn-outline-warning.active:hover,
.btn.btn-outline-warning.active:focus,
.btn.btn-outline-warning.active.focus,
.open>.btn.btn-outline-warning.dropdown-toggle:hover,
.open>.btn.btn-outline-warning.dropdown-toggle:focus,
.open>.btn.btn-outline-warning.dropdown-toggle.focus,
.show>.btn.btn-outline-warning.dropdown-toggle:hover,
.show>.btn.btn-outline-warning.dropdown-toggle:focus,
.show>.btn.btn-outline-warning.dropdown-toggle.focus {
  color: #ff9800;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #ff9800;
}
.open>.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-warning.disabled:focus,
.btn.btn-outline-warning.disabled.focus,
.btn.btn-outline-warning:disabled:focus,
.btn.btn-outline-warning:disabled.focus {
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning.disabled:hover,
.btn.btn-outline-warning:disabled:hover {
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning {
  color: #ff9800;
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning:focus,
.bg-inverse .btn.btn-outline-warning.focus,
.bg-inverse .btn.btn-outline-warning:hover {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning:active,
.bg-inverse .btn.btn-outline-warning.active,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-warning:active:hover,
.bg-inverse .btn.btn-outline-warning:active:focus,
.bg-inverse .btn.btn-outline-warning:active.focus,
.bg-inverse .btn.btn-outline-warning.active:hover,
.bg-inverse .btn.btn-outline-warning.active:focus,
.bg-inverse .btn.btn-outline-warning.active.focus,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.focus {
  color: #ff9800;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-warning.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-warning.disabled:focus,
.bg-inverse .btn.btn-outline-warning.disabled.focus,
.bg-inverse .btn.btn-outline-warning:disabled:focus,
.bg-inverse .btn.btn-outline-warning:disabled.focus {
  background-color: transparent;
  border-color: #ff9800;
}
.bg-inverse .btn.btn-outline-warning.disabled:hover,
.bg-inverse .btn.btn-outline-warning:disabled:hover {
  background-color: transparent;
  border-color: #ff9800;
}
.btn.btn-outline-warning.btn-link {
  background-color: transparent;
}
.btn.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
}
.btn.btn-outline-danger:focus,
.btn.btn-outline-danger.focus,
.btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
}
.btn.btn-outline-danger:active,
.btn.btn-outline-danger.active,
.open>.btn.btn-outline-danger.dropdown-toggle,
.show>.btn.btn-outline-danger.dropdown-toggle {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.2);
  border-color: #f44336;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-outline-danger:active:hover,
.btn.btn-outline-danger:active:focus,
.btn.btn-outline-danger:active.focus,
.btn.btn-outline-danger.active:hover,
.btn.btn-outline-danger.active:focus,
.btn.btn-outline-danger.active.focus,
.open>.btn.btn-outline-danger.dropdown-toggle:hover,
.open>.btn.btn-outline-danger.dropdown-toggle:focus,
.open>.btn.btn-outline-danger.dropdown-toggle.focus,
.show>.btn.btn-outline-danger.dropdown-toggle:hover,
.show>.btn.btn-outline-danger.dropdown-toggle:focus,
.show>.btn.btn-outline-danger.dropdown-toggle.focus {
  color: #f44336;
  background-color: rgba(153, 153, 153, 0.4);
  border-color: #f44336;
}
.open>.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(153, 153, 153, 0.2);
}
.btn.btn-outline-danger.disabled:focus,
.btn.btn-outline-danger.disabled.focus,
.btn.btn-outline-danger:disabled:focus,
.btn.btn-outline-danger:disabled.focus {
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger.disabled:hover,
.btn.btn-outline-danger:disabled:hover {
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger {
  color: #f44336;
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger:focus,
.bg-inverse .btn.btn-outline-danger.focus,
.bg-inverse .btn.btn-outline-danger:hover {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger:active,
.bg-inverse .btn.btn-outline-danger.active,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.15);
  border-color: rgba(204, 204, 204, 0.15);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.bg-inverse .btn.btn-outline-danger:active:hover,
.bg-inverse .btn.btn-outline-danger:active:focus,
.bg-inverse .btn.btn-outline-danger:active.focus,
.bg-inverse .btn.btn-outline-danger.active:hover,
.bg-inverse .btn.btn-outline-danger.active:focus,
.bg-inverse .btn.btn-outline-danger.active.focus,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:hover,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle:focus,
.show>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.focus {
  color: #f44336;
  background-color: rgba(204, 204, 204, 0.25);
  border-color: rgba(204, 204, 204, 0.25);
}
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon {
  color: inherit;
  background-color: transparent;
}
.open>.bg-inverse .btn.btn-outline-danger.dropdown-toggle.bmd-btn-icon:hover {
  background-color: rgba(204, 204, 204, 0.15);
}
.bg-inverse .btn.btn-outline-danger.disabled:focus,
.bg-inverse .btn.btn-outline-danger.disabled.focus,
.bg-inverse .btn.btn-outline-danger:disabled:focus,
.bg-inverse .btn.btn-outline-danger:disabled.focus {
  background-color: transparent;
  border-color: #f44336;
}
.bg-inverse .btn.btn-outline-danger.disabled:hover,
.bg-inverse .btn.btn-outline-danger:disabled:hover {
  background-color: transparent;
  border-color: #f44336;
}
.btn.btn-outline-danger.btn-link {
  background-color: transparent;
}
.btn.btn-lg,
.btn-group-lg>.btn,
.btn-group-lg .btn {
  padding: 1.125rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.333333;
  border-radius: 0.2rem;
}
.btn.btn-sm,
.btn-group-sm>.btn,
.btn-group-sm .btn {
  padding: 0.40625rem 1.25rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn.btn-round {
  border-radius: 30px;
}
.btn.btn-fab,
.btn.btn-just-icon {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
  padding: 0;
  overflow: hidden;
  position: relative;
  line-height: 41px;
}
.btn.btn-fab.btn-round,
.btn.btn-just-icon.btn-round {
  border-radius: 50%;
}
.btn-group-sm .btn.btn-fab,
.btn.btn-fab.btn-sm,
.btn-group-sm>.btn.btn-fab,
.btn.btn-fab.btn-fab-mini,
.btn-group-sm .btn.btn-just-icon,
.btn.btn-just-icon.btn-sm,
.btn-group-sm>.btn.btn-just-icon,
.btn.btn-just-icon.btn-fab-mini {
  height: 30px;
  min-width: 30px;
  width: 30px;
}
.btn-group-sm .btn.btn-fab .material-icons,
.btn-group-sm .btn.btn-fab .fa,
.btn.btn-fab.btn-sm .material-icons,
.btn-group-sm>.btn.btn-fab .material-icons,
.btn.btn-fab.btn-sm .fa,
.btn-group-sm>.btn.btn-fab .fa,
.btn.btn-fab.btn-fab-mini .material-icons,
.btn.btn-fab.btn-fab-mini .fa,
.btn-group-sm .btn.btn-just-icon .material-icons,
.btn-group-sm .btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-sm .material-icons,
.btn-group-sm>.btn.btn-just-icon .material-icons,
.btn.btn-just-icon.btn-sm .fa,
.btn-group-sm>.btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-fab-mini .material-icons,
.btn.btn-just-icon.btn-fab-mini .fa {
  font-size: 17px;
  line-height: 29px;
}
.btn-group-lg .btn.btn-fab,
.btn.btn-fab.btn-lg,
.btn-group-lg>.btn.btn-fab,
.btn-group-lg .btn.btn-just-icon,
.btn.btn-just-icon.btn-lg,
.btn-group-lg>.btn.btn-just-icon {
  height: 57px;
  min-width: 57px;
  width: 57px;
  line-height: 56px;
}
.btn-group-lg .btn.btn-fab .material-icons,
.btn-group-lg .btn.btn-fab .fa,
.btn.btn-fab.btn-lg .material-icons,
.btn-group-lg>.btn.btn-fab .material-icons,
.btn.btn-fab.btn-lg .fa,
.btn-group-lg>.btn.btn-fab .fa,
.btn-group-lg .btn.btn-just-icon .material-icons,
.btn-group-lg .btn.btn-just-icon .fa,
.btn.btn-just-icon.btn-lg .material-icons,
.btn-group-lg>.btn.btn-just-icon .material-icons,
.btn.btn-just-icon.btn-lg .fa,
.btn-group-lg>.btn.btn-just-icon .fa {
  font-size: 32px;
  line-height: 56px;
}
.btn.btn-fab .material-icons,
.btn.btn-fab .fa,
.btn.btn-just-icon .material-icons,
.btn.btn-just-icon .fa {
  margin-top: 0;
  position: absolute;
  width: 100%;
  transform: none;
  left: 0;
  top: 0;
  height: 100%;
  line-height: 41px;
  font-size: 20px;
}
.btn-just-icon.btn-lg,
.btn-group-lg>.btn-just-icon.btn {
  font-size: 24px;
  height: 41px;
  min-width: 41px;
  width: 41px;
}
.input-group-btn>.btn {
  border: 0;
}
.btn .material-icons,
.btn:not(.btn-just-icon):not(.btn-fab) .fa {
  position: relative;
  display: inline-block;
  top: 0;
  margin-top: -1em;
  margin-bottom: -1em;
  font-size: 1.1rem;
  vertical-align: middle;
}
.bg-inverse fieldset[disabled][disabled] .btn,
.bg-inverse .btn.disabled,
.bg-inverse .btn:disabled,
.bg-inverse .btn[disabled],
.bg-inverse fieldset[disabled][disabled] .input-group-btn .btn,
.bg-inverse .input-group-btn .btn.disabled,
.bg-inverse .input-group-btn .btn:disabled,
.bg-inverse .input-group-btn .btn[disabled],
.bg-inverse fieldset[disabled][disabled] .btn-group,
.bg-inverse .btn-group.disabled,
.bg-inverse .btn-group:disabled,
.bg-inverse .btn-group[disabled],
.bg-inverse fieldset[disabled][disabled] .btn-group-vertical,
.bg-inverse .btn-group-vertical.disabled,
.bg-inverse .btn-group-vertical:disabled,
.bg-inverse .btn-group-vertical[disabled] {
  color: rgba(255, 255, 255, 0.3);
}
.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px;
}
.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
  border-radius: 0 0 0.25rem 0.25rem;
}
.btn-group.btn-group-raised,
.btn-group-vertical.btn-group-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-group .btn+.btn,
.btn-group .btn,
.btn-group .btn:active,
.btn-group .btn-group,
.btn-group-vertical .btn+.btn,
.btn-group-vertical .btn,
.btn-group-vertical .btn:active,
.btn-group-vertical .btn-group {
  margin: 0;
}
.btn-group>.btn-group,
.btn-group-vertical>.btn-group {
  margin: 0;
}
legend {
  border-bottom: 0;
}
.serif-font {
  font-family: 'Lato', sans-serif;
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
*:focus {
  outline: 0;
}
a {
  color: #32a8df;
}
a:hover,
a:focus {
  color: #32a8df;
  text-decoration: none;
}
a.text-info:hover,
a.text-info:focus {
  color: #00a5bb;
}
a .material-icons {
  vertical-align: middle;
}
.main {
  position: relative;
  z-index: 3;
}
.form-check,
label {
  font-size: 14px;
  line-height: 1.42857;
  color: #AAAAAA;
  font-weight: 400;
}
.main-raised {
  margin: -60px 30px 0px;
  border-radius: 6px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
/*           Animations              */
.animation-transition-general,
.carousel .carousel-indicators li {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}
.animation-transition-slow {
  -webkit-transition: all 370ms linear;
  -moz-transition: all 370ms linear;
  -o-transition: all 370ms linear;
  -ms-transition: all 370ms linear;
  transition: all 370ms linear;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td>div,
.bootstrap-datetimepicker-widget table th>div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td span {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
.animation-transition-fast,
.bootstrap-datetimepicker-widget table td>div,
.bootstrap-datetimepicker-widget table th>div,
.bootstrap-datetimepicker-widget table th,
.bootstrap-datetimepicker-widget table td span,
.navbar,
.bootstrap-tagsinput .tag,
.bootstrap-tagsinput [data-role="remove"],
.card-collapse .card-header a i {
  -webkit-transition: all 150ms ease 0s;
  -moz-transition: all 150ms ease 0s;
  -o-transition: all 150ms ease 0s;
  -ms-transition: all 150ms ease 0s;
  transition: all 150ms ease 0s;
}
.signup-page .card-signup form .form-check {
  padding-left: 20px;
}
.signup-page .card-signup form .form-check .form-check-label {
  padding-left: 35px;
}
.section-signup .card-signup .card-header {
  width: auto;
}
.section-signup .card-signup .card-body .input-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0;
}
.offline-doc .page-header,
.offline-free-doc .page-header {
  height: 100% !important;
}
.offline-doc .page-header:after,
.offline-free-doc .page-header:after {
  background: rgba(0, 0, 0, 0.5) !important;
}
.offline-doc .footer,
.offline-free-doc .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #ffffff;
  z-index: 1;
}
.offline-doc .footer .copyright a,
.offline-free-doc .footer .copyright a {
  color: #ffffff;
}
.offline-doc .navbar .navbar-brand,
.offline-doc .navbar .navbar-collapse .nav-link,
.offline-free-doc .navbar .navbar-brand,
.offline-free-doc .navbar .navbar-collapse .nav-link {
  color: #ffffff;
}
@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }
  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }
  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}
.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}
.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.tim-row {
  margin-bottom: 20px;
}
.tim-white-buttons {
  background-color: #777777;
}
.title {
  margin-top: 30px;
  margin-bottom: 25px;
  min-height: 32px;
}
#map {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(100vh - 70px);
  margin-top: 70px;
}
.tim-typo {
  padding-left: 25%;
  margin-bottom: 40px;
  position: relative;
  width: 100%;
}
.tim-typo .tim-note {
  bottom: 5px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
}
.tim-row {
  padding-top: 50px;
}
.tim-row h3 {
  margin-top: 0;
}
#typography h1,
#typography h2,
#typography h3,
#typography h4,
#typography h5,
#typography h6 {
  margin-bottom: 0;
}
.switch {
  margin-right: 20px;
}
#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}
.space {
  height: 130px;
  display: block;
}
.space-110 {
  height: 110px;
  display: block;
}
.space-50 {
  height: 50px;
  display: block;
}
.space-70 {
  height: 70px;
  display: block;
}
.navigation-example .img-src {
  background-attachment: scroll;
}
.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
}
#notifications {
  background-color: #ffffff;
  display: block;
  width: 100%;
  position: relative;
}
#notifications .alert-danger {
  margin-bottom: 0px;
}
.tim-note {
  text-transform: capitalize;
}
#buttons .btn {
  margin: 0 0px 15px;
}
.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}
.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}
.parallax .parallax-image img {
  width: 100%;
}
@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}
.separator {
  content: "Separator";
  color: #ffffff;
  display: block;
  width: 100%;
  padding: 20px;
}
.separator-line {
  background-color: #EEE;
  height: 1px;
  width: 100%;
  display: block;
}
.separator.separator-gray {
  background-color: #EEEEEE;
}
.social-buttons-demo .btn {
  margin: 10px 5px 7px 1px;
}
.img-container {
  width: 100%;
  overflow: hidden;
}
.img-container img {
  width: 100%;
}
.section-black {
  background-color: #333;
}
.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}
.sharing-area {
  margin-top: 80px;
}
.sharing-area .btn {
  margin: 15px 4px 0;
  color: #ffffff;
}
.sharing-area .btn i {
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
#navbar {
  margin-bottom: -20px;
}
.sharing-area .btn-twitter {
  background-color: #55acee;
}
.sharing-area .btn-facebook {
  background-color: #3b5998;
}
.sharing-area .btn-instagram {
  background-color: #e4405f;
}
.sharing-area .btn-github {
  background-color: #333333;
}
#navbar .navbar {
  border-radius: 0;
}
@media (max-width: 830px) {
  .main-raised {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 767px) {
  .tab-pane#schedule-2 {
    padding-top: 20px;
  }
}
.bd-docs .bd-example.bd-example-popover-static .popover,
.bd-docs .bd-example.bd-example-tooltip-static .tooltip {
  z-index: 3 !important;
}
@media all and (max-width: 375px) {
  .index-page {
    overflow-x: hidden;
  }
  .pagination.pagination-primary .page-item:nth-of-type(3),
  .pagination.pagination-primary .page-item:nth-of-type(7) {
    display: none;
  }
  .pagination.pagination-info .page-item:nth-of-type(2) {
    display: none;
  }
  .main.main-raised {
    margin-top: -30px;
  }
  .form-group.is-focused .bootstrap-datetimepicker-widget {
    left: -32px !important;
  }
}
.show.modal .dropdown-toggle:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.bootstrap-datetimepicker-widget .timepicker .table-condesed .btn .ripple-container {
  width: 40px;
  height: 40px;
  margin: -11px 3px;
}
.bd-docs .bd-toc-item .bd-sidenav a span {
  float: right;
  margin-top: 5px;
  padding: 3px 7px;
  font-size: 8px;
  line-height: 9px;
  background-color: #32a8df;
}
.bd-docs .bd-content .bd-title .btn {
  padding: 7px 20px;
  margin-bottom: 10px;
}
.form-check {
  margin-bottom: .5rem;
  padding-left: 0;
}
.form-check .form-check-label {
  cursor: pointer;
  padding-left: 0;
}
.form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:hover,
.form-group.is-focused .form-check .form-check-label:focus {
  color: rgba(0, 0, 0, .54);
}
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-check .form-check-input {
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.form-check .form-check-sign {
  vertical-align: middle;
  position: relative;
  top: -2px;
  float: left;
  padding-right: 10px;
  display: inline-block;
}
.form-check .form-check-sign:before {
  display: block;
  position: absolute;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: 0;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-sign .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid rgba(0, 0, 0, .54);
  overflow: hidden;
  z-index: 1;
  border-radius: 3px;
}
.form-check .form-check-sign .check:before {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  display: block;
  margin-top: -3px;
  margin-left: 7px;
  width: 0;
  color: #ffffff;
  height: 0;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
  -webkit-animation: checkbox-off 0.3s forwards;
  -moz-animation: checkbox-off 0.3s forwards;
  -o-animation: checkbox-off 0.3s forwards;
  -ms-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}
.form-check .form-check-input:focus+.form-check-sign .check:after {
  opacity: 0.2;
}
.form-check .form-check-input:checked~.form-check-sign .check {
  background: #32a8df;
}
.form-check .form-check-input:checked~.form-check-sign .check:before {
  color: #fff;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  -webkit-animation: checkbox-on 0.3s forwards;
  -moz-animation: checkbox-on 0.3s forwards;
  -o-animation: checkbox-on 0.3s forwards;
  -ms-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}
.form-check .form-check-input:checked~.form-check-sign:before {
  -webkit-animation: rippleOn 500ms;
  -moz-animation: rippleOn 500ms;
  -o-animation: rippleOn 500ms;
  -ms-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.form-check .form-check-input:checked~.form-check-sign .check:after {
  -webkit-animation: rippleOn 500ms forwards;
  -moz-animation: rippleOn 500ms forwards;
  -o-animation: rippleOn 500ms forwards;
  -ms-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}
.form-check .form-check-input:not(:checked)+.form-check-sign:before {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.form-check .form-check-input:not(:checked)+.form-check-sign .check:after {
  -webkit-animation: rippleOff 500ms;
  -moz-animation: rippleOff 500ms;
  -o-animation: rippleOff 500ms;
  -ms-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
fieldset[disabled] .form-check,
fieldset[disabled] .form-check .form-check-input,
.form-check .form-check-input[disabled]~.form-check-sign .check,
.form-check .form-check-input[disabled]+.circle {
  opacity: 0.5;
}
.form-check .form-check-input[disabled]~.form-check-sign .check {
  border-color: #000000;
  opacity: .26;
}
.form-check .form-check-input[disabled]+.form-check-sign .check:after {
  background-color: rgba(0, 0, 0, 0.87);
  transform: rotate(-45deg);
}
.form-check .form-check-input[disabled][checked]+.form-check-sign .check {
  background-color: #000;
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.switch label {
  position: relative;
  padding-left: 2.4375rem;
}
.switch label .bmd-switch-track {
  position: absolute;
  top: 0.3125rem;
  left: 0;
  display: inline-block;
  width: 2.125rem;
  height: 0.875rem;
  cursor: pointer;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 50%, #c49bcb 50%, #c49bcb 100%);
  background-position: 0%;
  background-size: 4.25rem 0.875rem;
  border-radius: 2.125rem;
  transition: background-position 0.2s ease-in;
}
.switch label .bmd-switch-track::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  align-self: center;
  width: 1.25rem;
  height: 1.25rem;
  content: "";
  background: #f1f1f1;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  transition: left 0.2s ease-in, background-color 0.2s ease-in, transform 0.3s ease;
  transform: translateY(-50%);
}
.switch label .bmd-switch-track:active::after {
  transform: translateY(-50%) scale3d(1.15, 0.85, 1);
}
.switch label input {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  opacity: 0;
}
.switch label input:checked+.bmd-switch-track {
  background-position: -100%;
}
.switch label input:checked+.bmd-switch-track::after {
  left: calc(100% - 1.25rem);
  background-color: #32a8df;
}
.switch label input:disabled+.bmd-switch-track:active::after {
  transform: translateY(-50%);
}
.switch label input:disabled+.bmd-switch-track {
  cursor: default;
  background: rgba(0, 0, 0, 0.12);
}
.switch label input:disabled+.bmd-switch-track::after {
  background: #bdbdbd;
}
.form-check .form-check-label {
  cursor: pointer;
  padding-left: 25px;
  position: relative;
}
.form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .form-check .form-check-label:hover,
.form-group.is-focused .form-check .form-check-label:focus {
  color: rgba(0, 0, 0, .54);
}
fieldset[disabled] .form-group.is-focused .form-check .form-check-label {
  color: rgba(0, 0, 0, 0.26);
}
.form-check .form-check-label span {
  display: block;
  position: absolute;
  left: -1px;
  top: -1px;
  transition-duration: 0.2s;
}
.form-check .form-check-label .circle {
  border: 1px solid rgba(0, 0, 0, .54);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  top: 1px;
}
.form-check .form-check-label .circle .check {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background-color: #32a8df;
  -webkit-transform: scale3d(0, 0, 0);
  -moz-transform: scale3d(0, 0, 0);
  -o-transform: scale3d(0, 0, 0);
  -ms-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.form-check .form-check-input {
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.form-check .form-check-input:checked~.check,
.form-check .form-check-input:checked~.circle {
  opacity: 1;
}
.form-check .form-check-input:checked~.check {
  background-color: #32a8df;
}
.form-check .form-check-input:checked~.circle {
  border-color: #32a8df;
}
.form-check .form-check-input:checked .check:before {
  animation: checkboxOn .5s forwards;
}
.form-check .form-check-input:checked~.circle .check {
  -webkit-transform: scale3d(0.65, 0.65, 1);
  -moz-transform: scale3d(0.65, 0.65, 1);
  -o-transform: scale3d(0.65, 0.65, 1);
  -ms-transform: scale3d(0.65, 0.65, 1);
  transform: scale3d(0.65, 0.65, 1);
}
.form-check .form-check-input[disabled]~.check,
.form-check .form-check-input[disabled]~.circle {
  opacity: 0.26;
}
.form-check .form-check-input[disabled]~.check {
  background-color: #000;
}
.form-check .form-check-input[disabled]~.circle {
  border-color: #000;
}
.form-check .form-check-input[disabled]+.circle .check {
  background-color: #000;
}
.form-check .form-check-sign {
  vertical-align: middle;
  position: relative;
  top: -2px;
  float: left;
  padding-right: 10px;
  display: inline-block;
}
.form-check .form-check-label .circle:before {
  display: block;
  position: absolute;
  left: -1px;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  margin: 0;
  top: -1px;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  -moz-transform: scale3d(2.3, 2.3, 1);
  -o-transform: scale3d(2.3, 2.3, 1);
  -ms-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.form-check .form-check-label .form-check-input:checked+.circle:before {
  animation: rippleOn .5s;
}
.form-check .form-check-label .form-check-input:checked+.circle .check:before {
  color: #FFFFFF;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  animation: checkboxOn 0.3s forwards;
}
.form-check+.form-check {
  margin-top: 0;
}
@keyframes checkboxOn {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
.carousel .carousel-control.left,
.carousel .carousel-control.right {
  background-image: none;
}
.carousel .carousel-control.right i {
  right: 0;
}
.carousel .card {
  margin-top: 0;
}
.carousel .item {
  text-align: center;
}
.carousel.carousel-full-navigation .carousel-control {
  width: 50%;
}
.carousel.carousel-full-navigation .carousel-control.left,
.carousel.carousel-full-navigation .carousel-control.right {
  background-image: none;
}
.carousel.carousel-full-navigation .carousel-control .material-icons,
.carousel.carousel-full-navigation .carousel-control .fa {
  display: none;
}
.carousel .carousel-control {
  height: 15%;
  margin: auto;
}
.carousel .carousel-control-prev .material-icons,
.carousel .carousel-control-next .material-icons {
  z-index: 5;
  display: inline-block;
  font-size: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  margin: auto;
}
.carousel .carousel-indicators {
  bottom: 5px;
}
/* .carousel .carousel-indicators li,
.carousel .carousel-indicators .active {
  margin: 11px 10px;
}
.carousel .carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  text-indent: -999px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}
.carousel .carousel-indicators .active {
  margin-top: 10px;
  -webkit-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -o-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
} */
.carousel .carousel-caption {
  padding-bottom: 45px;
}
.carousel .carousel-caption .material-icons {
  position: relative;
  top: 5px;
}
form {
  margin-bottom: 1.125rem;
}
.card form {
  margin: 0;
}
.navbar form {
  margin-bottom: 0;
}
.navbar form .bmd-form-group {
  display: inline-block;
  padding-top: 0;
}
.navbar form .btn {
  margin-bottom: 0;
}
.form-control {
  background: no-repeat center bottom, center calc(100% - 1px);
  background-size: 0 100%, 100% 100%;
  border: 0;
  height: 36px;
  transition: background 0s ease-out;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
  font-size: 14px;
}
.form-control:focus,
.bmd-form-group.is-focused .form-control {
  background-size: 100% 100%, 100% 100%;
  transition-duration: 0.3s;
  box-shadow: none;
}
.form-control::-moz-placeholder {
  color: #AAAAAA;
  font-weight: 400;
  font-size: 14px;
}
.form-control:-ms-input-placeholder {
  color: #AAAAAA;
  font-weight: 400;
  font-size: 14px;
}
.form-control::-webkit-input-placeholder {
  color: #AAAAAA;
  font-weight: 400;
  font-size: 14px;
}
.has-white .form-control::-moz-placeholder {
  color: #ffffff;
}
.has-white .form-control:-ms-input-placeholder {
  color: #ffffff;
}
.has-white .form-control::-webkit-input-placeholder {
  color: #ffffff;
}
.bmd-help {
  position: absolute;
  display: none;
  font-size: .8rem;
  font-weight: normal;
}
.bmd-form-group.is-focused .bmd-help {
  display: block;
}
.bmd-help:nth-of-type(2) {
  padding-top: 1rem;
}
.bmd-help+.bmd-help {
  position: relative;
  margin-bottom: 0;
}
.radio label,
.is-focused .radio label,
.radio-inline,
.is-focused .radio-inline,
.checkbox label,
.is-focused .checkbox label,
.checkbox-inline,
.is-focused .checkbox-inline,
.switch label,
.is-focused .switch label {
  color: #999999;
}
.radio label label:has(input[type=radio][disabled]),
.radio label label:has(input[type=radio][disabled]):hover,
.radio label label:has(input[type=radio][disabled]):focus,
.radio label label:has(input[type=checkbox][disabled]),
.radio label label:has(input[type=checkbox][disabled]):hover,
.radio label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .radio label,
fieldset[disabled] .radio label:hover,
fieldset[disabled] .radio label:focus,
.is-focused .radio label label:has(input[type=radio][disabled]),
.is-focused .radio label label:has(input[type=radio][disabled]):hover,
.is-focused .radio label label:has(input[type=radio][disabled]):focus,
.is-focused .radio label label:has(input[type=checkbox][disabled]),
.is-focused .radio label label:has(input[type=checkbox][disabled]):hover,
.is-focused .radio label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .radio label,
fieldset[disabled] .is-focused .radio label:hover,
fieldset[disabled] .is-focused .radio label:focus,
.radio-inline label:has(input[type=radio][disabled]),
.radio-inline label:has(input[type=radio][disabled]):hover,
.radio-inline label:has(input[type=radio][disabled]):focus,
.radio-inline label:has(input[type=checkbox][disabled]),
.radio-inline label:has(input[type=checkbox][disabled]):hover,
.radio-inline label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .radio-inline,
fieldset[disabled] .radio-inline:hover,
fieldset[disabled] .radio-inline:focus,
.is-focused .radio-inline label:has(input[type=radio][disabled]),
.is-focused .radio-inline label:has(input[type=radio][disabled]):hover,
.is-focused .radio-inline label:has(input[type=radio][disabled]):focus,
.is-focused .radio-inline label:has(input[type=checkbox][disabled]),
.is-focused .radio-inline label:has(input[type=checkbox][disabled]):hover,
.is-focused .radio-inline label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .radio-inline,
fieldset[disabled] .is-focused .radio-inline:hover,
fieldset[disabled] .is-focused .radio-inline:focus,
.checkbox label label:has(input[type=radio][disabled]),
.checkbox label label:has(input[type=radio][disabled]):hover,
.checkbox label label:has(input[type=radio][disabled]):focus,
.checkbox label label:has(input[type=checkbox][disabled]),
.checkbox label label:has(input[type=checkbox][disabled]):hover,
.checkbox label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .checkbox label,
fieldset[disabled] .checkbox label:hover,
fieldset[disabled] .checkbox label:focus,
.is-focused .checkbox label label:has(input[type=radio][disabled]),
.is-focused .checkbox label label:has(input[type=radio][disabled]):hover,
.is-focused .checkbox label label:has(input[type=radio][disabled]):focus,
.is-focused .checkbox label label:has(input[type=checkbox][disabled]),
.is-focused .checkbox label label:has(input[type=checkbox][disabled]):hover,
.is-focused .checkbox label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .checkbox label,
fieldset[disabled] .is-focused .checkbox label:hover,
fieldset[disabled] .is-focused .checkbox label:focus,
.checkbox-inline label:has(input[type=radio][disabled]),
.checkbox-inline label:has(input[type=radio][disabled]):hover,
.checkbox-inline label:has(input[type=radio][disabled]):focus,
.checkbox-inline label:has(input[type=checkbox][disabled]),
.checkbox-inline label:has(input[type=checkbox][disabled]):hover,
.checkbox-inline label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .checkbox-inline:hover,
fieldset[disabled] .checkbox-inline:focus,
.is-focused .checkbox-inline label:has(input[type=radio][disabled]),
.is-focused .checkbox-inline label:has(input[type=radio][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type=radio][disabled]):focus,
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]),
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]):hover,
.is-focused .checkbox-inline label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .checkbox-inline,
fieldset[disabled] .is-focused .checkbox-inline:hover,
fieldset[disabled] .is-focused .checkbox-inline:focus,
.switch label label:has(input[type=radio][disabled]),
.switch label label:has(input[type=radio][disabled]):hover,
.switch label label:has(input[type=radio][disabled]):focus,
.switch label label:has(input[type=checkbox][disabled]),
.switch label label:has(input[type=checkbox][disabled]):hover,
.switch label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .switch label,
fieldset[disabled] .switch label:hover,
fieldset[disabled] .switch label:focus,
.is-focused .switch label label:has(input[type=radio][disabled]),
.is-focused .switch label label:has(input[type=radio][disabled]):hover,
.is-focused .switch label label:has(input[type=radio][disabled]):focus,
.is-focused .switch label label:has(input[type=checkbox][disabled]),
.is-focused .switch label label:has(input[type=checkbox][disabled]):hover,
.is-focused .switch label label:has(input[type=checkbox][disabled]):focus,
fieldset[disabled] .is-focused .switch label,
fieldset[disabled] .is-focused .switch label:hover,
fieldset[disabled] .is-focused .switch label:focus {
  color: #999999;
}
[class^='bmd-label'],
[class*=' bmd-label'] {
  color: #999999;
}
.form-control,
.is-focused .form-control {
  background-image: linear-gradient(to top, #32a8df 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .form-control,
.form-control.disabled,
.form-control:disabled,
.form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.form-control.form-control-success,
.is-focused .form-control.form-control-success {
  background-image: linear-gradient(to top, #32a8df 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.form-control.form-control-warning,
.is-focused .form-control.form-control-warning {
  background-image: linear-gradient(to top, #32a8df 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.form-control.form-control-danger,
.is-focused .form-control.form-control-danger {
  background-image: linear-gradient(to top, #32a8df 2px, rgba(156, 39, 176, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #999999;
}
.is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(153, 153, 153, 0.8);
  border-radius: .2rem;
}
.was-validated .is-focused .form-control:valid,
.is-focused .form-control.is-valid,
.was-validated .is-focused .custom-select:valid,
.is-focused .custom-select.is-valid {
  border-color: #999999;
}
.was-validated .is-focused .form-control:valid:focus,
.is-focused .form-control.is-valid:focus,
.was-validated .is-focused .custom-select:valid:focus,
.is-focused .custom-select.is-valid:focus {
  border-color: #999999;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.was-validated .is-focused .form-control:valid~.valid-feedback,
.was-validated .is-focused .form-control:valid~.valid-tooltip,
.is-focused .form-control.is-valid~.valid-feedback,
.is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .is-focused .custom-select:valid~.valid-feedback,
.was-validated .is-focused .custom-select:valid~.valid-tooltip,
.is-focused .custom-select.is-valid~.valid-feedback,
.is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .is-focused .form-check-input:valid~.form-check-label,
.is-focused .form-check-input.is-valid~.form-check-label {
  color: #999999;
}
.was-validated .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .is-focused .form-check-input:valid~.valid-tooltip,
.is-focused .form-check-input.is-valid~.valid-feedback,
.is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .is-focused .custom-control-input:valid~.custom-control-label,
.is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #999999;
}
.was-validated .is-focused .custom-control-input:valid~.custom-control-label::before,
.is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #d9d9d9;
}
.was-validated .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .is-focused .custom-control-input:valid~.valid-tooltip,
.is-focused .custom-control-input.is-valid~.valid-feedback,
.is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #b3b3b3;
}
.was-validated .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.was-validated .is-focused .custom-file-input:valid~.custom-file-label,
.is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #999999;
}
.was-validated .is-focused .custom-file-input:valid~.custom-file-label::before,
.is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .is-focused .custom-file-input:valid~.valid-tooltip,
.is-focused .custom-file-input.is-valid~.valid-feedback,
.is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .is-focused .custom-file-input:valid:focus~.custom-file-label,
.is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.25);
}
.is-focused [class^='bmd-label'],
.is-focused [class*=' bmd-label'] {
  color: #32a8df;
}
.is-focused .bmd-label-placeholder {
  color: #999999;
}
.is-focused .form-control {
  border-color: #d2d2d2;
}
.is-focused .bmd-help {
  color: #555;
}
.has-success [class^='bmd-label'],
.has-success [class*=' bmd-label'] {
  color: #4caf50;
}
.has-success .form-control,
.is-focused .has-success .form-control {
  background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-success .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-success .form-control,
.has-success .form-control.disabled,
.has-success .form-control:disabled,
.has-success .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-success .form-control.form-control-success,
.is-focused .has-success .form-control.form-control-success {
  background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-success .form-control.form-control-warning,
.is-focused .has-success .form-control.form-control-warning {
  background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-success .form-control.form-control-danger,
.is-focused .has-success .form-control.form-control-danger {
  background-image: linear-gradient(to top, #4caf50 2px, rgba(76, 175, 80, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-success .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #4caf50;
}
.has-success .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(76, 175, 80, 0.8);
  border-radius: .2rem;
}
.was-validated .has-success .is-focused .form-control:valid,
.has-success .is-focused .form-control.is-valid,
.was-validated .has-success .is-focused .custom-select:valid,
.has-success .is-focused .custom-select.is-valid {
  border-color: #4caf50;
}
.was-validated .has-success .is-focused .form-control:valid:focus,
.has-success .is-focused .form-control.is-valid:focus,
.was-validated .has-success .is-focused .custom-select:valid:focus,
.has-success .is-focused .custom-select.is-valid:focus {
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .has-success .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-success .is-focused .form-control:valid~.valid-tooltip,
.has-success .is-focused .form-control.is-valid~.valid-feedback,
.has-success .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-success .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-select:valid~.valid-tooltip,
.has-success .is-focused .custom-select.is-valid~.valid-feedback,
.has-success .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-success .is-focused .form-check-input:valid~.form-check-label,
.has-success .is-focused .form-check-input.is-valid~.form-check-label {
  color: #4caf50;
}
.was-validated .has-success .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .form-check-input:valid~.valid-tooltip,
.has-success .is-focused .form-check-input.is-valid~.valid-feedback,
.has-success .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-success .is-focused .custom-control-input:valid~.custom-control-label,
.has-success .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #4caf50;
}
.was-validated .has-success .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-success .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #a3d7a5;
}
.was-validated .has-success .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-control-input:valid~.valid-tooltip,
.has-success .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-success .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-success .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-success .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #6ec071;
}
.was-validated .has-success .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-success .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.was-validated .has-success .is-focused .custom-file-input:valid~.custom-file-label,
.has-success .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #4caf50;
}
.was-validated .has-success .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-success .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-success .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-success .is-focused .custom-file-input:valid~.valid-tooltip,
.has-success .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-success .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-success .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-success .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.25);
}
.has-success .is-focused [class^='bmd-label'],
.has-success .is-focused [class*=' bmd-label'] {
  color: #4caf50;
}
.has-success .is-focused .bmd-label-placeholder {
  color: #4caf50;
}
.has-success .is-focused .form-control {
  border-color: #4caf50;
}
.has-success .is-focused .bmd-help {
  color: #555;
}
.has-info [class^='bmd-label'],
.has-info [class*=' bmd-label'] {
  color: #00bcd4;
}
.has-info .form-control,
.is-focused .has-info .form-control {
  background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-info .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-info .form-control,
.has-info .form-control.disabled,
.has-info .form-control:disabled,
.has-info .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-info .form-control.form-control-success,
.is-focused .has-info .form-control.form-control-success {
  background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-info .form-control.form-control-warning,
.is-focused .has-info .form-control.form-control-warning {
  background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-info .form-control.form-control-danger,
.is-focused .has-info .form-control.form-control-danger {
  background-image: linear-gradient(to top, #00bcd4 2px, rgba(0, 188, 212, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-info .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00bcd4;
}
.has-info .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(0, 188, 212, 0.8);
  border-radius: .2rem;
}
.was-validated .has-info .is-focused .form-control:valid,
.has-info .is-focused .form-control.is-valid,
.was-validated .has-info .is-focused .custom-select:valid,
.has-info .is-focused .custom-select.is-valid {
  border-color: #00bcd4;
}
.was-validated .has-info .is-focused .form-control:valid:focus,
.has-info .is-focused .form-control.is-valid:focus,
.was-validated .has-info .is-focused .custom-select:valid:focus,
.has-info .is-focused .custom-select.is-valid:focus {
  border-color: #00bcd4;
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.was-validated .has-info .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-info .is-focused .form-control:valid~.valid-tooltip,
.has-info .is-focused .form-control.is-valid~.valid-feedback,
.has-info .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-info .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-select:valid~.valid-tooltip,
.has-info .is-focused .custom-select.is-valid~.valid-feedback,
.has-info .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-info .is-focused .form-check-input:valid~.form-check-label,
.has-info .is-focused .form-check-input.is-valid~.form-check-label {
  color: #00bcd4;
}
.was-validated .has-info .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .form-check-input:valid~.valid-tooltip,
.has-info .is-focused .form-check-input.is-valid~.valid-feedback,
.has-info .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-info .is-focused .custom-control-input:valid~.custom-control-label,
.has-info .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #00bcd4;
}
.was-validated .has-info .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-info .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #55ecff;
}
.was-validated .has-info .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-control-input:valid~.valid-tooltip,
.has-info .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-info .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-info .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-info .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #08e3ff;
}
.was-validated .has-info .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-info .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.was-validated .has-info .is-focused .custom-file-input:valid~.custom-file-label,
.has-info .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #00bcd4;
}
.was-validated .has-info .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-info .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-info .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-info .is-focused .custom-file-input:valid~.valid-tooltip,
.has-info .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-info .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-info .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-info .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(0, 188, 212, 0.25);
}
.has-info .is-focused [class^='bmd-label'],
.has-info .is-focused [class*=' bmd-label'] {
  color: #00bcd4;
}
.has-info .is-focused .bmd-label-placeholder {
  color: #00bcd4;
}
.has-info .is-focused .form-control {
  border-color: #00bcd4;
}
.has-info .is-focused .bmd-help {
  color: #555;
}
.has-white [class^='bmd-label'],
.has-white [class*=' bmd-label'] {
  color: #ffffff;
}
.has-white .form-control,
.is-focused .has-white .form-control {
  background-image: linear-gradient(to top, #ffffff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px);
}
.has-white .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px);
}
fieldset[disabled][disabled] .has-white .form-control,
.has-white .form-control.disabled,
.has-white .form-control:disabled,
.has-white .form-control[disabled] {
  background-image: linear-gradient(to right, #FFFFFF 0%, #FFFFFF 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-white .form-control.form-control-success,
.is-focused .has-white .form-control.form-control-success {
  background-image: linear-gradient(to top, #ffffff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-white .form-control.form-control-warning,
.is-focused .has-white .form-control.form-control-warning {
  background-image: linear-gradient(to top, #ffffff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-white .form-control.form-control-danger,
.is-focused .has-white .form-control.form-control-danger {
  background-image: linear-gradient(to top, #ffffff 2px, rgba(255, 255, 255, 0) 2px), linear-gradient(to top, #FFFFFF 1px, rgba(255, 255, 255, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-white .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ffffff;
}
.has-white .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: .2rem;
}
.was-validated .has-white .is-focused .form-control:valid,
.has-white .is-focused .form-control.is-valid,
.was-validated .has-white .is-focused .custom-select:valid,
.has-white .is-focused .custom-select.is-valid {
  border-color: #ffffff;
}
.was-validated .has-white .is-focused .form-control:valid:focus,
.has-white .is-focused .form-control.is-valid:focus,
.was-validated .has-white .is-focused .custom-select:valid:focus,
.has-white .is-focused .custom-select.is-valid:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.was-validated .has-white .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-white .is-focused .form-control:valid~.valid-tooltip,
.has-white .is-focused .form-control.is-valid~.valid-feedback,
.has-white .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-white .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-select:valid~.valid-tooltip,
.has-white .is-focused .custom-select.is-valid~.valid-feedback,
.has-white .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-white .is-focused .form-check-input:valid~.form-check-label,
.has-white .is-focused .form-check-input.is-valid~.form-check-label {
  color: #ffffff;
}
.was-validated .has-white .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .form-check-input:valid~.valid-tooltip,
.has-white .is-focused .form-check-input.is-valid~.valid-feedback,
.has-white .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-white .is-focused .custom-control-input:valid~.custom-control-label,
.has-white .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #ffffff;
}
.was-validated .has-white .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-white .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: white;
}
.was-validated .has-white .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-control-input:valid~.valid-tooltip,
.has-white .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-white .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-white .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-white .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: white;
}
.was-validated .has-white .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-white .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.was-validated .has-white .is-focused .custom-file-input:valid~.custom-file-label,
.has-white .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #ffffff;
}
.was-validated .has-white .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-white .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-white .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-white .is-focused .custom-file-input:valid~.valid-tooltip,
.has-white .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-white .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-white .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-white .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}
.has-white .is-focused [class^='bmd-label'],
.has-white .is-focused [class*=' bmd-label'] {
  color: #ffffff;
}
.has-white .is-focused .bmd-label-placeholder {
  color: #ffffff;
}
.has-white .is-focused .form-control {
  border-color: #ffffff;
}
.has-white .is-focused .bmd-help {
  color: #555;
}
.has-white .form-control:focus {
  color: #ffffff;
}
.has-warning [class^='bmd-label'],
.has-warning [class*=' bmd-label'] {
  color: #ff9800;
}
.has-warning .form-control,
.is-focused .has-warning .form-control {
  background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-warning .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-warning .form-control,
.has-warning .form-control.disabled,
.has-warning .form-control:disabled,
.has-warning .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-warning .form-control.form-control-success,
.is-focused .has-warning .form-control.form-control-success {
  background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-warning .form-control.form-control-warning,
.is-focused .has-warning .form-control.form-control-warning {
  background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-warning .form-control.form-control-danger,
.is-focused .has-warning .form-control.form-control-danger {
  background-image: linear-gradient(to top, #ff9800 2px, rgba(255, 152, 0, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-warning .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #ff9800;
}
.has-warning .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(255, 152, 0, 0.8);
  border-radius: .2rem;
}
.was-validated .has-warning .is-focused .form-control:valid,
.has-warning .is-focused .form-control.is-valid,
.was-validated .has-warning .is-focused .custom-select:valid,
.has-warning .is-focused .custom-select.is-valid {
  border-color: #ff9800;
}
.was-validated .has-warning .is-focused .form-control:valid:focus,
.has-warning .is-focused .form-control.is-valid:focus,
.was-validated .has-warning .is-focused .custom-select:valid:focus,
.has-warning .is-focused .custom-select.is-valid:focus {
  border-color: #ff9800;
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.was-validated .has-warning .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-warning .is-focused .form-control:valid~.valid-tooltip,
.has-warning .is-focused .form-control.is-valid~.valid-feedback,
.has-warning .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-warning .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-select:valid~.valid-tooltip,
.has-warning .is-focused .custom-select.is-valid~.valid-feedback,
.has-warning .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-warning .is-focused .form-check-input:valid~.form-check-label,
.has-warning .is-focused .form-check-input.is-valid~.form-check-label {
  color: #ff9800;
}
.was-validated .has-warning .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .form-check-input:valid~.valid-tooltip,
.has-warning .is-focused .form-check-input.is-valid~.valid-feedback,
.has-warning .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-warning .is-focused .custom-control-input:valid~.custom-control-label,
.has-warning .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #ff9800;
}
.was-validated .has-warning .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-warning .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #ffcc80;
}
.was-validated .has-warning .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-control-input:valid~.valid-tooltip,
.has-warning .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-warning .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-warning .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-warning .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #ffad33;
}
.was-validated .has-warning .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-warning .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.was-validated .has-warning .is-focused .custom-file-input:valid~.custom-file-label,
.has-warning .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #ff9800;
}
.was-validated .has-warning .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-warning .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-warning .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-warning .is-focused .custom-file-input:valid~.valid-tooltip,
.has-warning .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-warning .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-warning .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-warning .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.25);
}
.has-warning .is-focused [class^='bmd-label'],
.has-warning .is-focused [class*=' bmd-label'] {
  color: #ff9800;
}
.has-warning .is-focused .bmd-label-placeholder {
  color: #ff9800;
}
.has-warning .is-focused .form-control {
  border-color: #ff9800;
}
.has-warning .is-focused .bmd-help {
  color: #555;
}
.has-danger [class^='bmd-label'],
.has-danger [class*=' bmd-label'] {
  color: #f44336;
}
.has-danger .form-control,
.is-focused .has-danger .form-control {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-danger .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-danger .form-control,
.has-danger .form-control.disabled,
.has-danger .form-control:disabled,
.has-danger .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-danger .form-control.form-control-success,
.is-focused .has-danger .form-control.form-control-success {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-danger .form-control.form-control-warning,
.is-focused .has-danger .form-control.form-control-warning {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-danger .form-control.form-control-danger,
.is-focused .has-danger .form-control.form-control-danger {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-danger .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f44336;
}
.has-danger .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(244, 67, 54, 0.8);
  border-radius: .2rem;
}
.was-validated .has-danger .is-focused .form-control:valid,
.has-danger .is-focused .form-control.is-valid,
.was-validated .has-danger .is-focused .custom-select:valid,
.has-danger .is-focused .custom-select.is-valid {
  border-color: #f44336;
}
.was-validated .has-danger .is-focused .form-control:valid:focus,
.has-danger .is-focused .form-control.is-valid:focus,
.was-validated .has-danger .is-focused .custom-select:valid:focus,
.has-danger .is-focused .custom-select.is-valid:focus {
  border-color: #f44336;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .has-danger .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-danger .is-focused .form-control:valid~.valid-tooltip,
.has-danger .is-focused .form-control.is-valid~.valid-feedback,
.has-danger .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-danger .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-select:valid~.valid-tooltip,
.has-danger .is-focused .custom-select.is-valid~.valid-feedback,
.has-danger .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-danger .is-focused .form-check-input:valid~.form-check-label,
.has-danger .is-focused .form-check-input.is-valid~.form-check-label {
  color: #f44336;
}
.was-validated .has-danger .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .form-check-input:valid~.valid-tooltip,
.has-danger .is-focused .form-check-input.is-valid~.valid-feedback,
.has-danger .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-danger .is-focused .custom-control-input:valid~.custom-control-label,
.has-danger .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #f44336;
}
.was-validated .has-danger .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-danger .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #fbb4af;
}
.was-validated .has-danger .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-control-input:valid~.valid-tooltip,
.has-danger .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-danger .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-danger .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-danger .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #f77066;
}
.was-validated .has-danger .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-danger .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.was-validated .has-danger .is-focused .custom-file-input:valid~.custom-file-label,
.has-danger .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #f44336;
}
.was-validated .has-danger .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-danger .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-danger .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-danger .is-focused .custom-file-input:valid~.valid-tooltip,
.has-danger .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-danger .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-danger .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-danger .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.25);
}
.has-danger .is-focused [class^='bmd-label'],
.has-danger .is-focused [class*=' bmd-label'] {
  color: #f44336;
}
.has-danger .is-focused .bmd-label-placeholder {
  color: #f44336;
}
.has-danger .is-focused .form-control {
  border-color: #f44336;
}
.has-danger .is-focused .bmd-help {
  color: #555;
}
.has-rose [class^='bmd-label'],
.has-rose [class*=' bmd-label'] {
  color: #e91e63;
}
.has-rose .form-control,
.is-focused .has-rose .form-control {
  background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control:invalid {
  background-image: linear-gradient(to top, #f44336 2px, rgba(244, 67, 54, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
.has-rose .form-control:read-only {
  background-image: linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px);
}
fieldset[disabled][disabled] .has-rose .form-control,
.has-rose .form-control.disabled,
.has-rose .form-control:disabled,
.has-rose .form-control[disabled] {
  background-image: linear-gradient(to right, #d2d2d2 0%, #d2d2d2 30%, transparent 30%, transparent 100%);
  background-repeat: repeat-x;
  background-size: 3px 1px;
}
.has-rose .form-control.form-control-success,
.is-focused .has-rose .form-control.form-control-success {
  background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjNWNiODVjIiBkPSJNMjMzLjggNjEwYy0xMy4zIDAtMjYtNi0zNC0xNi44TDkwLjUgNDQ4LjhDNzYuMyA0MzAgODAgNDAzLjMgOTguOCAzODljMTguOC0xNC4yIDQ1LjUtMTAuNCA1OS44IDguNGw3MiA5NUw0NTEuMyAyNDJjMTIuNS0yMCAzOC44LTI2LjIgNTguOC0xMy43IDIwIDEyLjQgMjYgMzguNyAxMy43IDU4LjhMMjcwIDU5MGMtNy40IDEyLTIwLjIgMTkuNC0zNC4zIDIwaC0yeiIvPjwvc3ZnPg==";
}
.has-rose .form-control.form-control-warning,
.is-focused .has-rose .form-control.form-control-warning {
  background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZjBhZDRlIiBkPSJNNjAzIDY0MC4ybC0yNzguNS01MDljLTMuOC02LjYtMTAuOC0xMC42LTE4LjUtMTAuNnMtMTQuNyA0LTE4LjUgMTAuNkw5IDY0MC4yYy0zLjcgNi41LTMuNiAxNC40LjIgMjAuOCAzLjggNi41IDEwLjggMTAuNCAxOC4zIDEwLjRoNTU3YzcuNiAwIDE0LjYtNCAxOC40LTEwLjQgMy41LTYuNCAzLjYtMTQuNCAwLTIwLjh6bS0yNjYuNC0zMGgtNjEuMlY1NDloNjEuMnY2MS4yem0wLTEwN2gtNjEuMlYzMDRoNjEuMnYxOTl6Ii8+PC9zdmc+";
}
.has-rose .form-control.form-control-danger,
.is-focused .has-rose .form-control.form-control-danger {
  background-image: linear-gradient(to top, #e91e63 2px, rgba(233, 30, 99, 0) 2px), linear-gradient(to top, #d2d2d2 1px, rgba(210, 210, 210, 0) 1px), "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MTIgNzkyIj48cGF0aCBmaWxsPSIjZDk1MzRmIiBkPSJNNDQ3IDU0NC40Yy0xNC40IDE0LjQtMzcuNiAxNC40LTUyIDBsLTg5LTkyLjctODkgOTIuN2MtMTQuNSAxNC40LTM3LjcgMTQuNC01MiAwLTE0LjQtMTQuNC0xNC40LTM3LjYgMC01Mmw5Mi40LTk2LjMtOTIuNC05Ni4zYy0xNC40LTE0LjQtMTQuNC0zNy42IDAtNTJzMzcuNi0xNC4zIDUyIDBsODkgOTIuOCA4OS4yLTkyLjdjMTQuNC0xNC40IDM3LjYtMTQuNCA1MiAwIDE0LjMgMTQuNCAxNC4zIDM3LjYgMCA1MkwzNTQuNiAzOTZsOTIuNCA5Ni40YzE0LjQgMTQuNCAxNC40IDM3LjYgMCA1MnoiLz48L3N2Zz4=";
}
.has-rose .is-focused .valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e91e63;
}
.has-rose .is-focused .valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(233, 30, 99, 0.8);
  border-radius: .2rem;
}
.was-validated .has-rose .is-focused .form-control:valid,
.has-rose .is-focused .form-control.is-valid,
.was-validated .has-rose .is-focused .custom-select:valid,
.has-rose .is-focused .custom-select.is-valid {
  border-color: #e91e63;
}
.was-validated .has-rose .is-focused .form-control:valid:focus,
.has-rose .is-focused .form-control.is-valid:focus,
.was-validated .has-rose .is-focused .custom-select:valid:focus,
.has-rose .is-focused .custom-select.is-valid:focus {
  border-color: #e91e63;
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.was-validated .has-rose .is-focused .form-control:valid~.valid-feedback,
.was-validated .has-rose .is-focused .form-control:valid~.valid-tooltip,
.has-rose .is-focused .form-control.is-valid~.valid-feedback,
.has-rose .is-focused .form-control.is-valid~.valid-tooltip,
.was-validated .has-rose .is-focused .custom-select:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-select:valid~.valid-tooltip,
.has-rose .is-focused .custom-select.is-valid~.valid-feedback,
.has-rose .is-focused .custom-select.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-rose .is-focused .form-check-input:valid~.form-check-label,
.has-rose .is-focused .form-check-input.is-valid~.form-check-label {
  color: #e91e63;
}
.was-validated .has-rose .is-focused .form-check-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .form-check-input:valid~.valid-tooltip,
.has-rose .is-focused .form-check-input.is-valid~.valid-feedback,
.has-rose .is-focused .form-check-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-rose .is-focused .custom-control-input:valid~.custom-control-label,
.has-rose .is-focused .custom-control-input.is-valid~.custom-control-label {
  color: #e91e63;
}
.was-validated .has-rose .is-focused .custom-control-input:valid~.custom-control-label::before,
.has-rose .is-focused .custom-control-input.is-valid~.custom-control-label::before {
  background-color: #f492b4;
}
.was-validated .has-rose .is-focused .custom-control-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-control-input:valid~.valid-tooltip,
.has-rose .is-focused .custom-control-input.is-valid~.valid-feedback,
.has-rose .is-focused .custom-control-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-rose .is-focused .custom-control-input:valid:checked~.custom-control-label::before,
.has-rose .is-focused .custom-control-input.is-valid:checked~.custom-control-label::before {
  background-color: #ee4c83;
}
.was-validated .has-rose .is-focused .custom-control-input:valid:focus~.custom-control-label::before,
.has-rose .is-focused .custom-control-input.is-valid:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fafafa, 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.was-validated .has-rose .is-focused .custom-file-input:valid~.custom-file-label,
.has-rose .is-focused .custom-file-input.is-valid~.custom-file-label {
  border-color: #e91e63;
}
.was-validated .has-rose .is-focused .custom-file-input:valid~.custom-file-label::before,
.has-rose .is-focused .custom-file-input.is-valid~.custom-file-label::before {
  border-color: inherit;
}
.was-validated .has-rose .is-focused .custom-file-input:valid~.valid-feedback,
.was-validated .has-rose .is-focused .custom-file-input:valid~.valid-tooltip,
.has-rose .is-focused .custom-file-input.is-valid~.valid-feedback,
.has-rose .is-focused .custom-file-input.is-valid~.valid-tooltip {
  display: block;
}
.was-validated .has-rose .is-focused .custom-file-input:valid:focus~.custom-file-label,
.has-rose .is-focused .custom-file-input.is-valid:focus~.custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.25);
}
.has-rose .is-focused [class^='bmd-label'],
.has-rose .is-focused [class*=' bmd-label'] {
  color: #e91e63;
}
.has-rose .is-focused .bmd-label-placeholder {
  color: #e91e63;
}
.has-rose .is-focused .form-control {
  border-color: #e91e63;
}
.has-rose .is-focused .bmd-help {
  color: #555;
}
.bmd-form-group {
  position: relative;
  padding-top: 27px;
}
.bmd-form-group:not(.has-success):not(.has-danger) [class^='bmd-label'].bmd-label-floating,
.bmd-form-group:not(.has-success):not(.has-danger) [class*=' bmd-label'].bmd-label-floating {
  color: #AAAAAA;
}
.bmd-form-group [class^='bmd-label'],
.bmd-form-group [class*=' bmd-label'] {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}
.bmd-form-group [class^='bmd-label'].bmd-label-floating,
.bmd-form-group [class*=' bmd-label'].bmd-label-floating {
  will-change: left, top, contents;
  margin: 0;
  line-height: 1.4;
  font-weight: 400;
}
.bmd-form-group.is-filled .bmd-label-placeholder {
  display: none;
}
.bmd-form-group.bmd-collapse-inline {
  display: flex;
  align-items: center;
  padding: 0;
  min-height: 2.1em;
}
.bmd-form-group.bmd-collapse-inline .collapse {
  flex: 1;
  display: none;
}
.bmd-form-group.bmd-collapse-inline .collapse.show {
  max-width: 1200px;
}
.bmd-form-group.bmd-collapse-inline .collapsing,
.bmd-form-group.bmd-collapse-inline .width:not(.collapse),
.bmd-form-group.bmd-collapse-inline .collapse.show {
  display: block;
}
.bmd-form-group.bmd-collapse-inline .collapsing {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-form-group .form-control,
.bmd-form-group label,
.bmd-form-group input::placeholder {
  line-height: 1.1;
}
.bmd-form-group label {
  color: #AAAAAA;
}
.bmd-form-group .radio label,
.bmd-form-group label.radio-inline,
.bmd-form-group .checkbox label,
.bmd-form-group label.checkbox-inline,
.bmd-form-group .switch label {
  line-height: 1.5;
}
.bmd-form-group .checkbox label,
.bmd-form-group .radio label,
.bmd-form-group label {
  font-size: 0.875rem;
}
.bmd-form-group .bmd-label-floating,
.bmd-form-group .bmd-label-placeholder {
  top: 2.3125rem;
}
.bmd-form-group .is-focused .bmd-label-floating,
.bmd-form-group .is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group .bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group .bmd-help {
  margin-top: 0;
  font-size: 0.75rem;
}
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger,
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.46875rem;
}
.bmd-form-group .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-success,
.bmd-form-group .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-warning,
.bmd-form-group .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.bmd-form-group.bmd-form-group-sm .form-control,
.bmd-form-group.bmd-form-group-sm label,
.bmd-form-group.bmd-form-group-sm input::placeholder {
  line-height: 1.1;
}
.bmd-form-group.bmd-form-group-sm label {
  color: #AAAAAA;
}
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm label.radio-inline,
.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm label.checkbox-inline,
.bmd-form-group.bmd-form-group-sm .switch label {
  line-height: 1.5;
}
.bmd-form-group.bmd-form-group-sm .checkbox label,
.bmd-form-group.bmd-form-group-sm .radio label,
.bmd-form-group.bmd-form-group-sm label {
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .bmd-label-placeholder {
  top: 1.875rem;
}
.bmd-form-group.bmd-form-group-sm .is-focused .bmd-label-floating,
.bmd-form-group.bmd-form-group-sm .is-filled .bmd-label-floating {
  top: 0.75rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-label-static {
  top: 0.1rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-sm .bmd-help {
  margin-top: 0;
  font-size: 0.65625rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.6875rem 0.6875rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.34375rem;
}
.bmd-form-group.bmd-form-group-sm .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-success,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-sm .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-sm .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.6875rem 0.6875rem;
}
.bmd-form-group.bmd-form-group-lg .form-control,
.bmd-form-group.bmd-form-group-lg label,
.bmd-form-group.bmd-form-group-lg input::placeholder {
  line-height: 1.1;
}
.bmd-form-group.bmd-form-group-lg label {
  color: #AAAAAA;
}
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg label.radio-inline,
.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg label.checkbox-inline,
.bmd-form-group.bmd-form-group-lg .switch label {
  line-height: 1.5;
}
.bmd-form-group.bmd-form-group-lg .checkbox label,
.bmd-form-group.bmd-form-group-lg .radio label,
.bmd-form-group.bmd-form-group-lg label {
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .bmd-label-placeholder {
  top: 2.4375rem;
}
.bmd-form-group.bmd-form-group-lg .is-focused .bmd-label-floating,
.bmd-form-group.bmd-form-group-lg .is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-form-group.bmd-form-group-lg .bmd-help {
  margin-top: 0;
  font-size: 0.9375rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 1.1875rem 1.1875rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.59375rem;
}
.bmd-form-group.bmd-form-group-lg .form-control.form-control-success:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-success,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-warning:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-warning,
.bmd-form-group.bmd-form-group-lg .form-control.form-control-danger:focus,
.bmd-form-group.is-focused .bmd-form-group.bmd-form-group-lg .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 1.1875rem 1.1875rem;
}
.form-control,
label,
input::placeholder {
  line-height: 1.1;
}
label {
  color: #AAAAAA;
}
.radio label,
label.radio-inline,
.checkbox label,
label.checkbox-inline,
.switch label {
  line-height: 1.5;
}
.checkbox label,
.radio label,
label {
  font-size: 0.875rem;
}
.bmd-label-floating,
.bmd-label-placeholder {
  top: 2.3125rem;
}
.is-focused .bmd-label-floating,
.is-filled .bmd-label-floating {
  top: 1rem;
  left: 0;
  font-size: 0.6875rem;
}
.bmd-label-static {
  top: 0.35rem;
  left: 0;
  font-size: 0.875rem;
}
.bmd-help {
  margin-top: 0;
  font-size: 0.75rem;
}
.form-control.form-control-success,
.form-control.form-control-warning,
.form-control.form-control-danger {
  background-size: 0 100%, 100% 100%, 0.9375rem 0.9375rem;
}
.form-control.form-control-success,
.form-control.form-control-success:focus,
.bmd-form-group.is-focused .form-control.form-control-success,
.form-control.form-control-warning,
.form-control.form-control-warning:focus,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger,
.form-control.form-control-danger:focus,
.bmd-form-group.is-focused .form-control.form-control-danger {
  padding-right: 0;
  background-repeat: no-repeat, no-repeat;
  background-position: center bottom, center calc(100% - 1px), center right 0.46875rem;
}
.form-control.form-control-success:focus,
.bmd-form-group.is-focused .form-control.form-control-success,
.form-control.form-control-warning:focus,
.bmd-form-group.is-focused .form-control.form-control-warning,
.form-control.form-control-danger:focus,
.bmd-form-group.is-focused .form-control.form-control-danger {
  background-size: 100% 100%, 100% 100%, 0.9375rem 0.9375rem;
}
select,
select.form-control {
  -moz-appearance: none;
  -webkit-appearance: none;
}
@media (min-width: 576px) {
  .form-inline .input-group {
    display: inline-flex;
    align-items: center;
  }
}
.form-control-feedback {
  position: absolute;
  top: 33px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
}
.has-success .form-control-feedback {
  color: #4caf50;
  opacity: 1;
}
.has-danger .form-control-feedback {
  color: #f44336;
  opacity: 1;
}
.form-group {
  margin-bottom: 17px;
  position: relative;
}
textarea {
  height: auto !important;
  resize: none;
  line-height: 1.428571 !important;
}
.form-group input[type=file] {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.form-newsletter .input-group,
.form-newsletter .form-group {
  float: left;
  width: 78%;
  margin-right: 2%;
  margin-top: 9px;
  padding-top: 5px;
}
.form-newsletter .btn {
  float: left;
  width: 20%;
  margin: 9px 0 0;
}
.form-file-upload .input-group-btn:last-child>.btn-round {
  border-radius: 30px;
}
.form-file-upload .input-group-btn .btn {
  margin: 0;
}
.form-file-upload .input-group {
  width: 100%;
}
.input-group .input-group-btn {
  padding: 0 12px;
}
.input-group .input-group-text {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px 0 15px;
  background-color: transparent;
  border-color: transparent;
}
.input-group .input-group-btn {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 0;
}
.list-group {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: .5rem 0;
}
.list-group.bmd-list-group-sm {
  padding: .25rem 0;
}
.list-group.bmd-list-group-sm .list-group-item {
  padding: .5rem 1rem;
}
.bmd-list-group-col {
  display: flex;
  flex-direction: column;
  min-width: 0;
}
.list-group-item {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 1rem;
  line-height: 1;
}
.list-group-item .list-group-item-text {
  min-width: 0;
  max-height: 2.188rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-group-item :first-child {
  margin-right: 2rem;
}
.list-group-item>.pull-xs-right,
.list-group-item>.pull-sm-right,
.list-group-item>.pull-md-right,
.list-group-item>.pull-lg-right,
.list-group-item>.pull-xl-right,
.list-group-item>*~.label:last-child,
.list-group-item>*~.material-icons:last-child {
  margin-right: 0;
  margin-left: auto;
}
.list-group-item .material-icons.pull-xs-right,
.list-group-item .material-icons.pull-sm-right,
.list-group-item .material-icons.pull-md-right,
.list-group-item .material-icons.pull-lg-right,
.list-group-item .material-icons.pull-xl-right,
.list-group-item .material-icons~.material-icons:last-child {
  padding-left: 1rem;
}
.list-group-item .list-group-item-text {
  font-size: .875rem;
  color: #555;
}
.nav-link {
  text-transform: uppercase;
}
.navbar-nav .nav-link {
  padding: 0.5321rem;
  font-size: 0.875rem;
  font-weight: 400;
}
.nav-tabs,
.nav-pills {
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
}
.nav-tabs .nav-link,
.nav-pills .nav-link {
  padding: 1.4286em 0.8575em;
  font-size: 0.875rem;
  font-weight: 500;
  border: 0;
}
.nav .nav-item {
  position: relative;
}
.img-thumbnail {
  border-radius: 16px;
}
.img-raised {
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.rounded {
  border-radius: 6px !important;
}
.navbar,
.makeStyles-appBar-6 {
  border: 0 !important;
  border-radius: 0 !important;
  padding: 15px 0px !important;
  margin-bottom: 20px;
  color: #fff !important;
  background-color: #1e2023 !important;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15);
}
.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 255, 255, 0.4);
  background-color: #ffffff;
  color: #555;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-color: #555;
}
.navbar.fixed-top {
  border-radius: 0;
}
.navbar .navbar-nav .nav-item .nav-link {
  position: relative;
  color: inherit;
  padding: 0.9375rem;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  line-height: 20px;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn-just-icon) .fa {
  position: relative;
  top: 2px;
  margin-top: -4px;
  margin-right: 4px;
}
.navbar .navbar-nav .nav-item .nav-link .material-icons,
.navbar .navbar-nav .nav-item .nav-link .fa {
  font-size: 1.25rem;
  max-width: 24px;
  margin-top: -1.1em;
}
.navbar .navbar-nav .nav-item .nav-link:not(.btn) .material-icons {
  margin-top: -7px;
  top: 3px;
  position: relative;
  margin-right: 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo {
  padding: 0;
  margin: 0 3px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo:after {
  display: none;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .profile-photo-small {
  height: 40px;
  width: 40px;
}
.navbar .navbar-nav .nav-item .nav-link.profile-photo .ripple-container {
  border-radius: 50%;
}
.navbar .navbar-nav .dropdown-menu-right {
  transform-origin: 100% 0;
}
.navbar .navbar-nav .nav-item.active .nav-link,
.navbar .navbar-nav .nav-item.active .nav-link:hover,
.navbar .navbar-nav .nav-item.active .nav-link:focus {
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar .btn,
.navbar .navbar-nav .nav-item .btn {
  margin-top: 0;
  margin-bottom: 0;
}
.navbar .navbar-toggler {
  cursor: pointer;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  position: relative;
  width: 22px;
  height: 2px;
  vertical-align: middle;
  outline: 0;
  display: block;
  border-radius: 1px;
}
.navbar .navbar-toggler .navbar-toggler-icon+.navbar-toggler-icon {
  margin-top: 4px;
}
.navbar.navbar-absolute {
  position: absolute;
  width: 100%;
  padding-top: 10px;
  z-index: 1029;
}
.navbar .navbar-wrapper {
  display: inline-flex;
  align-items: center;
}
.navbar .navbar-brand {
  position: relative;
  color: inherit;
  height: 50px;
  font-size: 1.125rem;
  line-height: 30px;
  padding: 0.625rem 0;
}
.navbar.bg-primary {
  color: #ffffff;
  background-color: #32a8df !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46);
}
.navbar.bg-primary .dropdown-item:hover,
.navbar.bg-primary .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #32a8df;
  color: #ffffff;
}
.navbar.bg-primary .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-info {
  color: #ffffff;
  background-color: #00bcd4 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46);
}
.navbar.bg-info .dropdown-item:hover,
.navbar.bg-info .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4);
  background-color: #00bcd4;
  color: #ffffff;
}
.navbar.bg-info .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-warning {
  color: #ffffff;
  background-color: #ff9800 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46);
}
.navbar.bg-warning .dropdown-item:hover,
.navbar.bg-warning .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4);
  background-color: #ff9800;
  color: #ffffff;
}
.navbar.bg-warning .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-rose {
  color: #ffffff;
  background-color: #e91e63 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46);
}
.navbar.bg-rose .dropdown-item:hover,
.navbar.bg-rose .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(233, 30, 99, 0.4);
  background-color: #e91e63;
  color: #ffffff;
}
.navbar.bg-rose .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-danger {
  color: #ffffff;
  background-color: #f44336 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46);
}
.navbar.bg-danger .dropdown-item:hover,
.navbar.bg-danger .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4);
  background-color: #f44336;
  color: #ffffff;
}
.navbar.bg-danger .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-success {
  color: #ffffff;
  background-color: #4caf50 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46);
}
.navbar.bg-success .dropdown-item:hover,
.navbar.bg-success .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
  background-color: #4caf50;
  color: #ffffff;
}
.navbar.bg-success .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.bg-dark {
  color: #ffffff;
  background-color: #212121 !important;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46);
}
.navbar.bg-dark .dropdown-item:hover,
.navbar.bg-dark .dropdown-item:focus {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(33, 33, 33, 0.4);
  background-color: #212121;
  color: #ffffff;
}
.navbar.bg-dark .navbar-toggler .navbar-toggler-icon {
  background-color: #ffffff;
}
.navbar.navbar-transparent {
  background-color: transparent !important;
  box-shadow: none;
  padding-top: 25px;
  color: #ffffff;
}
#navbar .navbar {
  border-radius: 0;
}
.badge {
  padding: 5px 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: #ffffff;
}
.badge.badge-primary {
  background-color: #32a8df;
}
.badge.badge-info {
  background-color: #00bcd4;
}
.badge.badge-success {
  background-color: #4caf50;
}
.badge.badge-warning {
  background-color: #ff9800;
}
.badge.badge-danger {
  background-color: #f44336;
}
.badge.badge-rose {
  background-color: #e91e63;
}
.badge.badge-default {
  background-color: #999;
}
.badge-default[href]:hover,
.badge-default[href]:focus {
  background-color: #c1c1c1;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: #32a8df;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: #00a5bb;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: #449d48;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: #e68900;
  color: #ffffff;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: #f32c1e;
}
.badge-rose[href]:hover,
.badge-rose[href]:focus {
  background-color: #d81558;
}
.alert {
  border: 0;
  border-radius: 0;
  padding: 20px 15px;
  line-height: 20px;
}
.alert b {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
}
.alert,
.alert.alert-default {
  background-color: white;
  color: #555;
}
.alert a,
.alert .alert-link,
.alert.alert-default a,
.alert.alert-default .alert-link {
  color: #555;
}
.alert.alert-inverse {
  background-color: #292929;
  color: #fff;
}
.alert.alert-inverse a,
.alert.alert-inverse .alert-link {
  color: #fff;
}
.alert.alert-primary {
  background-color: #a72abd;
  color: #fff;
}
.alert.alert-primary a,
.alert.alert-primary .alert-link {
  color: #fff;
}
.alert.alert-success {
  background-color: #55b559;
  color: #fff;
}
.alert.alert-success a,
.alert.alert-success .alert-link {
  color: #fff;
}
.alert.alert-info {
  background-color: #00cae3;
  color: #fff;
}
.alert.alert-info a,
.alert.alert-info .alert-link {
  color: #fff;
}
.alert.alert-warning {
  background-color: #fff6df;
  color: #000000;
}
.alert.alert-warning a,
.alert.alert-warning .alert-link {
  color: #fff;
}
.alert.alert-danger {
  background-color: #f55145;
  color: #fff;
}
.alert.alert-danger a,
.alert.alert-danger .alert-link {
  color: #fff;
}
.alert.alert-rose {
  background-color: #ea2c6d;
  color: #fff;
}
.alert.alert-rose a,
.alert.alert-rose .alert-link {
  color: #fff;
}
.alert-info,
.alert-danger,
.alert-warning,
.alert-success {
  color: #fff;
}
.alert-default a,
.alert-default .alert-link {
  color: rgba(0, 0, 0, 0.87);
}
.alert .alert-icon {
  display: block;
  float: left;
  margin-right: 1.071rem;
}
.alert .alert-icon i {
  margin-top: -7px;
  top: 5px;
  position: relative;
}
.alert .close {
  color: #ffffff;
  text-shadow: none;
  opacity: .9;
}
.alert .close i {
  font-size: 20px;
}
.alert .close:hover,
.alert .close:focus {
  opacity: 1;
}
.pagination>.page-item>.page-link,
.pagination>.page-item>span {
  border: 0;
  border-radius: 30px !important;
  transition: all .3s;
  padding: 0px 11px;
  margin: 0 3px;
  min-width: 30px;
  height: 30px;
  line-height: 30px;
  color: #999999;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
  background: transparent;
  text-align: center;
}
.pagination>.page-item>.page-link:hover,
.pagination>.page-item>.page-link:focus,
.pagination>.page-item>span:hover,
.pagination>.page-item>span:focus {
  color: #999999;
}
.pagination>.page-item.active>a,
.pagination>.page-item.active>span {
  color: #999999;
}
.pagination>.page-item.active>a,
.pagination>.page-item.active>a:focus,
.pagination>.page-item.active>a:hover,
.pagination>.page-item.active>span,
.pagination>.page-item.active>span:focus,
.pagination>.page-item.active>span:hover {
  background-color: #32a8df;
  border-color: #32a8df;
  color: #ffffff;
  box-shadow: 0 4px 5px 0 rgba(156, 39, 176, 0.14), 0 1px 10px 0 rgba(156, 39, 176, 0.12), 0 2px 4px -1px rgba(156, 39, 176, 0.2);
}
.pagination.pagination-info>.page-item.active>a,
.pagination.pagination-info>.page-item.active>a:focus,
.pagination.pagination-info>.page-item.active>a:hover,
.pagination.pagination-info>.page-item.active>span,
.pagination.pagination-info>.page-item.active>span:focus,
.pagination.pagination-info>.page-item.active>span:hover {
  background-color: #00bcd4;
  border-color: #00bcd4;
  box-shadow: 0 4px 5px 0 rgba(0, 188, 212, 0.14), 0 1px 10px 0 rgba(0, 188, 212, 0.12), 0 2px 4px -1px rgba(0, 188, 212, 0.2);
}
.pagination.pagination-success>.page-item.active>a,
.pagination.pagination-success>.page-item.active>a:focus,
.pagination.pagination-success>.page-item.active>a:hover,
.pagination.pagination-success>.page-item.active>span,
.pagination.pagination-success>.page-item.active>span:focus,
.pagination.pagination-success>.page-item.active>span:hover {
  background-color: #4caf50;
  border-color: #4caf50;
  box-shadow: 0 4px 5px 0 rgba(76, 175, 80, 0.14), 0 1px 10px 0 rgba(76, 175, 80, 0.12), 0 2px 4px -1px rgba(76, 175, 80, 0.2);
}
.pagination.pagination-warning>.page-item.active>a,
.pagination.pagination-warning>.page-item.active>a:focus,
.pagination.pagination-warning>.page-item.active>a:hover,
.pagination.pagination-warning>.page-item.active>span,
.pagination.pagination-warning>.page-item.active>span:focus,
.pagination.pagination-warning>.page-item.active>span:hover {
  background-color: #ff9800;
  border-color: #ff9800;
  box-shadow: 0 4px 5px 0 rgba(255, 152, 0, 0.14), 0 1px 10px 0 rgba(255, 152, 0, 0.12), 0 2px 4px -1px rgba(255, 152, 0, 0.2);
}
.pagination.pagination-danger>.page-item.active>a,
.pagination.pagination-danger>.page-item.active>a:focus,
.pagination.pagination-danger>.page-item.active>a:hover,
.pagination.pagination-danger>.page-item.active>span,
.pagination.pagination-danger>.page-item.active>span:focus,
.pagination.pagination-danger>.page-item.active>span:hover {
  background-color: #f44336;
  border-color: #f44336;
  box-shadow: 0 4px 5px 0 rgba(244, 67, 54, 0.14), 0 1px 10px 0 rgba(244, 67, 54, 0.12), 0 2px 4px -1px rgba(244, 67, 54, 0.2);
}
.pagination .page-item .page-link:focus {
  box-shadow: none;
}
.nav-pills:not(.flex-column) .nav-item+.nav-item:not(:first-child) {
  margin-left: 5px;
}
.nav-pills.flex-column .nav-item+.nav-item {
  margin-top: 5px;
}
.nav-pills .nav-item .nav-link {
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
  color: #555;
  transition: all .3s;
  border-radius: 30px;
  padding: 10px 15px;
}
.nav-pills .nav-item .nav-link:hover {
  background-color: rgba(200, 200, 200, 0.2);
}
.nav-pills .nav-item .nav-link.active {
  color: #ffffff;
  background-color: #32a8df;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.nav-pills .nav-item i {
  display: block;
  font-size: 30px;
  padding: 15px 0;
}
.nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
  background-color: #00bcd4;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(0, 188, 212, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-rose .nav-item .nav-link.active,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-rose .nav-item .nav-link.active:hover {
  background-color: #e91e63;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-success .nav-item .nav-link.active,
.nav-pills.nav-pills-success .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-success .nav-item .nav-link.active:hover {
  background-color: #4caf50;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(76, 175, 80, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-warning .nav-item .nav-link.active,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-warning .nav-item .nav-link.active:hover {
  background-color: #ff9800;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(255, 152, 0, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-danger .nav-item .nav-link.active,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-danger .nav-item .nav-link.active:hover {
  background-color: #f44336;
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(244, 67, 54, 0.6);
  color: #ffffff;
}
.nav-pills.nav-pills-icons .nav-item .nav-link {
  border-radius: 4px;
}
.tab-space {
  padding: 20px 0 50px 0px;
}
.info .icon {
  color: #999999;
}
.info .icon>i {
  font-size: 3.85rem;
}
.info .info-title {
  color: #000000;
  margin: 1.75rem 0 0.875rem;
}
.info p {
  color: #999999;
}
.info-horizontal .icon {
  float: left;
  margin-top: 24px;
  margin-right: 10px;
}
.info-horizontal .icon>i {
  font-size: 2.25rem;
}
.info-horizontal .description {
  overflow: hidden;
}
.icon.icon-primary {
  color: #32a8df;
}
.icon.icon-info {
  color: #00bcd4;
}
.icon.icon-success {
  color: #4caf50;
}
.icon.icon-warning {
  color: #ff9800;
}
.icon.icon-danger {
  color: #f44336;
}
.icon.icon-rose {
  color: #e91e63;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: 'Lato', sans-serif;
  line-height: 1.5em;
}
h1,
h2,
h3,
.h1,
.h2,
.h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6,
.h4,
.h5,
.h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
.h1 {
  font-size: 40px;
  line-height: 1.15em;
}
h2,
.h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}
h3,
.h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4,
.h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}
h5,
.h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
  margin-bottom: 15px;
}
h6,
.h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 16px;
  margin: 0 0 10px;
}
b {
  font-weight: 700;
}
small,
.small {
  font-size: 75%;
  color: #777;
}
.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
  font-weight: 500;
  font-family: 'Lato', sans-serif;
}
.title,
.title a,
.card-title,
.card-title a,
.info-title,
.info-title a,
.footer-brand,
.footer-brand a,
.footer-big h5,
.footer-big h5 a,
.footer-big h4,
.footer-big h4 a,
.media .media-heading,
.media .media-heading a {
  color: #000000;
  text-decoration: none;
}
h2.title {
  margin-bottom: 1rem;
}
.description,
.card-description,
.footer-big p {
  color: #999;
}
.text-warning {
  color: #ff9800 !important;
}
.text-primary {
  color: #32a8df !important;
}
.text-danger {
  color: #f44336 !important;
}
.text-success {
  color: #4caf50 !important;
}
.text-info {
  color: #00bcd4 !important;
}
.text-rose {
  color: #e91e63 !important;
}
.text-gray {
  color: #999999 !important;
}
.space {
  height: 130px;
  display: block;
}
.space-110 {
  height: 110px;
  display: block;
}
.space-50 {
  height: 50px;
  display: block;
}
.space-70 {
  height: 70px;
  display: block;
}
.blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  border-left: 5px solid #eee;
}
.blockquote p {
  font-size: 1.063rem;
  font-style: italic;
}
.blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}
.nav-tabs {
  border: 0;
  border-radius: 3px;
  padding: 0 15px;
}
.nav-tabs .nav-item .nav-link {
  color: #ffffff;
  border: 0;
  margin: 0;
  border-radius: 3px;
  line-height: 24px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 15px;
  background-color: transparent;
  transition: 0.3s background-color 0s;
}
.nav-tabs .nav-item .nav-link:hover {
  border: 0;
}
.nav-tabs .nav-item .nav-link,
.nav-tabs .nav-item .nav-link:hover,
.nav-tabs .nav-item .nav-link:focus {
  border: 0 !important;
  color: #ffffff !important;
  font-weight: 500;
}
.nav-tabs .nav-item.disabled .nav-link,
.nav-tabs .nav-item.disabled .nav-link:hover {
  color: rgba(255, 255, 255, 0.5);
}
.nav-tabs .nav-item .material-icons {
  margin: -1px 5px 0 0;
}
.nav-tabs .nav-item .nav-link.active {
  background-color: rgba(255, 255, 255, 0.2);
  transition: 0.3s background-color 0.2s;
}
.nav-tabs .nav-link {
  border-bottom: 0.214rem solid transparent;
}
.nav-tabs .nav-link {
  color: #555;
}
.nav-tabs .nav-link.active {
  color: rgba(0, 0, 0, 0.87);
  border-color: #32a8df;
}
.nav-tabs .nav-link.active:hover,
.nav-tabs .nav-link.active:focus {
  border-color: #32a8df;
}
.nav-tabs .nav-link.disabled {
  color: #999;
}
.nav-tabs .nav-link.disabled,
.nav-tabs .nav-link.disabled:hover,
.nav-tabs .nav-link.disabled:focus {
  color: #999;
}
.nav-tabs.header-primary .nav-link {
  color: white;
}
.nav-tabs.header-primary .nav-link.active {
  color: #fff;
  border-color: #fff;
}
.nav-tabs.header-primary .nav-link.active:hover,
.nav-tabs.header-primary .nav-link.active:focus {
  border-color: #fff;
}
.nav-tabs.header-primary .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.header-primary .nav-link.disabled,
.nav-tabs.header-primary .nav-link.disabled:hover,
.nav-tabs.header-primary .nav-link.disabled:focus {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link {
  color: white;
}
.nav-tabs.bg-inverse .nav-link.active {
  color: #fff;
  border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.active:hover,
.nav-tabs.bg-inverse .nav-link.active:focus {
  border-color: #fff;
}
.nav-tabs.bg-inverse .nav-link.disabled {
  color: rgba(255, 255, 255, 0.84);
}
.nav-tabs.bg-inverse .nav-link.disabled,
.nav-tabs.bg-inverse .nav-link.disabled:hover,
.nav-tabs.bg-inverse .nav-link.disabled:focus {
  color: rgba(255, 255, 255, 0.84);
}
.card-nav-tabs {
  margin-top: 45px;
}
.card-nav-tabs .card-header {
  margin-top: -30px !important;
}
.tooltip-arrow {
  display: none;
}
.tooltip.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
}
.tooltip {
  opacity: 0;
  transition: opacity, transform .2s ease;
  -webkit-transform: translate3d(0, 5px, 0);
  -moz-transform: translate3d(0, 5px, 0);
  -o-transform: translate3d(0, 5px, 0);
  -ms-transform: translate3d(0, 5px, 0);
  transform: translate3d(0, 5px, 0);
  font-size: 0.875rem;
}
.tooltip.bs-tooltip-top .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #ffffff;
}
.tooltip.bs-tooltip-right .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #ffffff;
}
.tooltip.bs-tooltip-left .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #ffffff;
}
.tooltip.bs-tooltip-bottom .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #ffffff;
}
.tooltip-inner {
  padding: 10px 15px;
  min-width: 130px;
}
.popover,
.tooltip-inner {
  line-height: 1.5em;
  background: #ffffff;
  border: none;
  border-radius: 3px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  color: #555;
}
.popover {
  padding: 0;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.popover.left>.arrow,
.popover.right>.arrow,
.popover.top>.arrow,
.popover.bottom>.arrow {
  border: none;
}
.popover.bs-popover-top .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-auto[x-placement^="top"] .arrow::before,
.popover.bs-popover-bottom .arrow::before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover.bs-popover-right .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-auto[x-placement^="right"] .arrow::before,
.popover.bs-popover-left .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before,
.popover.bs-popover-auto[x-placement^="left"] .arrow::before {
  border: 0;
}
.popover-header {
  background-color: #ffffff;
  border: none;
  padding: 15px 15px 5px;
  font-size: 1.125rem;
  margin: 0;
  color: #555;
}
.popover-body {
  padding: 10px 15px 15px;
  line-height: 1.4;
  color: #555;
}
.modal-dialog .modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 6px;
  border: none;
}
.modal-dialog .modal-content .card-signup {
  margin: 0;
}
.modal-dialog .modal-content .card-signup .modal-header {
  padding-top: 0;
}
.modal-dialog .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}
.modal-dialog .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
}
.modal-dialog .modal-footer {
  border-top: none;
  padding: 24px;
}
.modal-dialog .modal-footer.text-center {
  text-align: center;
}
.modal-dialog .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
.modal-dialog .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}
.modal-dialog .modal-body+.modal-footer {
  padding-top: 0;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.5);
}
.modal .modal-header .close {
  color: #999;
}
.modal .modal-header .close:hover,
.modal .modal-header .close:focus {
  opacity: 1;
}
.modal .modal-header .close i {
  font-size: 16px;
}
.dropdown:after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.dropdown.show>.dropdown-toggle:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-menu {
  display: none;
  padding: 0.3125rem 0;
  border: 0;
  opacity: 0;
  transform: scale(0);
  transform-origin: 0 0;
  will-change: transform, opacity;
  transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dropdown-menu.showing {
  animation-name: bmd-dropdown-animation;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.open>.dropdown-menu,
.dropdown-menu.show {
  display: block;
  opacity: 1;
  transform: scale(1);
}
.dropdown-menu.hiding {
  display: block;
  opacity: 0;
  transform: scale(0);
}
.dropdown-menu[x-placement="bottom-start"] {
  transform-origin: 0 0;
}
.dropdown-menu[x-placement="bottom-end"] {
  transform-origin: 100% 0;
}
.dropdown-menu[x-placement="top-start"] {
  transform-origin: 0 100%;
}
.dropdown-menu[x-placement="top-end"] {
  transform-origin: 100% 100%;
}
.dropdown-menu .disabled>a {
  color: #777;
}
.dropdown-menu .disabled>a:focus,
.dropdown-menu .disabled>a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  color: #777;
}
.dropdown-menu.dropdown-with-icons .dropdown-item {
  padding: 0.75rem 1.25rem 0.75rem 0.75rem;
}
.dropdown-menu.dropdown-with-icons .dropdown-item .material-icons {
  vertical-align: middle;
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 1px;
  margin-right: 12px;
  opacity: .5;
}
.dropdown-menu .dropdown-item,
.dropdown-menu li>a {
  position: relative;
  width: auto;
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  color: #333;
  font-weight: normal;
  text-decoration: none;
  font-size: .8125rem;
  border-radius: 0.125rem;
  margin: 0 0.3125rem;
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  min-width: 7rem;
  padding: 0.625rem 1.25rem;
  overflow: hidden;
  line-height: 1.428571;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
div#transitions-popper {
  z-index: 1000;
}
@media (min-width: 768px) {
  .dropdown-menu .dropdown-item,
  .dropdown-menu li>a {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu a:active,
.dropdown-menu a.active,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
  background-color: #32a8df;
  color: #fff !important;
}
.dropdown-toggle.bmd-btn-icon::after,
.dropdown-toggle.bmd-btn-fab::after {
  display: none;
}
.dropdown-toggle.bmd-btn-icon~.dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-icon~.dropdown-menu.dropdown-menu-top-right,
.dropdown-toggle.bmd-btn-fab~.dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab~.dropdown-menu.dropdown-menu-top-right {
  bottom: 2rem;
}
.dropdown-toggle:after {
  will-change: transform;
  transition: transform 150ms linear;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}
.dropdown-toggle.open:after {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.dropdown-toggle.bmd-btn-fab-sm~.dropdown-menu.dropdown-menu-top-left,
.dropdown-toggle.bmd-btn-fab-sm~.dropdown-menu.dropdown-menu-top-right {
  bottom: 2.5rem;
}
.dropdown-toggle.bmd-btn-icon~.dropdown-menu {
  margin: 0;
}
.dropdown-header {
  font-size: 0.75rem;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  text-transform: none;
  color: #777;
  line-height: 1.428571;
  font-weight: inherit;
}
@keyframes bmd-dropdown-animation {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.dropdown-menu.bootstrap-datetimepicker-widget {
  opacity: 0;
  transform: scale(0);
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transform-origin: 0 0;
  will-change: transform, opacity;
  top: 0;
}
.dropdown-menu.bootstrap-datetimepicker-widget.top {
  transform-origin: 0 100%;
}
.dropdown-menu.bootstrap-datetimepicker-widget.open {
  opacity: 1;
  transform: scale(1);
  top: 0;
}
.bmd-layout-drawer {
  position: absolute;
  z-index: 5;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 100%;
  overflow: visible;
  overflow-y: auto;
  font-size: .875rem;
  transition: transform;
  will-change: transform;
  transform-style: preserve-3d;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.bmd-layout-drawer>* {
  flex-shrink: 0;
}
.bmd-layout-drawer>header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1rem;
}
.bmd-layout-drawer>header .navbar-brand {
  padding-left: 1.5rem;
  font-size: 1.25rem;
}
.bmd-layout-drawer>.list-group {
  padding-top: 1rem;
}
.bmd-layout-drawer>.list-group .list-group-item {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  font-size: .8125rem;
  font-weight: 500;
}
.bmd-drawer-f-l>.bmd-layout-drawer {
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  transform: translateX(-250px);
}
.bmd-drawer-f-l>.bmd-layout-header,
.bmd-drawer-f-l>.bmd-layout-content {
  margin-left: 0;
}
.bmd-drawer-f-r>.bmd-layout-drawer {
  top: 0;
  right: 0;
  width: 240px;
  height: 100%;
  transform: translateX(250px);
}
.bmd-drawer-f-r>.bmd-layout-header,
.bmd-drawer-f-r>.bmd-layout-content {
  margin-right: 0;
}
.bmd-drawer-f-t>.bmd-layout-drawer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transform: translateY(-110px);
}
.bmd-drawer-f-t>.bmd-layout-content {
  margin-top: 0;
}
.bmd-drawer-f-b>.bmd-layout-drawer {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  transform: translateY(110px);
}
.bmd-drawer-f-b>.bmd-layout-content {
  margin-bottom: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l>.bmd-layout-header {
  width: calc(100% - 240px);
  margin-left: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l>.bmd-layout-drawer {
  transform: translateX(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-l>.bmd-layout-content {
  margin-left: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r>.bmd-layout-header {
  width: calc(100% - 240px);
  margin-right: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r>.bmd-layout-drawer {
  transform: translateX(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-r>.bmd-layout-content {
  margin-right: 240px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-t>.bmd-layout-header {
  margin-top: 100px;
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-t>.bmd-layout-drawer {
  transform: translateY(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-b>.bmd-layout-drawer {
  transform: translateY(0);
}
:not(.bmd-drawer-out) .bmd-drawer-in .bmd-drawer-f-b>.bmd-layout-content {
  margin-bottom: 100px;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay>.bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay>.bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-l>.bmd-layout-header,
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-l>.bmd-layout-content {
  width: 100%;
  margin-left: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-r>.bmd-layout-header,
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-r>.bmd-layout-content {
  width: 100%;
  margin-right: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay>.bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay>.bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-t>.bmd-layout-header {
  margin-top: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay.bmd-drawer-overlay .bmd-drawer-f-b>.bmd-layout-content {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-l>.bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-r>.bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 576px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-t>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-b>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-sm-up .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 767.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-l>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-l>.bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-r>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-r>.bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 767.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-sm-down .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-l>.bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-r>.bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 768px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-t>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-b>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-md-up .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 991.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-l>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-l>.bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-r>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-r>.bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 991.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-md-down .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-l>.bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-r>.bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 992px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-t>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-b>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-lg-up .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 100px;
  }
}
@media (max-width: 1199.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-l>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-l>.bmd-layout-content {
    width: 100%;
    margin-left: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-r>.bmd-layout-header,
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-r>.bmd-layout-content {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 1199.98px) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down>.bmd-layout-backdrop .in {
    visibility: visible;
    background-color: rgba(0, 0, 0, 0.5);
  }
  @supports (pointer-events: auto) {
    :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down>.bmd-layout-backdrop.in {
      pointer-events: auto;
      opacity: 1;
    }
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 0;
  }
  :not(.bmd-drawer-out) .bmd-drawer-overlay-lg-down .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-l>.bmd-layout-content {
    margin-left: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r>.bmd-layout-header {
    width: calc(100% - 240px);
    margin-right: 240px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r>.bmd-layout-drawer {
    transform: translateX(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-r>.bmd-layout-content {
    margin-right: 240px;
  }
}
@media (min-width: 1200px) {
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-t>.bmd-layout-header {
    margin-top: 100px;
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-t>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-b>.bmd-layout-drawer {
    transform: translateY(0);
  }
  :not(.bmd-drawer-out) .bmd-drawer-in-xl-up .bmd-drawer-f-b>.bmd-layout-content {
    margin-bottom: 100px;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down>.bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down>.bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-l>.bmd-layout-header,
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-l>.bmd-layout-content {
  width: 100%;
  margin-left: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-r>.bmd-layout-header,
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-r>.bmd-layout-content {
  width: 100%;
  margin-right: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down>.bmd-layout-backdrop .in {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}
@supports (pointer-events: auto) {
  :not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down>.bmd-layout-backdrop.in {
    pointer-events: auto;
    opacity: 1;
  }
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-t>.bmd-layout-header {
  margin-top: 0;
}
:not(.bmd-drawer-out) .bmd-drawer-overlay-xl-down .bmd-drawer-f-b>.bmd-layout-content {
  margin-bottom: 0;
}
.progress {
  height: 4px;
  border-radius: 0;
  box-shadow: none;
  background: #DDDDDD;
  margin-bottom: 20px;
}
.progress .progress-bar {
  box-shadow: none;
}
.progress .progress-bar.progress-bar-primary {
  background: #32a8df !important;
}
.progress .progress-bar.progress-bar-info {
  background: #00bcd4;
}
.progress .progress-bar.progress-bar-success {
  background: #4caf50;
}
.progress .progress-bar.progress-bar-warning {
  background: #ff9800;
}
.progress .progress-bar.progress-bar-danger {
  background: #f44336;
}
.progress.progress-line-primary {
  background: rgba(156, 39, 176, 0.2);
}
.progress.progress-line-info {
  background: rgba(0, 188, 212, 0.2);
}
.progress.progress-line-success {
  background: rgba(76, 175, 80, 0.2);
}
.progress.progress-line-warning {
  background: rgba(255, 152, 0, 0.2);
}
.progress.progress-line-danger {
  background: rgba(244, 67, 54, 0.2);
}
.togglebutton {
  vertical-align: middle;
}
.togglebutton,
.togglebutton label,
.togglebutton input,
.togglebutton .toggle {
  user-select: none;
}
.togglebutton label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label:hover,
.form-group.is-focused .togglebutton label:focus {
  color: rgba(0, 0, 0, .54);
}
fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.togglebutton label input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}
.togglebutton label .toggle {
  text-align: left;
  margin-left: 5px;
}
.togglebutton label .toggle,
.togglebutton label input[type=checkbox][disabled]+.toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 15px;
  transition: background 0.3s ease;
  vertical-align: middle;
}
.togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -2.5px;
  border: 1px solid rgba(0, 0, 0, .54);
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.togglebutton label input[type=checkbox][disabled]+.toggle:after,
.togglebutton label input[type=checkbox][disabled]:checked+.toggle:after {
  background-color: #BDBDBD;
}
.togglebutton label input[type=checkbox]+.toggle:active:after,
.togglebutton label input[type=checkbox][disabled]+.toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.togglebutton label input[type=checkbox]:checked+.toggle:after {
  left: 15px;
}
.togglebutton label input[type=checkbox]:checked+.toggle {
  background-color: rgba(156, 39, 176, 0.7);
}
.togglebutton label input[type=checkbox]:checked+.toggle:after {
  border-color: #32a8df;
}
.togglebutton label input[type=checkbox]:checked+.toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(156, 39, 176, 0.1);
}
.ripple {
  position: relative;
}
.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  border-radius: inherit;
}
.ripple-container .ripple-decorator {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 100%;
  opacity: 0;
  transform: scale(1);
  transform-origin: 50%;
}
.ripple-container .ripple-decorator.ripple-on {
  opacity: 0.1;
  transition: opacity 0.15s ease-in 0s, transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}
.ripple-container .ripple-decorator.ripple-out {
  opacity: 0;
  transition: opacity 0.1s linear 0s !important;
}
.page-header {
  height: 100%;
  background-position: center center;
  background-size: contain;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
  background-image: url("../src/assets/images/banner_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  cursor: default;
}
.page-header .page-header-image {
  position: absolute;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.page-header .content-center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  color: #FFFFFF;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
}
.page-header .carousel .carousel-indicators {
  bottom: 60px;
}
.page-header>.container {
  color: #ffffff;
}
.page-header .iframe-container iframe {
  width: 100%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.header-filter {
  position: relative;
  overflow: hidden;
}
.header-filter:before,
.header-filter:after {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  content: "";
}
.header-filter .container {
  z-index: 2;
  position: relative;
}
.clear-filter::before {
  background: none;
}
.purple-filter:after {
  background: rgba(101, 47, 142, 0.64);
  background: linear-gradient(45deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
  background: -moz-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
  background: -webkit-linear-gradient(135deg, rgba(101, 47, 142, 0.88) 0%, rgba(125, 46, 185, 0.45) 100%);
}
footer {
  padding: 0.9375rem 0;
  display: -webkit-flex;
  /* Safari */
  /* Safari 6.1+ */
  display: flex;
}
footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}
footer ul li {
  display: inline-block;
}
footer ul li a {
  color: inherit;
  padding: 0.9375rem;
  font-size: 12px;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}
footer ul li a:hover {
  text-decoration: none;
}
footer ul li .btn {
  margin: 0;
}
footer ul.links-horizontal:first-child a {
  padding-left: 0;
}
footer ul.links-horizontal:last-child a {
  padding-right: 0;
}
footer ul.links-vertical li {
  display: block;
  margin-left: -5px;
  margin-right: -5px;
}
footer ul.links-vertical li a {
  padding: 5px;
}
footer .social-buttons a,
footer .social-buttons .btn {
  margin-top: 5px;
  margin-bottom: 5px;
}
footer .footer-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  margin-left: -15px;
}
footer .footer-brand:hover,
footer .footer-brand:focus {
  color: #000000;
}
footer .copyright {
  padding: 15px 0;
}
footer .copyright .material-icons {
  font-size: 18px;
  position: relative;
  top: 3px;
}
footer .pull-center {
  display: inline-block;
  float: none;
}
.card {
  border: 0;
  /* margin-bottom: 30px;
  margin-top: 30px; */
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #ffffff;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card .card-category:not([class*="text-"]) {
  color: #999999;
}
.card .card-category {
  margin-top: 10px;
}
.card .card-category .material-icons {
  position: relative;
  top: 8px;
  line-height: 0;
}
.card .form-check {
  margin-top: 15px;
}
.card .card-title {
  margin-top: 0.625rem;
}
.card .card-title:last-child {
  margin-bottom: 0;
}
.card.no-shadow .card-header-image,
.card.no-shadow .card-header-image img {
  box-shadow: none !important;
}
.card .card-body,
.card .card-footer {
  padding: 0.9375rem 1.875rem;
}
.card .card-body+.card-footer {
  padding-top: 0rem;
  border: 0;
  border-radius: 6px;
}
.card .card-footer {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
}
.card .card-footer .author,
.card .card-footer .stats {
  display: inline-flex;
}
.card .card-footer .stats {
  color: #999999;
}
.card .card-footer .stats .material-icons {
  position: relative;
  top: 3px;
  margin-right: 3px;
  margin-left: 3px;
  font-size: 18px;
}
.card.bmd-card-raised {
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
  .card.bmd-card-flat {
    box-shadow: none;
  }
}
/* .card .card-header {
  border-radius: 3px;
  padding: 1rem 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border: 0;
  background: linear-gradient(60deg, #eee, #bdbdbd);
} */
.card .card-header .title {
  color: #ffffff;
}
/* .card .card-header:not([class*="header-"]) {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
} */
.card .card-header .nav-tabs {
  padding: 0;
}
.card .card-header.card-header-image {
  position: relative;
  padding: 0;
  z-index: 1;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -30px;
  border-radius: 6px;
}
.card .card-header.card-header-image a {
  display: block;
}
.card .card-header.card-header-image img {
  width: 100%;
  border-radius: 6px;
  pointer-events: none;
  box-shadow: 0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image .card-title {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: #ffffff;
  font-size: 1.125rem;
  text-shadow: 0 2px 5px rgba(33, 33, 33, 0.5);
}
.card .card-header.card-header-image .colored-shadow {
  transform: scale(0.94);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
  transition: opacity .45s;
  opacity: 0;
}
.card .card-header.card-header-image.no-shadow {
  box-shadow: none;
}
.card .card-header.card-header-image.no-shadow.shadow-normal {
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}
.card .card-header.card-header-image.no-shadow .colored-shadow {
  display: none !important;
}
.card .card-header-primary,
.card.bg-primary,
.card.card-rotate.bg-primary .front,
.card.card-rotate.bg-primary .back {
  background: linear-gradient(60deg, #ab47bc, #7b1fa2);
}
.card .card-header-info,
.card.bg-info,
.card.card-rotate.bg-info .front,
.card.card-rotate.bg-info .back {
  background: linear-gradient(60deg, #26c6da, #0097a7);
}
.card .card-header-success,
.card.bg-success,
.card.card-rotate.bg-success .front,
.card.card-rotate.bg-success .back {
  background: linear-gradient(60deg, #66bb6a, #388e3c);
}
.card .card-header-warning,
.card.bg-warning,
.card.card-rotate.bg-warning .front,
.card.card-rotate.bg-warning .back {
  background: linear-gradient(60deg, #ffa726, #f57c00);
}
.card .card-header-danger,
.card.bg-danger,
.card.card-rotate.bg-danger .front,
.card.card-rotate.bg-danger .back {
  background: linear-gradient(60deg, #ef5350, #d32f2f);
}
.card .card-header-rose,
.card.bg-rose,
.card.card-rotate.bg-rose .front,
.card.card-rotate.bg-rose .back {
  background: linear-gradient(60deg, #ec407a, #c2185b);
}
.card .card-header-primary {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(156, 39, 176, 0.6);
}
.card .card-header-danger {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(244, 67, 54, 0.6);
}
.card .card-header-rose {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(233, 30, 99, 0.6);
}
.card .card-header-warning {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(255, 152, 0, 0.6);
}
.card .card-header-info {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(0, 188, 212, 0.6);
}
.card .card-header-success {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2), 0 13px 24px -11px rgba(76, 175, 80, 0.6);
}
.card [class*="header-"],
.card[class*="bg-"] {
  color: #ffffff;
}
.card [class*="header-"] .card-title a,
.card [class*="header-"] .card-title,
.card [class*="header-"] .icon i,
.card[class*="bg-"] .card-title a,
.card[class*="bg-"] .card-title,
.card[class*="bg-"] .icon i {
  color: #ffffff;
}
.card [class*="header-"] .icon i,
.card[class*="bg-"] .icon i {
  border-color: rgba(255, 255, 255, 0.25);
}
.card [class*="header-"] .author a,
.card [class*="header-"] .stats,
.card [class*="header-"] .card-category,
.card [class*="header-"] .card-description,
.card[class*="bg-"] .author a,
.card[class*="bg-"] .stats,
.card[class*="bg-"] .card-category,
.card[class*="bg-"] .card-description {
  color: rgba(255, 255, 255, 0.8);
}
.card [class*="header-"] .author a:hover,
.card [class*="header-"] .author a:focus,
.card [class*="header-"] .author a:active,
.card[class*="bg-"] .author a:hover,
.card[class*="bg-"] .author a:focus,
.card[class*="bg-"] .author a:active {
  color: #ffffff;
}
.card .author .avatar {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 5px;
}
.card .author a {
  color: #000000;
  text-decoration: none;
}
.card .author a .ripple-container {
  display: none;
}
.card .card-category-social .fa {
  font-size: 24px;
  position: relative;
  margin-top: -4px;
  top: 2px;
  margin-right: 5px;
}
.card .card-category-social .material-icons {
  position: relative;
  top: 5px;
}
.card[class*="bg-"],
.card[class*="bg-"] .card-body {
  border-radius: 6px;
}
.card[class*="bg-"] h1 small,
.card[class*="bg-"] h2 small,
.card[class*="bg-"] h3 small,
.card[class*="bg-"] .card-body h1 small,
.card[class*="bg-"] .card-body h2 small,
.card[class*="bg-"] .card-body h3 small {
  color: rgba(255, 255, 255, 0.8);
}
.card .card-stats {
  background: transparent;
  display: flex;
}
.card .card-stats .author,
.card .card-stats .stats {
  display: inline-flex;
}
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .card {
    display: inline-block;
  }
}
.card-carousel {
  overflow: hidden;
}
.card-login .card-header {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -40px;
  padding: 20px 0;
  margin-bottom: 15px;
}
.card-login .text-divider {
  margin-top: 30px;
  margin-bottom: 0px;
  text-align: center;
}
.card-login .card-body {
  padding: 0px 30px 0px 10px;
}
.card-login .form-check {
  padding-top: 27px;
}
.card-login .form-check label {
  margin-left: 18px;
}
.card-login .form-check .form-check-sign {
  padding-right: 27px;
}
.card-login .input-group {
  padding-bottom: 7px;
  margin: 27px 0 0 0;
}
.card-login .form {
  min-height: 420px;
}
.card-login .social-line {
  margin-top: 1rem;
  text-align: center;
  padding: 0;
}
.card-login .social-line .btn {
  color: #ffffff;
  margin-left: 5px;
  margin-right: 5px;
}
.login-page .footer {
  position: absolute;
  width: 100%;
  background: transparent;
  bottom: 0;
  color: #ffffff;
  z-index: 2;
}
.login-page .footer .copyright a {
  color: #ffffff;
}
.card-plain {
  background: transparent;
  box-shadow: none;
}
.card-plain .card-header:not(.card-avatar) {
  margin-left: 0;
  margin-right: 0;
}
.card-plain .card-body {
  padding-left: 5px;
  padding-right: 5px;
}
.card-plain .card-header-image {
  margin: 0 !important;
  border-radius: 6px;
}
.card-plain .card-header-image img {
  border-radius: 6px;
}
.card-plain .card-footer {
  padding-left: 5px;
  padding-right: 5px;
  background-color: transparent;
}
/*!
 * Datetimepicker for Bootstrap 3
 * ! version : 4.17.37
 * https://github.com/Eonasdan/bootstrap-datetimepicker/
 */
.sr-only,
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after,
.bootstrap-datetimepicker-widget .picker-switch::after,
.bootstrap-datetimepicker-widget table th.prev::after,
.bootstrap-datetimepicker-widget table th.next::after {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.bootstrap-datetimepicker-widget {
  list-style: none;
}
.bootstrap-datetimepicker-widget a:hover {
  box-shadow: none !important;
}
.bootstrap-datetimepicker-widget a .btn:hover {
  background-color: transparent;
}
.bootstrap-datetimepicker-widget.dropdown-menu {
  padding: 4px;
  width: 19em;
}
@media (min-width: 768px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 991px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
@media (min-width: 1200px) {
  .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
    width: 38em;
  }
}
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:before,
.bootstrap-datetimepicker-widget.dropdown-menu.bottom:after {
  right: auto;
  left: 12px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top {
  margin-top: auto;
  margin-bottom: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.top.open {
  margin-top: auto;
  margin-bottom: 27px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:before {
  left: auto;
  right: 6px;
}
.bootstrap-datetimepicker-widget.dropdown-menu.pull-right:after {
  left: auto;
  right: 7px;
}
.bootstrap-datetimepicker-widget .list-unstyled {
  margin: 0;
}
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 0;
  margin: 0;
  border-width: 0;
  background-color: transparent;
  color: #32a8df;
  box-shadow: none;
}
.bootstrap-datetimepicker-widget a[data-action]:hover {
  background-color: transparent;
}
.bootstrap-datetimepicker-widget a[data-action]:hover span {
  background-color: #eee;
  color: #32a8df;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
  box-shadow: none;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute,
.bootstrap-datetimepicker-widget .timepicker-second {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-weight: 300;
  font-size: 1.125rem;
  margin: 0;
  border-radius: 50%;
}
.bootstrap-datetimepicker-widget button[data-action] {
  width: 38px;
  height: 38px;
  margin-right: 3px;
  padding: 0;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after {
  content: "Increment Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  content: "Increment Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after {
  content: "Decrement Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  content: "Decrement Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
  content: "Show Hours";
}
.bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
  content: "Show Minutes";
}
.bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
  content: "Toggle AM/PM";
}
.bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
  content: "Clear the picker";
}
.bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
  content: "Set the date to today";
}
.bootstrap-datetimepicker-widget .picker-switch {
  text-align: center;
  border-radius: 3px;
  font-size: 0.875rem;
}
.bootstrap-datetimepicker-widget .picker-switch::after {
  content: "Toggle Date and Time Screens";
}
.bootstrap-datetimepicker-widget .picker-switch td {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  line-height: inherit;
}
.bootstrap-datetimepicker-widget .picker-switch td span {
  line-height: 2.5;
  height: 2.5em;
  width: 100%;
  border-radius: 3px;
  margin: 2px 0px !important;
}
.bootstrap-datetimepicker-widget table {
  width: 100%;
  margin: 0;
}
.bootstrap-datetimepicker-widget table.table-condensed tr>td {
  text-align: center;
}
.bootstrap-datetimepicker-widget table td>div,
.bootstrap-datetimepicker-widget table th>div {
  text-align: center;
}
.bootstrap-datetimepicker-widget table th {
  height: 20px;
  line-height: 20px;
  width: 20px;
  font-weight: 500;
}
.bootstrap-datetimepicker-widget table th.picker-switch {
  width: 145px;
}
.bootstrap-datetimepicker-widget table th.disabled,
.bootstrap-datetimepicker-widget table th.disabled:hover {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table th.prev span,
.bootstrap-datetimepicker-widget table th.next span {
  border-radius: 3px;
  height: 27px;
  width: 27px;
  line-height: 28px;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
}
.bootstrap-datetimepicker-widget table th.prev::after {
  content: "Previous Month";
}
.bootstrap-datetimepicker-widget table th.next::after {
  content: "Next Month";
}
.bootstrap-datetimepicker-widget table th.dow {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 400;
  padding-bottom: 5px;
  padding-top: 10px;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th {
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table thead tr:first-child th:hover span,
.bootstrap-datetimepicker-widget table thead tr:first-child th.picker-switch:hover {
  background: #eee;
}
.bootstrap-datetimepicker-widget table td>div {
  border-radius: 3px;
  height: 54px;
  line-height: 54px;
  width: 54px;
  text-align: center;
}
.bootstrap-datetimepicker-widget table td.cw>div {
  font-size: .8em;
  height: 20px;
  line-height: 20px;
  color: #999;
}
.bootstrap-datetimepicker-widget table td.day>div {
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  padding: 0px;
  border-radius: 50%;
  position: relative;
  z-index: -1;
  color: #000000;
  font-size: 0.875rem;
}
.bootstrap-datetimepicker-widget table td.minute>div,
.bootstrap-datetimepicker-widget table td.hour>div {
  border-radius: 50%;
}
.bootstrap-datetimepicker-widget table td.day:hover>div,
.bootstrap-datetimepicker-widget table td.hour:hover>div,
.bootstrap-datetimepicker-widget table td.minute:hover>div,
.bootstrap-datetimepicker-widget table td.second:hover>div {
  background: #eee;
  cursor: pointer;
}
.bootstrap-datetimepicker-widget table td.old>div,
.bootstrap-datetimepicker-widget table td.new>div {
  color: #999;
}
.bootstrap-datetimepicker-widget table td.today>div {
  position: relative;
}
.bootstrap-datetimepicker-widget table td.active>div,
.bootstrap-datetimepicker-widget table td.active:hover>div {
  background-color: #32a8df;
  color: #ffffff;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(156, 39, 176, 0.4);
}
.bootstrap-datetimepicker-widget table td.active.today:before>div {
  border-bottom-color: #ffffff;
}
.bootstrap-datetimepicker-widget table td.disabled>div,
.bootstrap-datetimepicker-widget table td.disabled:hover>div {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget table td span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 3px 3px;
  cursor: pointer;
  border-radius: 50%;
  text-align: center;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: #eee;
}
.bootstrap-datetimepicker-widget table td span.active {
  background-color: #32a8df;
  color: #ffffff;
}
.bootstrap-datetimepicker-widget table td span.old {
  color: #999;
}
.bootstrap-datetimepicker-widget table td span.disabled,
.bootstrap-datetimepicker-widget table td span.disabled:hover {
  background: none;
  color: rgba(0, 0, 0, 0.12);
  cursor: not-allowed;
}
.bootstrap-datetimepicker-widget .timepicker-picker span,
.bootstrap-datetimepicker-widget .timepicker-hours span,
.bootstrap-datetimepicker-widget .timepicker-minutes span {
  border-radius: 50% !important;
}
.bootstrap-datetimepicker-widget.usetwentyfour td.hour {
  height: 27px;
  line-height: 27px;
}
.input-group.date .input-group-addon {
  cursor: pointer;
}
.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
  padding: 1px;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
/*! nouislider - 14.1.1  */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat;
}
.noUi-connect {
  height: 100%;
  width: 100%;
}
.noUi-origin {
  height: 10%;
  width: 10%;
}
/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
}
.noUi-touch-area {
  height: 100%;
  width: 100%;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 2px;
  margin: 15px 0;
}
.noUi-horizontal .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: 100%;
  top: -7px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #32a8df;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-vertical {
  height: 100%;
  width: 2px;
  margin: 0 15px;
}
.noUi-vertical .noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -8px;
  top: -10px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid #32a8df;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  right: -10px;
  left: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #c8c8c8;
  border-radius: 4px;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3FB8AF;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  left: -10px;
  top: -6px;
  cursor: pointer;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
  background: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
  transform: scale3d(1.5, 1.5, 1);
}
/* Handle stripes;
 */
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}
.noUi-marker-sub {
  background: #AAA;
}
.noUi-marker-large {
  background: #AAA;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}
.slider {
  background: #c8c8c8;
}
.slider .noUi-connect {
  background-color: #32a8df;
  border-radius: 4px;
}
.slider .noUi-handle {
  border-color: #32a8df;
}
.slider.slider-info .noUi-connect {
  background-color: #00bcd4;
}
.slider.slider-info .noUi-handle {
  border-color: #00bcd4;
}
.slider.slider-success .noUi-connect {
  background-color: #4caf50;
}
.slider.slider-success .noUi-handle {
  border-color: #4caf50;
}
.slider.slider-warning .noUi-connect {
  background-color: #ff9800;
}
.slider.slider-warning .noUi-handle {
  border-color: #ff9800;
}
.slider.slider-danger .noUi-connect {
  background-color: #f44336;
}
.slider.slider-danger .noUi-handle {
  border-color: #f44336;
}
.slider.slider-rose .noUi-connect {
  background-color: #e91e63;
}
.slider.slider-rose .noUi-handle {
  border-color: #e91e63;
}
.index-page .section-components h6.description {
  margin-top: 5px;
  margin-bottom: 30px;
}
.index-page .page-header {
  /* height: 90vh; */
  overflow: hidden;
}
.index-page .brand {
  color: #ffffff;
  text-align: center;
}
.index-page .brand h1 {
  font-size: 4.2rem;
  font-weight: 600;
  display: inline-block;
  position: relative;
}
.index-page .brand h3 {
  font-size: 1.313rem;
  max-width: 500px;
  margin: 10px auto 0;
}
.index-page h4.description {
  font-size: 1.5em;
}
.section {
  padding: 65px 0;
  position: relative;
}
@media all and (max-width: 991px) {
  [class*="navbar-expand-"]>.container,
  [class*="navbar-expand-"]>.container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show {
    right: 101% !important;
  }
  .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show .dropdown-item.open+.dropdown-menu,
  .dropdown-menu.show .dropdown-item.open+.dropdown-menu.show .dropdown-item.open+.dropdown-menu.show {
    left: -165px !important;
  }
  .navbar .navbar-collapse .navbar-nav>li.button-container {
    padding: 15px;
  }
  .carousel .card .card-body {
    max-width: 340px;
    margin: 0 auto;
    min-height: 400px;
  }
  .navbar-collapse {
    position: fixed;
    display: block;
    top: 0px;
    height: 100vh;
    width: 230px;
    right: 0;
    margin-right: 0 !important;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    overflow-y: visible;
    border-top: none;
    text-align: left;
    padding-right: 0;
    padding-left: 0;
    max-height: none !important;
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar-collapse::after {
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #ffffff;
    display: block;
    content: "";
    z-index: 1;
  }
  .navbar-collapse .dropdown-toggle:after {
    position: absolute;
    right: 16px;
    margin-top: 8px;
  }
  .navbar-collapse .navbar-nav {
    position: relative;
    z-index: 3;
  }
  .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #000000;
    margin: 5px 15px;
  }
  .navbar-collapse .navbar-nav .nav-item.active .nav-link,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:hover,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:focus,
  .navbar-collapse .navbar-nav .nav-item.active .nav-link:active {
    background-color: #ecedef;
    color: #000000;
  }
  .navbar-collapse .navbar-nav .nav-item.button-container .nav-link {
    margin: 15px;
  }
  .navbar-collapse .navbar-nav .nav-item:after {
    width: calc(100% - 30px);
    content: "";
    display: block;
    height: 1px;
    margin-left: 15px;
    background-color: #e5e5e5;
  }
  .navbar-collapse .navbar-nav .nav-item:last-child:after {
    display: none;
  }
  .nav-open .navbar-collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .navbar-translate {
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .navbar .navbar-translate {
    width: 100%;
    position: relative;
    display: flex;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -moz-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -o-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -ms-transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: transform 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .navbar .dropdown.show .dropdown-menu {
    display: block;
  }
  .navbar .dropdown .dropdown-menu {
    display: none;
  }
  .navbar .dropdown-menu .dropdown-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .navbar .dropdown.show .dropdown-menu,
  .navbar .dropdown .dropdown-menu {
    background-color: transparent;
    border: 0;
    padding-bottom: 15px;
    transition: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    transform: none !important;
    width: auto;
    margin-bottom: 15px;
    padding-top: 0;
    height: 300px;
    animation: none;
    opacity: 1;
    overflow-y: scroll;
  }
  .navbar.navbar-transparent .navbar-toggler .navbar-toggler-icon {
    background-color: #ffffff;
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 230px;
    content: "";
    z-index: 1029;
    overflow-x: hidden;
  }
  #navbar .navbar-collapse,
  #navigation .navbar-collapse {
    display: none !important;
  }
}
@media all and (min-width: 991px) {
  .navbar .navbar-nav {
    align-items: center;
  }
  .navbar .navbar-nav .button-container {
    margin-left: 0.1875px;
  }
  .contactus-2 .card-contact {
    margin: 80px 0 80px 150px;
  }
  .index-page .fileinput-new.thumbnail.img-circle {
    margin-top: 50px;
  }
}
@media screen and (max-width: 991px) {
  .presentation-page .section-components .components-macbook {
    max-width: 850px !important;
    max-height: 480px !important;
    margin-top: 12vh;
    left: -12px;
  }
  .presentation-page .section-components .coloured-card-img,
  .presentation-page .section-components .table-img {
    display: none;
  }
  .presentation-page .section-components .social-img {
    left: 47%;
    top: 37%;
  }
  .presentation-page .section-components .pin-btn-img {
    top: 54%;
  }
  .presentation-page .section-components .share-btn-img {
    top: 12%;
  }
  .presentation-page .section-components .coloured-card-btn-img {
    top: -2%;
    left: 65%;
  }
  .presentation-page .section-content .area-img {
    max-width: 130px;
    max-height: 170px;
  }
  .presentation-page .section-content .info-img {
    max-width: 170px;
    max-height: 120px;
  }
  @keyframes topbar-x {
    0% {
      top: 0px;
      transform: rotate(0deg);
    }
    45% {
      top: 6px;
      transform: rotate(145deg);
    }
    75% {
      transform: rotate(130deg);
    }
    100% {
      transform: rotate(135deg);
    }
  }
  @-webkit-keyframes topbar-x {
    0% {
      top: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -webkit-transform: rotate(145deg);
    }
    75% {
      -webkit-transform: rotate(130deg);
    }
    100% {
      -webkit-transform: rotate(135deg);
    }
  }
  @-moz-keyframes topbar-x {
    0% {
      top: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      top: 6px;
      -moz-transform: rotate(145deg);
    }
    75% {
      -moz-transform: rotate(130deg);
    }
    100% {
      -moz-transform: rotate(135deg);
    }
  }
  @keyframes topbar-back {
    0% {
      top: 6px;
      transform: rotate(135deg);
    }
    45% {
      transform: rotate(-10deg);
    }
    75% {
      transform: rotate(5deg);
    }
    100% {
      top: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes topbar-back {
    0% {
      top: 6px;
      -webkit-transform: rotate(135deg);
    }
    45% {
      -webkit-transform: rotate(-10deg);
    }
    75% {
      -webkit-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes topbar-back {
    0% {
      top: 6px;
      -moz-transform: rotate(135deg);
    }
    45% {
      -moz-transform: rotate(-10deg);
    }
    75% {
      -moz-transform: rotate(5deg);
    }
    100% {
      top: 0px;
      -moz-transform: rotate(0);
    }
  }
  @keyframes bottombar-x {
    0% {
      bottom: 0px;
      transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      transform: rotate(-145deg);
    }
    75% {
      transform: rotate(-130deg);
    }
    100% {
      transform: rotate(-135deg);
    }
  }
  @-webkit-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -webkit-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -webkit-transform: rotate(-145deg);
    }
    75% {
      -webkit-transform: rotate(-130deg);
    }
    100% {
      -webkit-transform: rotate(-135deg);
    }
  }
  @-moz-keyframes bottombar-x {
    0% {
      bottom: 0px;
      -moz-transform: rotate(0deg);
    }
    45% {
      bottom: 6px;
      -moz-transform: rotate(-145deg);
    }
    75% {
      -moz-transform: rotate(-130deg);
    }
    100% {
      -moz-transform: rotate(-135deg);
    }
  }
  @keyframes bottombar-back {
    0% {
      bottom: 6px;
      transform: rotate(-135deg);
    }
    45% {
      transform: rotate(10deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      transform: rotate(0);
    }
  }
  @-webkit-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -webkit-transform: rotate(-135deg);
    }
    45% {
      -webkit-transform: rotate(10deg);
    }
    75% {
      -webkit-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -webkit-transform: rotate(0);
    }
  }
  @-moz-keyframes bottombar-back {
    0% {
      bottom: 6px;
      -moz-transform: rotate(-135deg);
    }
    45% {
      -moz-transform: rotate(10deg);
    }
    75% {
      -moz-transform: rotate(-5deg);
    }
    100% {
      bottom: 0px;
      -moz-transform: rotate(0);
    }
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(2) {
    top: 0px;
    -webkit-animation: topbar-back 500ms linear 0s;
    -moz-animation: topbar-back 500ms linear 0s;
    animation: topbar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(3) {
    opacity: 1;
  }
  .navbar .navbar-toggler .navbar-toggler-icon:nth-child(4) {
    bottom: 0px;
    -webkit-animation: bottombar-back 500ms linear 0s;
    -moz-animation: bottombar-back 500ms linear 0s;
    animation: bottombar-back 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(2) {
    top: 6px;
    -webkit-animation: topbar-x 500ms linear 0s;
    -moz-animation: topbar-x 500ms linear 0s;
    animation: topbar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(3) {
    opacity: 0;
  }
  .navbar .navbar-toggler.toggled .navbar-toggler-icon:nth-child(4) {
    bottom: 6px;
    -webkit-animation: bottombar-x 500ms linear 0s;
    -moz-animation: bottombar-x 500ms linear 0s;
    animation: bottombar-x 500ms 0s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}
@media screen and (max-width: 767px) {
  .presentation-page .section-components .components-macbook {
    max-width: 350px !important;
    max-height: 250px !important;
    margin-top: 12vh;
    left: -12px;
  }
  .presentation-page .section-components .coloured-card-img,
  .presentation-page .section-components .table-img {
    display: none;
  }
  .presentation-page .section-components .social-img {
    left: -7%;
    top: 37%;
  }
  .presentation-page .section-components .pin-btn-img {
    top: 54%;
  }
  .presentation-page .section-components .share-btn-img {
    top: 7%;
  }
  .presentation-page .section-components .coloured-card-btn-img {
    top: -2%;
  }
  .card.card-form-horizontal .input-group {
    padding-bottom: 10px;
  }
  .presentation-page #cd-vertical-nav,
  .index-page #cd-vertical-nav,
  .section-page #cd-vertical-nav {
    display: none;
  }
  .index-page .cd-section .tim-typo .tim-note {
    width: 60px;
  }
  .login-page .page-header>.container {
    padding-bottom: 200px;
  }
}
@media screen and (max-width: 400px) {
  .cd-vertical-nav {
    display: none !important;
  }
}
@media screen and (min-width: 767px) {
  .bd-docs .bd-example .card.card-profile .card-header-image {
    max-height: 100% !important;
  }
  .card-profile .card-header-image {
    max-height: 215px;
  }
}
@media screen and (max-width: 991px) {
  .menu-on-left .page-header,
  .menu-on-left .main,
  .menu-on-left .navbar>div {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
  .menu-on-left .navbar .collapse {
    left: 0;
    top: -25px;
    right: auto;
    -webkit-transform: translate3d(-230px, 0, 0);
    -moz-transform: translate3d(-230px, 0, 0);
    -o-transform: translate3d(-230px, 0, 0);
    -ms-transform: translate3d(-230px, 0, 0);
    transform: translate3d(-230px, 0, 0);
  }
  .menu-on-left .navbar .navbar-translate .navbar-brand {
    position: absolute;
    right: 0;
    top: -15px;
  }
  .nav-open .menu-on-left .page-header,
  .nav-open .menu-on-left .main {
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
  }
  .nav-open .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .menu-on-left .navbar>div .navbar-translate {
    -webkit-transform: translate3d(230px, 0, 0);
    -moz-transform: translate3d(230px, 0, 0);
    -o-transform: translate3d(230px, 0, 0);
    -ms-transform: translate3d(230px, 0, 0);
    transform: translate3d(230px, 0, 0);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}
@media (min-width: 575px) and (max-width: 991px) {
  .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(-255px, 0, 0);
    -moz-transform: translate3d(-255px, 0, 0);
    -o-transform: translate3d(-255px, 0, 0);
    -ms-transform: translate3d(-255px, 0, 0);
    transform: translate3d(-255px, 0, 0);
  }
  .nav-open .menu-on-left .navbar .collapse {
    -webkit-transform: translate3d(-25px, 0, 0);
    -moz-transform: translate3d(-25px, 0, 0);
    -o-transform: translate3d(-25px, 0, 0);
    -ms-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    width: 245px;
  }
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #32a8df;
}

/* Mobile Navigation */
.mobile_nav {
  background-image: linear-gradient(-300deg, #3a3480, #1d4e84) !important;
}
.home_page_menu li::after {
  display: none;
}

/* Custom Styles */
/* Home Page */
ul,li,button,
.MuiButton-root {
  font-family: 'Lato', sans-serif !important;
}
.hamburger_btn {
  color: #32a8df !important;
}
.page_wrapper header {
  z-index: 1000;
  /* background-color: #1a1a1a !important; */
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 0;
}
.home_left_navbar {
  display: flex;
  margin-left: 20% !important;
}
.home_left_navbar li a {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 24px;
  color: #fff;
  padding: 0;
}
.home_left_navbar li a:hover,
.home_left_navbar li a.active {
  color: #32a8df;
}
.home_main_nav {
  justify-content: space-between;
}
button.auth_btn {
  border: 1px solid #32a8df;
  background: #32a8df;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 5px 12px;
  transition: 0.2s all;
}
button.auth_btn svg {
  margin-right: 5px;
}
button.auth_btn a {
  color: #fff;
}
button.auth_btn:hover {
  background: #f7a100;   
  border: 1px solid #ffd800;
}
button.auth_btn:hover a {
  color: #fff !important;
}
button.auth_btn.blue_btn:hover {
  color: #fff;
}
.main_navbar {
  display: flex;
}
.page-header h1 {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}
.page-header h3 {
  color: #6ad0ff;
  font-size: 20px;
  font-weight: 500;
}
.page-header h3 span {
  color: #fff;
}
.page-header p {
  font-size: 16px;
  line-height: 30px;
  color: #fff;
}
.banner_slider .slick-prev,
.banner_slider .slick-next {
  top: unset;
  bottom: -50px;
}
.banner_slider .slick-prev {
  left: 0;
}
.banner_slider .slick-next {
  right: unset;
  left: 40px;
}
.banner_slider .slick-prev:before,
.banner_slider .slick-next:before {
  color: #32a8df;
}
.banner_slider .slick-prev:before {
  content: "\f284";
  font-family: bootstrap-icons !important;
}
.banner_slider .slick-next:before {
  content: "\f285";
  font-family: bootstrap-icons !important;
}
.float_img {
  position: absolute;
  width: 100%;
  height: 100%;
}
.float_img img {
  position: absolute;
  animation: NewsletterAnimated3 12s infinite linear alternate;
}
.float_img img:first-child {  
  right: -120px;
  top: 60px;
}
.float_img img:nth-child(2) {
  top: 40%;
  left: -80px;
}
.float_img img:nth-child(3) {
  top: 20%;
  left: 150px;
}
.float_img img:nth-child(4) {
  bottom: 20%;
  right: 100px;
}
select.custom-select.language_select {
  background: transparent;
  border: none;
  color: #969696;
  font-size: 16px;
  box-shadow: none;
  padding: 0 15px;
}
.noti_ul {
  padding: 0 !important;
}
.noti_ul b {
  display: none !important;
}
.noti_ul button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 50px;
}
.noti_head {
  color: #fff;
  text-align: center;
  font-weight: 700;
  padding-top: 10px;
  font-size: 18px;
}
.no_noti {
  color: #9a9696;
  font-size: 14px;
  text-align: center;
  padding: 20px 0px;
  margin-bottom: 0px;
  white-space: normal;
}
.noti_ul ul li {
  background-color: transparent !important;
  box-shadow: none !important;
}
.noti_ul .MuiPaper-rounded {
  background-color: #0f0f0f;
}
.noti_ul img {
  width: 150px;
}
.menu_icons {
  color: #969696;
}
.see_all_link {
  font-size: 15px;
  font-weight: 600;
  color: #32a8df !important;
  width: 100%;
  text-align:center;
  text-decoration: underline !important;
  display: block;
}
.see_all_link:hover {
  color:#787878 !important;
}
.theme_switch_btn .btn.active {
	background-color: #0f0f0f;
}
.theme_switch_btn .btn {
	background-color: #000;
  box-shadow: none;
  padding: 5px 10px;
}
.home_page_menu ul {
  align-items: center;
  display: flex;
  justify-content: center;
}
.theme_switch_btn i {
  font-size: 14px;
  color: #3c3c3c;
}
.theme_switch_btn .btn.active i {
  color: #32a8df;
}
.noti_ul sup {
  color: #32a8df;
  font-size: 12px;
}
.theme_switch_btn {
  margin-right: 10px;
}
.main_title {
  font-size: 35px;
  font-weight: 700;
}
.main_content {
  color: #657191;
  font-size: 16px;
}
.main_link {
  color: #32a8df;
  font-size: 16px;
  font-weight: 700;
}
li.menu_dropdown {
  padding: 5px;
}
li.menu_dropdown button {
  color: #fff;
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 16px;
  font-weight: normal;
  padding: 0 !important;
  line-height: 16px;
  min-width: auto;
}
li.menu_dropdown .MuiPaper-root {
  background-color: #0f0f0f;
}
li.menu_dropdown .MuiPaper-root ul li {
  padding: 10px;
}
li.menu_dropdown .MuiPaper-root ul li:hover {
  background-color: #000;
  box-shadow: none;
}
li.menu_dropdown button:hover {
  color: #32a8df;
}
.primary_checkbox .custom-control-input {
  z-index: 1;
}
.primary_checkbox .custom-control-label::before {
  top: 0;
  left: -20px;
  background: #262626;
  border-color: #262626;
}
.primary_checkbox .custom-control-label::after {
  top: 0;
  left: -20px;
}
.primary_checkbox .custom-control-input:checked~.custom-control-label::before {
  border-color: #32a8df;
}
.primary_checkbox label {
  font-size: 14px;
}
button.banner_btn {
  width: 100%;
  background-image: linear-gradient(#e7c65f, #d09514);
  border: 2px solid #dfb544;
  font-size: 14px;
  font-weight: 700;
  padding: 8px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 8px;
}
button.banner_btn:hover {
  color: #dfb544;
  border: 2px solid #dfb544;
  background-image: none;
}
.form_note {
  color: #b9b9b9 !important;
  font-size: 14px !important;
}
.form_note a {
  color: #32a8df;
}
.bottom_banner img {
  border-radius: 10px;
  margin-bottom: 30px;
}
.banner_ticker_panel .slick-slide {
  margin: 0 auto 0px;
}
.banner_ticker_single {
  width: 250px !important;
  padding: 15px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  display: flex !important;
  align-items: center;  
  background: #193b72;
}
.banner_ticker_single img {
  width: 36px;
  margin-right: 8px;
}
.green_txt {
  color: #2bc155 !important;
}
.red_txt {
  color: #fb1d46 !important;
}
.orange_txt {
  color: #f68819 !important;
}
.yellow_txt {
  color: #32a8df !important;
}
.banner_ticker_single h3 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  color: #e1e1e1;
}
.banner_ticker_single h2 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  color: #fff;
}
.banner_ticker_single h4 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.banner_ticker_single .badge {
  font-size: 12px;
  padding: 5px 7px;
  font-weight: 600;
}
.green_badge {
  background: #3bd20d;
  color: #000000;
}
.red_badge {
  background: #fb1d46;
  color: #ffffff;
}
.banner_ticker_panel {
  background-color: #fff;
  border-radius: 8px;
}
.features_section {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
}
.features_section::before{
  content: "";
  position: absolute;
  background: url("../src/assets/images/benefitbg1.png");
  bottom: 0;
  right: 0;
  width: 179px;
  height: 273px;
  background-repeat: no-repeat;
  background-position: center;
}
.features_section::after{
  content: "";
  position: absolute;
  background: url("../src/assets/images/benefitbg2.png");
  top: -36px;
  left: 0;
  width: 339px;
  height: 531px;
  background-repeat: no-repeat;
  background-position: center;
}
.rewards_section {
  background: url("../src/assets/images/section_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  background-size: cover;
  padding: 80px 0;
  overflow: hidden;
}
.getstarted_section {
  background: #fafafc;
}
.rewards_section h2 {
  color: #fff;
  font-size: 24px;
}
.graph_bg {
  background-image: linear-gradient(180deg, rgb(255 255 255 / 10%), rgb(255 255 255 / 10%));
  padding: 30px 20px;
  border-radius: 10px;
}
.graph_bg .exchange_value h2 {
  font-size: 28px;
  color: #fff;
}
.graph_bg p {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  color: #fff;
}
.getstarted_section h6 {
  color: #32a8df;
  font-size: 20px;
  font-weight: 600;
  text-transform: unset;
  margin: 0;
}
.getstarted_section h2 {
  color: #0d1543;
  font-size: 45px;
  font-weight: 700;
  margin: 0;
}
.detail_panel_single img {
  margin-right: 10px;
}
.detail_panel_single {
  background: #2d5b8f;
  padding: 15px 30px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
  border:2px solid transparent;
}
.detail_panel_single:hover{
background:#32649c;
border:2px solid #3392cc;
}
.detail_panel_single h2 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.detail_panel_single h3 {
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.feature_panel_single { 
  margin: 15px 0;
  background: #fff;
  cursor: default;
  transition: 0.2s all;
  box-shadow: 0 0 10px 4px rgb(60 77 175 / 5%);
  padding: 15px 0px;
  border-radius: 8px;
  transition: 0.2s all;
}
.feature_panel_single:hover {
  transform: translateY(-10px);
  background: linear-gradient(45deg, #1d4e84, #363781);
  color:#fff;
}
.feature_panel_single:hover h2,.feature_panel_single:hover p{
  color:#fff;
}
.feature_panel_single .media {
  align-items: center;
}
.feature_panel_single img {
  margin-right: 8px;
  margin:8px;
}
.feature_panel_single h2  {
  font-size: 15px;
  font-weight: 600;
  color: #000;
  margin: 0 0 10px;
}
.feature_panel_single p {
  color: #657191;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.benefits_img {
  width: 700px;
}
.feature_panel .feature_panel_single:nth-child(even) {
  margin-top: 20px;
}
.features_section .float_img img {
  left: 40%;
  top: -30px;
}
.advantages_section_first {
  background: #fff url("../src/assets/images/liqiditybg.png");
  background-position: center;
  background-size: 100% 58%;
  background-repeat: no-repeat;
  background-color: white;
}
.starts_section {
  background: url("../src/assets/images/peerbg.png");
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
@keyframes NewsletterAnimated3 {
	0% {
		transform: translateY(0)
	}
	25% {
		transform: translateY(-20px)
	}
	50% {
		transform: translateY(0)
	}
	75% {
		transform: translateY(20px)
	}
	100% {
		transform: translateY(0)
	}
}
.footer_menu_list li {
  display: block;
  text-align: left;
}
footer.footer_home {
  background-color: #2a2872;
  padding: 50px 0 20px;
}
.footer_menu_list li a {
  padding: 5px 0;
  font-size: 13px;
  color: #8e8cce;
  transition: 0.2s all;
}
.footer_menu_list li a:hover {
  color: #fff;
}
.subscribe_label {
  font-size: 16px;
  margin-bottom: 20px;
  color: #000;
}
.footer_top_home {
  text-align: left;
  border-bottom: 1px solid rgb(255 255 255 / 8%);
  padding-bottom: 15px;
}
.footer_top_home div::nth-child(1){
  background:red;
}
.footer_top_home .subscribe_label {
  font-size: 15px;
  display: block;
  color: #8e8cce;
  margin-top: 8px;
}
.footer_top_home h2 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.subscribe_form {
  position: relative;
}
input.subscribe_inp {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  height: 42px;
  padding: 8px 10px 10px 10px;
  width: 100%;
}
button.subscribe_btn {
  font-size: 16px;
  font-weight: 600;  
  height: 42px;
  padding: 3px 20px;
  border-radius: 0 5px 5px 0;
  position: absolute;
  top: 0;
  right: 0;
  transition: 0.2s all;
  text-transform: capitalize;
}
.footer_bottom_home {
  display: flex;
  justify-content:center;
  align-items: center;
  padding: 5px 0 10px;
}
.copyryt_txt {
  color: #928fed;
  font-size: 13px;
}
.footer_social_links li a {
  color: #7674af;
  font-size: 18px;
  transition: 0.2s all;
  padding: 15px 12px;
}
.footer_social_links li a:hover,
.footer_bottom_home ul li a:hover {
  color: #fff;
}
.footer_bottom_home ul li a {
  color: #928fed;
  font-size: 13px;
}
.footer_top_home input.subscribe_inp::placeholder {
  color: #928fed;
  font-size: 14px;
}
.footer_top_home input.subscribe_inp {
  border: 1px solid #5d5baa;
  background-color: transparent;
  color: #fff;
}
.footer_top_home button.subscribe_btn {
  font-weight: 500;
  text-transform: uppercase;
}
.primary_inp {
  border: 1px solid #262626;
  background: #262626;
  border-radius: 5px;
  width: 100%;
  padding: 5px 10px 5px 15px;
  height: 40px;
  font-size: 14px;
  color: #fff;
}
.primary_inp::placeholder {
  font-size: 14px;
  color: #b9b9b9;
}
.primary_modal .custom-control-label {
  position: unset;
  box-shadow: none;
  font-size: 16px;
  color: #000;
}
.primary_modal .custom-control-label::before {
  top: 0;
  box-shadow: none;
}
.primary_modal .custom-control-label::after {
  top: 0;
}
/* .primary_modal .modal-body {
  max-height: calc(100vh - 200px);
  overflow: auto;
} */

/* Login & Register */
.auth_padding_container {
  padding-top: 20vh !important;
  padding-bottom: 100px !important;
}
.auth_panel {
  /* background: url("../src/assets/images/auth_bg.png") #fff; */
  background-size: cover;
}
.relative {
  position: relative;
}
/* Dashboard */
.inner_pageheader {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 500px;
}
.inner_pageheader > div {
  min-height: inherit;
}
.inner_pageheader > div > div.MuiGrid-grid-lg-2 {
  background: #ededed;
}
.inner_heading_wrapper {  
  background-size: cover;
  min-height: 326px !important;
  display: flex;
  align-items: center;
}
.pool_heading_wrap {
  background: url("../src/assets/images/pool_banner_bg.jpg") no-repeat center ;
}
.exchange_heading_wrap {
  background: url("../src/assets/images/exchange_banner_bg.jpg") no-repeat center ;
}
.liquidity_heading_wrap {
  background: url("../src/assets/images/liquidity_banner_bg.jpg") no-repeat center ;
}
button.inner_primary_btn {
  background: #32a8df;
  border: 2px solid #32a8df;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 25px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 5px;
}
button.inner_primary_btn:hover {
  color: #32a8df;
  border: 2px solid #32a8df;
  background: transparent;
}
button.inner_primary_btn a {
  color: #fff;
}
button.inner_primary_btn:hover a {
  color: #32a8df;
}
.inner_heading_wrapper h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  margin: 0;
}
.inner_heading_wrapper h4 {
  color: #e0e0e0;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}
.inner_heading_wrapper p {
  color: #6ad0ff;
  font-size: 18px;
  font-weight: 400;
}
.inner_content_wrapper {
  background: #e2e4f4;
}
button.follow_btn {
  background-image: linear-gradient(#e7c65f, #d09514);
  border: 2px solid #dfb544;
  font-size: 10px;
  font-weight: 400;
  padding: 5px 10px;
  color: #fff;
  transition: 0.2s all;
  border-radius: 5px;
}
button.follow_btn a {
  color: #fff;
}
button.full_btn {
  background-color: #ededed;
  border: 1px solid #d2d2d2;
  font-size: 10px;
  font-weight: 400;
  padding: 5px 10px;
  color: #5a5a5a;
  transition: 0.2s all;
  border-radius: 5px;
}
.inner_content_wrapper_plain {
  margin-top: 120px;
}
.whitebox {
  background: #fff;
  box-shadow: 0 0 10px 1px rgb(56 66 71 / 5%);
  margin: 30px 0;
  padding: 20px;
  border-radius: 5px;
}
.follow_trade_head {
  display: flex;
  justify-content: space-between;
}
.follow_trade_head i {
  font-size: 20px;
  color: #32a8df;
  margin-right: 5px;
}
.follow_trade_head span {
  font-size: 20px;
  color: #000000;
}
.follow_trade_head p {
  margin-bottom: 0;
  font-size: 14px;
  color: #787878;
}
.trader_contract_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}
button.contract_pair {
  position: relative;
  padding: 5px 10px;
  border: 1px solid #f1f3f6;
  background: #f1f3f6;
  color: #787878;
  font-size: 14px;
  border-radius: 2px;
  cursor: pointer;
  transition: all .35s;
  margin: 8px 10px 0;
}
.contract_label {
  font-size: 14px;
  color: #000;
  margin-right: 15px;
}
.contract_pair.active, 
.contract_pair:hover {
  border-color: #32a8df;
  background-color: #fffaee;
  color: #494949;
}
.contract_pair.active:after {
  content: " ";
  position: absolute;
  bottom: -1px;
  right: -1px;
  display: block;
  width: 15px;
  height: 15px;
  background: url("../src/assets/images/button_tick.svg") 50% no-repeat;
  background-size: cover;
}
.custom-control {
  min-height: initial;
}
.custom-control-label::before {
  top: 0;
  left: -20px;
}
.custom-control-label::after {
  top: 0;
  left: -20px;
}
.copy_setting_box {
  margin-top: 30px;
  padding: 10px 0 10px 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.copy_unify_label {
  color: #000;
  font-size: 14px;
  display: inline-block;
  width: 120px;
  margin-right: 15px;
}
.copy_unify_box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0;
}
.copy_input_lever {
  display: flex;
  align-items: flex-end;
  margin: 0 15px;
}
span.input-lever-text {
  color: #787878;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 10px;
}
.lever_input {
  width: 50px;
  text-align: center;
  height: initial;
}
.copy_input_lever_box {
  display: flex;
  margin: 10px 0;
}
button.copy_inp_cal {
  color: #32a8df;
  background: #f5f5f5;
  font-size: 20px;
  padding: 15px 0;
  margin: 0 5px;
  height: 0;
  width: 40px;
  min-width: initial;
}
.primary_modal .modal-header h5 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
}
.primary_modal .modal-header .close {
  color: #000;
}
.setting_overview {
  margin-bottom: 10px;
  padding: 16px 13px 16px 11px;
  background: #fff;
  border: 1px solid #dee1e4;
  border-radius: 6px;
}
.dialog_pair {
  color: #32a8df;
  font-size: 16px;
  font-weight: 700;
}
.setting_overview div span {
  font-size: 14px;
  color: #5d5e65;
  display: inline-block;
  width: 49%;
  text-align: left;
}
.setting_overview div span:nth-child(2) {
  font-size: 14px;
  color: #000;
  text-align: right;
}
.setting_overview div {
  margin: 10px 0;
}
.primary_form select.custom-select {
  border: 1px solid #d9d9d9;
  background: #f5f5f5 url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center;
}
.primary_form .primary_inp_inner {
  border: 1px solid #d9d9d9;
  background: #f5f5f5;
  width: 100%;
  height: 41px;
  border-radius: 0.25rem;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
}
.pointer {
  cursor: pointer;
}
.holder {
  height: 50px;
  width: 50px;
  border: 2px solid #252525;
  border-radius: 100%;
  margin: 0 auto;
}
.holder img {
  border-radius: 100%;
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
}
input[type="file"]#photo {
  margin-top: -5px;
  opacity: 0;
  z-index: 999;
}
.profile_edit_panel {
  position: relative;
  margin-bottom: -15px;
}
.profile_edit_icon {
  position: absolute;
  top: -10px;
  z-index: 0;
  left: 40px;
  right: 0;
  text-align: center;
}
.profile_edit_icon i {
  background: #32a8df;
  color: #000;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 12px;
}
.custom-switch .custom-control-label::after {
  top: 2px;
}
.copy_trade_user_data {
  display: flex;
  align-items: center;
}
.copy_trade_user_data div {
  text-align: center;
  margin: 0 30px;
}
.copy_trade_user_data h3 {
  font-size: 16px;
  margin: 0;
}
.copy_trade_user_data p {
  color: #787878;
  font-size: 12px;
}
.badge.badge-danger {
  background: #feecec;
  color: #ff0000;
  font-size: 13px;
  text-transform: capitalize;
  padding: 8px 7px;
}
.badge_light {
  border-radius: 30px;
  font-size: 12px;
  text-transform: capitalize;
  color: #5a5a5a;
  margin: 15px 0;
}
.trade_order_pair {
  background: #f5f5f5;
  font-size: 14px;
  color: #787878;
  padding: 5px 12px;
  border-radius: 3px;
  margin: 0 10px !important;
  cursor: default;
}
.form_exchange_grp {
  display: flex;
  align-items: flex-end;
}
.form_exchange_grp img {
  margin: 12px 20px;
}
.form_exchange_grp input {
  font-size: 14px;
}
.buy_crypto_panel {
  background: #32a8df;
  border-radius: 5px;
  padding: 30px 30px 10px;
}
.buy_crypto_append_inp {
  position: absolute;
  top: 1px;
  width: 90px;
  right: 5px;
  border: 0;
  border-radius: 0;
  height: 33px;
  box-shadow: none !important;
}
.buy_crypto_panel h2 {
  margin: 0;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
}
.buy_crypto_panel label {
  color: #ffffff;
}
.buy_crypto_eq_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buy_crypto_eq_value h3 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin: 0;
}
.buy_crypto_eq_value h3 span {
  color: #f5f5f5;
}
.buy_crypto_service {
  border: 1px solid #32a8df;
  border-radius: 5px;
  background: #f5f5f5;
  padding: 15px 10px 20px;
  transition: 0.2s all;
  cursor: pointer;
  text-align: center;
}
.buy_crypto_service:hover {
  box-shadow: 0 8px 16px -4px rgb(219 172 55 / 80%);
}
.buy_crypto_service h3 {
  color: #32a8df;
  font-size: 26px;
  font-weight: 700;
  margin: 10px 0 0;
}
.buy_crypto_service h4 {
  font-size: 14px;
  color: #5a5a5a;
  font-weight: 700;
  margin: 0;
}
.buy_crypto_service p {
  color: #5a5a5a;
  font-size: 14px;
  margin: 0;
}
.buy_crypto_service_header {
  display: flex;
  justify-content: space-between;
}
.buy_crypto_service_header h2 {
  font-size: 20px;
  font-weight: 700;
  font-style: italic;
  margin: 0;
}
.buy_crypto_service_header p {
  font-size: 14px;
  color: #5d5e65;
  margin-bottom: 0;
}
.custom_timer {
  color: #dcaf3d !important;
  margin-left: 10px;
  background: #f5f5f5;
  border-radius: 50%;
}
.custom_timer circle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.launchpad_token_section {
  background: #eef2f7;
  padding: 0 0 60px;
}
.timer_panel {
  text-align: center;
  display: flex;
  justify-content: center;
}
span.timer_label {
  display: block;
  font-size: 14px;
  color: #7d7d7d;
}
span.timer_time {
  font-size: 20px;
  color: #000;
}
.timer_panel > span {
  margin: 5px;
}
span.timer_dots {
  margin: 0 3px;
  line-height: 38px;
  color: #333333;
}
.launchpad_btn_center {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
}
.launchpad_btn_center .btnLandingJoin {
  padding: 10px 30px;
  font-size: 14px;
}
.launchpad_token_single {
  background: #fff;
  border: 1px solid #ebeff4;
  border-radius: 10px;
  box-shadow: 0 0 10px 8px rgb(241 247 253 / 80%);
  margin-bottom: 30px;
}
.launchpad_token_single img {
  width: 100%;
  border-radius: 10px 10px 0 0;
}
.launchpad_token_single p {
  padding: 0 15px;
  margin: 0 0 10px;
  font-size: 14px;
  color: #000;
}
.launchpad_token_single h4 {
  font-size: 18px;
  color: #000;
  margin: 15px 0 5px;
}
.launchpad_token_single .btnLandingJoin {
  font-size: 14px;
  padding: 7px 30px;
  margin-bottom: 20px;
}
.launchpad_token_single > div p:first-child {
  color: #7d7d7d;
}
.launchpad_token_single h6 {
  color: #7d7d7d;
  font-size: 14px;
}
.whiteBoxLaunchpad {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 7px 3px rgb(0 0 0 / 10%);
}
.launchpadCoinName {
  display: flex;
  align-items: center;
}
.launchpadCoinName img {
  margin-right: 15px;
}
.launchpadCoinName h3 {
  font-size: 36px;
  font-weight: 400;
}
.launchpadCoinName h3 small {
  display: block;
  font-size: 16px;
  color: #7d7d7d;
}
.launchPadCoinDetails h4 {
  font-size: 18px;
  color: #000;
  border: 2px solid #fcb305;
  padding: 10px 30px;
  border-radius: 50px;
}
.launchPadCoinDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.whiteShadowBox {
  box-shadow: 0 0 4px 10px rgb(231 237 243 / 50%);
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 30px;
}
.projectIntroduction h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
}
.projectIntroduction ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.projectIntroduction li {
  color: #000;
  font-size: 14px;
  margin: 10px 0;
}
.projectIntroduction li span {
  font-size: 14px;
  color: #7d7d7d;
  width: 40%;
  display: inline-block;
}
.projectIntroduction p {
  color: #7d7d7d;
  font-size: 14px;
}
.whiteShadowBox .form-group label {
  color: #000;
  font-size: 16px;
}
.choose_coin_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 10px;
}
.choose_coin_list li {
  margin: 0 25px 0 0;
}
.choose_coin_list li a {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 700;
  transition: 0.4s all;
}
.choose_coin_list li a.active {
  color: #03005b;
  border-bottom: 2px solid #fcb305;
}
.whiteShadowBox input.form-control {
  border: 1px solid #e1e1e1;
}
.whiteShadowBox span.input-group-text {
  background-color: transparent;
  border: 1px solid #e1e1e1;
}
.btnGroupPercent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.whiteShadowBox .custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #fec003;
  background-color: #febf04;
}
.whiteShadowBox h3 {
  font-size: 18px;
}
.whiteShadowBox .table thead th {
  border-bottom: 0;
}
.whiteShadowBox .table tbody {
border-top: 0;
}
.project_docs a {
  font-size: 16px;
  color: #7d7d7d;
  margin: 0 40px 0 0;
  display: inline-block;
}
.project_docs a.active {
  color: #fec003;
}
.socialMediaCoinDetails ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.socialMediaCoinDetails ul li {
  margin: 0 20px 0 0;
}
.socialMediaCoinDetails ul li a {
  color: #000;
  border: 1px solid #000;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 18px;
  transition: 0.4s all;
}
.socialMediaCoinDetails ul li a:hover {
  color: #fec003;
  border: 1px solid #fec003;
}
.socialMediaCoinDetails h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
  margin-top: 30px;
}
.cdTeamDetail h3 {
  font-size: 18px;
  border-top: 1px solid #e0e6ee;
  border-bottom: 1px solid #e0e6ee;
  padding: 10px 0;
  margin: 30px 0;
}
.cdTeamDetail ul {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0;
}
.cdTeamProfilePic img {
  border-radius: 50%;
  margin-right: 10px;
}
.cdTeamDetail ul li {
  display: flex;
}
.cdTeamProfileDetail h6 {
  font-size: 14px;
  margin: 0;
}
.cdTeamProfileDetail p {
  color: #7d7d7d;
  font-size: 14px;
  margin: 0;
}
.cdTeamProfileDetail a {
  color: #000;
  border: 1px solid #000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
}
.cdTeamProfileDetail a:hover {
  color: #fec003;
  border: 1px solid #fec003;
}
.currency_select {
  background: #fff url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center;
  border: 0;
  box-shadow: none !important;
  font-size: 16px;
  color: #646e88;
  font-weight: 600;
}
.chart_btns button {
  background: #fff;
  border-radius: 5px;
  font-size: 13px;
  color: #646e88;
  font-weight: 600;
}
.chart_btns {
  background: #f1f2f9;
  padding: 5px;
}
.chart_btns .MuiButton-root:hover{  
  background: #faa400 !important;
  color: #fff;
}
.chart_btns button:nth-child(2) {
  margin: 0 5px;
}
.chart_btns button.active {
  background: #faa400;
  color: #fff; 
}
.exchange_value {
  display: flex;
  align-items: center;
}
.exchange_value h2 {
  color: #292f45;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}
.exchange_value p {
  font-size: 13px;
  font-weight: 600;
  margin: 0 0 0 10px;
}
.exchange_div {
  position: relative;
  z-index: 10;
  width: 100%;
}
.trade_wrap_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.trade_wrap_title h2 {
  font-size: 28px;
  color: #292f45;
  font-weight: 700;
  margin: 0;
}
.trade_wrap_title h5 {
  font-size: 14px;
  font-weight: 500;
  color: #646e88;
  margin: 0;
}
button.round_btn {
  background-color: #f1f2f9;
  color: #292f45;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  padding: 0;
  min-width: 40px;
  margin-left: 8px;
  transition: 0.4s all;
  font-size: 18px;
  font-weight: 700 !important;
}
button.round_btn .fas{
  font-weight: 600;
}
button.round_btn a {
  color: #a1a1a1;
}
button.round_btn a:hover,
button.round_btn:hover a {
  color: #fff;
}
button.round_btn svg {
  font-size: 30px;
}
button.round_btn:hover {  
  background-color: #f7a100;
  color: #fff;
}
button.round_btn:hover img {
  filter: invert(1) sepia(1);
}
.input_panel {
  background-color: #e9ecf4;
  border-radius: 10px;
  padding: 12px 15px 10px;
  position: relative;
}
input.custom_inp {
  border: 0;
  font-size: 15px;
  font-weight: 600;
  color: #283045;
  background-color: #e9ecf4;
}
.input_panel label {
  font-size: 15px;
  font-weight: 500;
  color: #657191;
}
.input_panel label span {
  color: #292f45;
  font-size: 15px;
  font-weight: 700;
}
.equivalent_value {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #32a8df;
  font-size: 15px;
}
.equivalent_value p {
  margin: 0 10px 0 0;
  font-size: 15px;
  font-weight: 600;
  color: #646e88;
}
button.token_btn {
  font-size: 14px;
  font-weight: 500;
  color: #283045;
  text-transform: capitalize;
}
button.token_btn img {
  margin-right: 5px;
  width: 25px;
}
button.token_btn i {
  margin-left: 8px;
}
.trade_right_img {
  margin-top: 180px;
}
button.primary_btn.blue_btn {
  background-color: #32a8df;
  border: 2px solid #32a8df;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-transform: unset;
  width: 100%;
}
button.primary_btn.blue_btn:hover {
  color: #ffffff;
  background-color: #f7a100;
  border: 2px solid #ffd800;
}
input.search_inp {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: 10px;
}
.select_token_list {
  list-style: none;
  padding: 0;
  height: calc(100vh - 300px);
  overflow-y: scroll;
}
.select_token_list li {
  display: flex;
  border-top: 2px dashed #ededed;
  padding: 15px 0;
  align-items: center;
  cursor: pointer;
}
.select_token_list li img {
  width: 25px;
}
.select_token_list li p {
  margin: 0 8px;
  font-size: 16px;
  color: #333;
}
button.primary_btn a {
  color: #fff;
}
button.primary_btn a:hover,
button.primary_btn:hover a {
  color: #32a8df;
}
.trade_notes > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.trade_notes > div span {
  font-size: 14px;
  font-weight: 600;
  color: #646e88;
}
.trade_notes > div span:nth-child(2) {
  font-weight: 700;
  color: #292f45;
}
.trade_notes > .bestRoutes span:nth-child(2){
  font-weight: 400 !important;
  color:#646e88;
}
.history_table{
  background-color: green;
}
.history_table_div h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  background: #2976a9;
  margin-top: -35px;
  padding: 10px 5px 10px 30px;
  border-radius: 5px;
  margin: 0;
  box-shadow: 0px 0px 1px 0px black;
  margin-top: -40px;
}

.rdt_TableCol_Sortable {
  color: #292f45;
  font-size: 16px;  
}
.rdt_TableCol_Sortable div {
  font-weight: 600 !important;
}
.rdt_TableCell {
  color: #646e88;
  font-size: 14px;
  font-weight: 500 !important;
}
.table_crypto_icon {
  width: 26px;
  margin-right: 8px;
}
.rdt_TableCell i {
  color: #868ea2;
}
.footer_about {
  font-size: 13px;
  color: #8e8cce;
}
.top_menu {
  top: 20px;
  left: 20px;
}
.sticky_left_nav {
  position: fixed;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 1001;
  background-image: linear-gradient(-300deg, #3a3480, #1d4e84);
  height: 95vh;
  border-radius: 10px;
}
.stick_left_nav_ul li {
  padding: 5px 10px;
  float: none;
}
.stick_left_nav_ul .top_nav_login {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 3px;
  transition: 0.2s all;
}
.stick_left_nav_ul .top_nav_login img {
  width: 20px;
  transition: 0.2s all;
}

.stick_left_nav_ul .top_nav_login:hover::before, .stick_left_nav_ul .top_nav_login.active::before {
  content: "";
  position: absolute;
  height: 50%;
  background: #f7a100;
  left: 0px;
  width: 3px;
}
.stick_left_nav_ul li .top_nav_login:hover img,
.stick_left_nav_ul li .top_nav_login.active img {
  filter: invert(16%) sepia(34%) saturate(7404%) hue-rotate(4deg) brightness(98%) contrast(111%);
  /* background: url("../src/assets/images/homehvr.png") no-repeat; */
}
.brand_logo_mobile {
  display: none;
}
img.brand_desk_logo {
  margin: 10px 0 0;
  padding: 0 8px;
}
.rdt_TableRow {
  cursor: default;
}
.primary_modal .modal-header {
  padding-top: 10px !important;
}
.primary_modal .modal-body {
  padding-top: 5px;
}
.tooltip_content {
  font-size: 14px !important;
}
.tooltip_icon {
  color: #32a8df;
  font-size: 18px !important;
  margin-left: 8px;
}
.wallet_panel_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wallet_panel {
  background-color: #f1f9fd;
  border: 1px solid #d0e9f5;
  padding: 15px 5px;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px;
  text-align: center;
  width: 28%;
  transition: 0.2s all;
}
.wallet_panel:hover {
  background-color: #32a8df;
}
.wallet_panel img {
  width: 40px;
  margin-bottom: 10px;
}
.wallet_panel h2 {
  color: #32a8df;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.wallet_panel:hover h2 {
  color: #fff;
}
.wallet_modal_footer a {
  display: flex;
  align-items: center;
}
.wallet_modal_footer span {
  font-size: 16px;
  font-weight: 600;
  color: #32a8df;
}
.liquidity_div {
  position: relative;
  z-index: 10;
}

.liquidity_table h2 {
  font-size: 28px;
  font-weight: 700;
  color: #292f45;
  margin: 0 0 10px;
}
.liquidity_table p {
  color: #646e88;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 15px;
}
.pools_filter {
  background-image: linear-gradient(90deg, #3a3480, #1d4e84);
  margin-top: 30px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.pools_filter_left {
  display: flex;
  align-items: center;
}
.pools_filter_right {
  display: flex;
}
.pools_filter_left .custom-checkbox .custom-control-label::before {
  border: 2px solid #32a8df;
  background: #3f396b;
  box-shadow: none !important;
}
.pools_filter_left .custom-checkbox label {
  font-size: 14px;
  color: #fff;
  font-weight: 700;
}
.pools_filter_left .custom-checkbox {
  margin-top: 10px;
}
.pools_filter_left .custom-control-label::before {
  top: -2px;
  left: -25px;
}
.pools_filter_left .custom-control-label::after {
  top: -2px;
  left: -25px;
}


.pool_btn_grp {
  background: #3a4a7b;
  border-radius: 10px;
  padding: 8px;
  margin-left: 20px;
}
.pool_btn_grp button {
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  font-weight: 500;
  text-transform: unset;
}
.pool_btn_grp button.active {
  background: #32a8df;
  color: #fff;
  border-radius: 10px;
}
.pool_btn_grp button:nth-child(1) {
  margin-right: 8px;
}
.pools_filter_right .custom-select {
  background: #2e72a0 url("../src/assets/images/icon_caret_down.png") no-repeat right 0.75rem center;
  border: 1px solid #2e72a0;
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  font-weight: 500;
  box-shadow: none !important;
  margin: 0 15px;
  border-radius: 10px;
}
.pool_search::placeholder {
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  font-weight: 500;
}
.pool_search {
  background: #2e72a0;
  border: 1px solid #2e72a0;
  color: rgb(255 255 255 / 50%);
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  padding-left: 10px;
}
.view_btn_grp {
  display: flex;
  margin-left: 15px;
}
.view_btn_grp button {
  background-color: #2983b0;
  font-size: 24px;
  color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  min-width: inherit;
}
.view_btn_grp button.active,
.view_btn_grp button:hover {
  background-color: #32a8df;
}
.view_btn_grp button:nth-child(1) {
  margin-right: 10px;
}
.grid_view_single {
  background: #fff;
  border-radius: 10px;
  margin: 30px 0;
  padding: 20px 0 15px;
}
.grid_view_single_first {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
}
.grid_view_single_first h2 {
  color: #283044;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.grid_view_single_second {
  padding: 0 15px;
}
.grid_view_single_second p {
  color: #283044;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.grid_view_single_second label {
  font-size: 14px;
  font-weight: 500;
  color: #646e88;
} 
.grid_view_single_first img {
  width: 36px;
}
.grid_view_single_first img.farms_pair_img {
  width: 60px !important;
}
.grid_view_single_second button.primary_btn.blue_btn {
  font-size: 15px;
  font-weight: 600;
}
.grid_view_single_second i {
  margin-left: 8px;
  cursor: pointer;
  color: #3d3d3d;
  font-size: 15px;
}
.grid_view_single_third {
  padding: 0 15px;
}
.accordian_link:after {
  content: "\f107";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  float: right;
  background-color: #32a8df;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-left: 10px;
}
.accordian_link.collapsed:after {
  content: "\f077";
  background-color: #eaf6fc;
  color: #32a8df;
}
button.manual_btn {
  font-size: 12px;
  font-weight: 700;
  color: #32a8df;
  text-transform: unset;
  background-color: transparent;
  border: 1px solid #32a8df;
  padding: 4px 10px;
}
.accordian_link {
  color: #32a8df !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  display: flex;
  align-items: center;
  text-decoration: none !important;
}
.grid_view_single_third h3 {
  color: #646e88;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
}
.grid_view_single_third h4 {
  color: #283044;
  font-size: 15px;
  font-weight: 700;
}
.grid_view_single_third a {
  color: #32a8df;
  font-size: 14px;
  font-weight: 600;
}
.grid_view_single_third a:hover {
  text-decoration: underline;
}
.grid_view_single_third a i::before {
  font-weight: 600 !important;
  margin-left: 3px;
}
.roi_table.table thead th {
  border: 0;
}
.roi_table.table tbody td,
.roi_table.table thead th {
  padding: 5px 10px;
  font-size: 14px;
}
p.roi_notes {
  font-size: 14px;
  line-height: 24px;
  color: #464646;
}
.list_view_single_first {
  display: flex;
  align-items: center;
}
.list_view_single_first h2 {
  font-size: 14px;
  color: #283044;
  font-weight: 700;
  margin: 0;
}
.list_view_single_first p {
  font-size: 13px;
  font-weight: 500;
  color: #646e88;
  margin: 0;
  line-height: 16px;
}
.list_view_single_first img {
  width: 40px;
  margin-right: 5px;
}
.list_view_single {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list_view_single_second {
  display: flex;
}
.list_view_single_second h3 {
  color: #646e88;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 5px;
}
.list_view_single_second h4 {
  color: #292f45;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.list_view_single_second > div {
  margin: 0 30px;
}
.list_view_single_second p {
  color: #292f45;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.list_view_single_second i {
  margin-left: 5px;
}
.list_view .card-body {
  background-color: #f1f2f9;
}
.list_view_single_card_body {
  display: flex;
  justify-content: space-between;
}
.list_view_single_card_body_right {
  display: flex;
}
.list_view_single_card_body_right .input_panel,
.list_view_single_card_body_right input.custom_inp {
  background-color: #fff;
}
.list_view_start_stake {
  background: #fff;
  padding: 15px;
  margin-left: 20px;
  border-radius: 10px;
  width: 250px;
}
.list_view_start_stake button.primary_btn.blue_btn {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}
.list_view_start_stake button.primary_btn.blue_btn:hover {
  color: #fff;
}
.list_view_start_stake h4 {
  font-size: 14px;
  font-weight: 500;
  color: #646e88;
}
.list_view_single_card_body_left a {
  font-size: 14px;
  font-weight: 700;
}
.list_view_single_card_body_left p {
  margin: 0;
}
.list_view_single_card_body_left a i::before {
  font-weight: 600 !important;
}
.list_view .card {
  border-bottom: 1px solid rgb(32 41 60 / 10%) !important;
}
.list_view .card-header {
  cursor: default;
}
.list_view {
  margin: 30px 0;
  display: none;
}
button.harvest_btn {
  color: #32a8df;
  font-size: 15px;
  text-transform: capitalize;
  background: #c0e2f2;
  border: 2px solid #c0e2f2;
  transition: 0.4s all;
  padding: 3px 18px;
  border-radius: 10px;
}
button.harvest_btn.disabled_btn:hover {
  background: #c0e2f2;
  border: 2px solid #c0e2f2;
}
button.harvest_btn:hover {
  background: transparent;
  border: 2px solid #c0e2f2;
}
button.disabled_btn {
  cursor: not-allowed !important;
}
.grid_view_single_second h4 {
  margin: 0 0 5px;
}
.referral_box h2 {
  font-size: 20px;
  font-weight: 700;
  color: #000;
  margin: 0;
}
.referral_inp {
  background-color: #eff0f9;
  border: 1px solid #eff0f9;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 500;
  color: #32a8df;
  margin-top: 10px;
}
button.referral_btn {
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  background-color: #32a8df;
  border: 1px solid #32a8df;
  margin-top: 15px;
  padding: 7px ​15px;
}
button.referral_btn i::before {
  font-weight: 600;
  font-size: 14px;
  margin-left: 4px;
}
button.referral_btn:hover {
  background-color: transparent;
  border: 1px solid #32a8df;
  color: #32a8df;
}
.total_referral {
  color: #32a8df;
  font-size: 45px;
  font-weight: 700;
  margin: 5px 0 7px;
}
.referral_level_table td img {
  width: 24px;
  margin-right: 10px;
}
.referral_level_table tr td {
  font-size: 15px;
  font-weight: 600;
  color: rgb(0 0 0 / 50%);
  padding: 17px 0;
}
.referral_level_table tr td:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}
.top_level {
  color: #000;
}
.snap_nav {
  border-top: 1px solid #e9ecf5;
  margin: 20px 0 0;
  display: block;
  min-width: 100%;
  overflow-x: auto;
  padding-bottom: 5px;
}
.snap_nav .nav-tabs {
  padding: 0;
  justify-content: space-between;
  margin-top: 10px;
  display: inline-block;
  min-width: 100%;
  white-space: nowrap;
}
.snap_nav a {
  padding: 0 12px !important;
  display: inline-block;
}
.snap_nav a h4 {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0;
}
.snap_nav a h4 span {
  display: block;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: rgb(0 0 0 / 80%);
  margin: 0;
}
.snap_nav a.nav-link.active h4 {
  color: #32a8df;
}
.referral_box_select {
  background: #e9ecf5 url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center;
  font-size: 14px;
  font-weight: 500;
  width: 250px;
  color: #646e88;
}
.referral_box h6 {
  font-size: 15px;
  font-weight: 500;
  color: #666666;
  text-transform: unset;
}
.referral_steps h2 {
  font-size: 35px;
  font-weight: 700;
  color: #000;
  padding: 0 200px;
}
.referral_steps_single {
  text-align: center;
  width: 220px;
  margin-bottom: 30px;
}
.referral_steps_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
ul.share_ref_list {
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}
ul.share_ref_list li a {
  width: 35px;
  height: 35px;
  background: #32a8df;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: 0 10px 0 0;
  color: #fff;
}
.referral_steps_single h3 {
  color: #292f45;
  font-size: 20px;
  font-weight: 700;
}
.referral_steps_single p {
  color: #646e88;
  font-size: 16px;
  font-weight: 500;
}
.referral_bottom {
  background: url("../src/assets/images/referral_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 0 30px;
}
.referral_bottom_single {
  background: #fff;
  padding: 20px 15px;
  border-radius: 10px;
  margin-bottom: 30px;
  text-align: center;
}
.referral_bottom_single h2 {
  font-size: 18px;
  font-weight: 700;
  color: #292f45;
  margin: 30px 0 10px;
}
.referral_bottom_single p {
  font-size: 15px;
  font-weight: 500;
  color: #646e88;
  margin: 0;
  min-height: 80px;
}
#success_modal h2 {
  font-size: 28px;
  font-weight: 600;
  margin-top: 0;
}
#success_modal p {
  font-size: 15px;
  color: #666666;
}
#success_modal .modal-dialog .modal-content {
  background: #fff url("../src/assets/images/success_modal_bg.png") no-repeat;
  background-position: top center;
}
.swap_coin_info {
  position: relative;
}
.swap_coin_info i {
  position: absolute;
  color: #b0b7cb;
  font-size: 18px;
  top: 56px;
  left: 5px;
}
.swap_coin_info img {
  width: 35px;
}
.swap_coin_info div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecf4;
  padding: 20px 0;
}
.swap_coin_info div span {
  color: #283045;
  font-size: 18px;
  font-weight: 700;
}
.swap_coin_info div span:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}
.swap_info_mid {
  padding: 15px 0;
}
.swap_info_mid img {
  width: 25px;
}
.swap_info_mid p {
  color: #646e88;
  font-size: 16px;
  font-weight: 500;
  font-style: italic;
}
.swap_info_mid div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecf4;
  padding: 25px 0 15px;
}
.swap_info_mid div span {
  color: #646e88;
  font-size: 16px;
  font-weight: 600;
}
.swap_info_mid div span:nth-child(2) {
  font-size: 18px;
  color: #283045;
  font-weight: 700;
}
.swap_info_end {
  padding-bottom: 30px;
}
.swap_info_end > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.swap_info_end > div span {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #646e88;
}
.swap_info_end > div div {
  text-align: right;
}
.swap_info_end > div div span {
  color: #283045;
  font-size: 16px;
  font-weight: 500;
}
#confirm_swap_modal .swap_coin_info img {
  width: 25px;
}
#confirm_swap_modal .swap_coin_info div span {
  font-size: 20px;
}
#confirm_swap_modal .swap_info_mid p {
  font-style: normal;
}
#confirm_swap_modal .swap_info_mid p span {
  font-weight: 700;
  color: #283045;
}
#confirm_swap_modal .swap_info_end {
  background: #f5f6fa;
  padding: 15px 10px 20px;
  border-bottom: 1px solid #e9ecf4;
  border-top: 1px solid #e9ecf4;
  margin-bottom: 20px;
}
#confirm_swap_modal .swap_info_end > div span {
  font-size: 15px;
}
#confirm_swap_modal .swap_info_end > div span:nth-child(2) {
  color: #283045;
  font-weight: 600;
}
.chart_container {
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 360px;
}
.chart_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 22%;
  right: 20%;
  bottom: 20%;
  left: 20%;
  border-radius: 50%;
  padding: 1.25em 0;
  line-height: 46px;
  z-index: 1; /* Allow tooltips on top */
  overflow: hidden;
}
.chart_status {
  font-size: 50px;
  font-weight: 700;
  color: #283045;
  text-align: center;
}
.chart_status span {
  display: block;
  color: #657191;
  font-size: 24px;
  font-weight: 500;
}
.chart_data h2 {
  color: #000;
  font-size: 20px;
  font-weight: 700;
}
.chart_data ul {
  padding-left: 30px;
}
.chart_data ul li {
  color: #657191;
  list-style: none;
  padding: 10px 0;
  position: relative;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}
.chart_data ul li span:nth-child(2) {
  font-size: 20px;
  font-weight: 700;
  color: #171b1e;
}
.chart_data ul li::before {
  content: "";
  background: #f7931a;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 12px;
  left: -30px;
}
.chart_data ul li:nth-child(2):before {
  background: #627eea;
}
.chart_data ul li:nth-child(3):before {
  background: #42c1ca;
}
.chart_data ul li:nth-child(4):before {
  background: #c3a634;
}
.chart_data ul li:nth-child(5):before {
  background: #ef0027;
}
button.buy_coin_btn {
  color: #32a8df;
  font-size: 18px;
  font-weight: 700;
  background: #ffffff;
  border-radius: 5px;
  text-transform: unset;
  border: 2px solid #fff;
  padding: 6px 20px;
}
button.buy_coin_btn:hover {
  color: #fff;
  background: transparent;
}
.starts_section h2 {
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}
.starts_section p {
  color:#fff;
}
.starts_section .para {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
} 
.banner_ticker_panel .slick-prev {
  left: -50px;
  background: url("../src/assets/images/leftarw.png") !important;
}
.banner_ticker_panel .slick-next {
  right: -50px;
  background: url("../src/assets/images/rightarwhvr.png") !important;
}
.banner_ticker_panel .slick-prev, 
.banner_ticker_panel .slick-next {
  width: 48px;
  height: 48px;
  transition: all 0.2s;
}
.banner_ticker_panel .slick-prev:hover {  
  background: url("../src/assets/images/leftarwhvr.png") !important;
}
.banner_ticker_panel .slick-next:hover {
  background: url("../src/assets/images/rightarw.png") !important;

}
.banner_ticker_panel .slick-prev:hover, 
.banner_ticker_panel .slick-next:hover {
  filter: drop-shadow(2px 4px 3px rgb(0 0 0 / 50%));
}
.banner_ticker_panel .slick-prev::before,
.banner_ticker_panel .slick-next::before {
  content: "";
}
.page-header canvas {
  position: fixed;
  top: 0;
}
.MuiSlider-root {
  color: #32a8df !important;
}
.circle-container {
  position: absolute;
  transform: translateY(-10vh);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.circle-container .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#99ffff, #99ffff 10%, rgba(153, 255, 255, 0) 56%);
  -webkit-animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
          animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}
@-webkit-keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-frames {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }
  50% {
    transform: scale3d(2.2, 2.2, 1);
  }
  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}
.circle-container:nth-child(1) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-1;
          animation-name: move-frames-1;
  -webkit-animation-duration: 29641ms;
          animation-duration: 29641ms;
  -webkit-animation-delay: 14573ms;
          animation-delay: 14573ms;
}
@-webkit-keyframes move-frames-1 {
  from {
    transform: translate3d(47vw, 103vh, 0);
  }
  to {
    transform: translate3d(59vw, -110vh, 0);
  }
}
@keyframes move-frames-1 {
  from {
    transform: translate3d(47vw, 103vh, 0);
  }
  to {
    transform: translate3d(59vw, -110vh, 0);
  }
}
.circle-container:nth-child(1) .circle {
  -webkit-animation-delay: 2235ms;
          animation-delay: 2235ms;
}
.circle-container:nth-child(2) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-2;
          animation-name: move-frames-2;
  -webkit-animation-duration: 31753ms;
          animation-duration: 31753ms;
  -webkit-animation-delay: 9730ms;
          animation-delay: 9730ms;
}
@-webkit-keyframes move-frames-2 {
  from {
    transform: translate3d(25vw, 108vh, 0);
  }
  to {
    transform: translate3d(27vw, -116vh, 0);
  }
}
@keyframes move-frames-2 {
  from {
    transform: translate3d(25vw, 108vh, 0);
  }
  to {
    transform: translate3d(27vw, -116vh, 0);
  }
}
.circle-container:nth-child(2) .circle {
  -webkit-animation-delay: 1421ms;
          animation-delay: 1421ms;
}
.circle-container:nth-child(3) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-3;
          animation-name: move-frames-3;
  -webkit-animation-duration: 33224ms;
          animation-duration: 33224ms;
  -webkit-animation-delay: 14986ms;
          animation-delay: 14986ms;
}
@-webkit-keyframes move-frames-3 {
  from {
    transform: translate3d(19vw, 105vh, 0);
  }
  to {
    transform: translate3d(53vw, -127vh, 0);
  }
}
@keyframes move-frames-3 {
  from {
    transform: translate3d(19vw, 105vh, 0);
  }
  to {
    transform: translate3d(53vw, -127vh, 0);
  }
}
.circle-container:nth-child(3) .circle {
  -webkit-animation-delay: 402ms;
          animation-delay: 402ms;
}
.circle-container:nth-child(4) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-4;
          animation-name: move-frames-4;
  -webkit-animation-duration: 31890ms;
          animation-duration: 31890ms;
  -webkit-animation-delay: 17711ms;
          animation-delay: 17711ms;
}
@-webkit-keyframes move-frames-4 {
  from {
    transform: translate3d(89vw, 106vh, 0);
  }
  to {
    transform: translate3d(88vw, -112vh, 0);
  }
}
@keyframes move-frames-4 {
  from {
    transform: translate3d(89vw, 106vh, 0);
  }
  to {
    transform: translate3d(88vw, -112vh, 0);
  }
}
.circle-container:nth-child(4) .circle {
  -webkit-animation-delay: 779ms;
          animation-delay: 779ms;
}
.circle-container:nth-child(5) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-5;
          animation-name: move-frames-5;
  -webkit-animation-duration: 33732ms;
          animation-duration: 33732ms;
  -webkit-animation-delay: 15614ms;
          animation-delay: 15614ms;
}
@-webkit-keyframes move-frames-5 {
  from {
    transform: translate3d(48vw, 106vh, 0);
  }
  to {
    transform: translate3d(10vw, -111vh, 0);
  }
}
@keyframes move-frames-5 {
  from {
    transform: translate3d(48vw, 106vh, 0);
  }
  to {
    transform: translate3d(10vw, -111vh, 0);
  }
}
.circle-container:nth-child(5) .circle {
  -webkit-animation-delay: 525ms;
          animation-delay: 525ms;
}
.circle-container:nth-child(6) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-6;
          animation-name: move-frames-6;
  -webkit-animation-duration: 36334ms;
          animation-duration: 36334ms;
  -webkit-animation-delay: 5482ms;
          animation-delay: 5482ms;
}
@-webkit-keyframes move-frames-6 {
  from {
    transform: translate3d(87vw, 104vh, 0);
  }
  to {
    transform: translate3d(36vw, -133vh, 0);
  }
}
@keyframes move-frames-6 {
  from {
    transform: translate3d(87vw, 104vh, 0);
  }
  to {
    transform: translate3d(36vw, -133vh, 0);
  }
}
.circle-container:nth-child(6) .circle {
  -webkit-animation-delay: 940ms;
          animation-delay: 940ms;
}
.circle-container:nth-child(7) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-7;
          animation-name: move-frames-7;
  -webkit-animation-duration: 30572ms;
          animation-duration: 30572ms;
  -webkit-animation-delay: 34728ms;
          animation-delay: 34728ms;
}
@-webkit-keyframes move-frames-7 {
  from {
    transform: translate3d(88vw, 107vh, 0);
  }
  to {
    transform: translate3d(37vw, -118vh, 0);
  }
}
@keyframes move-frames-7 {
  from {
    transform: translate3d(88vw, 107vh, 0);
  }
  to {
    transform: translate3d(37vw, -118vh, 0);
  }
}
.circle-container:nth-child(7) .circle {
  -webkit-animation-delay: 2903ms;
          animation-delay: 2903ms;
}
.circle-container:nth-child(8) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-8;
          animation-name: move-frames-8;
  -webkit-animation-duration: 31938ms;
          animation-duration: 31938ms;
  -webkit-animation-delay: 21055ms;
          animation-delay: 21055ms;
}
@-webkit-keyframes move-frames-8 {
  from {
    transform: translate3d(91vw, 104vh, 0);
  }
  to {
    transform: translate3d(51vw, -111vh, 0);
  }
}
@keyframes move-frames-8 {
  from {
    transform: translate3d(91vw, 104vh, 0);
  }
  to {
    transform: translate3d(51vw, -111vh, 0);
  }
}
.circle-container:nth-child(8) .circle {
  -webkit-animation-delay: 1146ms;
          animation-delay: 1146ms;
}
.circle-container:nth-child(9) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-9;
          animation-name: move-frames-9;
  -webkit-animation-duration: 30130ms;
          animation-duration: 30130ms;
  -webkit-animation-delay: 31891ms;
          animation-delay: 31891ms;
}
@-webkit-keyframes move-frames-9 {
  from {
    transform: translate3d(55vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -130vh, 0);
  }
}
@keyframes move-frames-9 {
  from {
    transform: translate3d(55vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -130vh, 0);
  }
}
.circle-container:nth-child(9) .circle {
  -webkit-animation-delay: 3840ms;
          animation-delay: 3840ms;
}
.circle-container:nth-child(10) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-10;
          animation-name: move-frames-10;
  -webkit-animation-duration: 31334ms;
          animation-duration: 31334ms;
  -webkit-animation-delay: 5767ms;
          animation-delay: 5767ms;
}
@-webkit-keyframes move-frames-10 {
  from {
    transform: translate3d(84vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -121vh, 0);
  }
}
@keyframes move-frames-10 {
  from {
    transform: translate3d(84vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -121vh, 0);
  }
}
.circle-container:nth-child(10) .circle {
  -webkit-animation-delay: 2223ms;
          animation-delay: 2223ms;
}
.circle-container:nth-child(11) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-11;
          animation-name: move-frames-11;
  -webkit-animation-duration: 30243ms;
          animation-duration: 30243ms;
  -webkit-animation-delay: 34516ms;
          animation-delay: 34516ms;
}
@-webkit-keyframes move-frames-11 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }
  to {
    transform: translate3d(33vw, -107vh, 0);
  }
}
@keyframes move-frames-11 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }
  to {
    transform: translate3d(33vw, -107vh, 0);
  }
}
.circle-container:nth-child(11) .circle {
  -webkit-animation-delay: 3537ms;
          animation-delay: 3537ms;
}
.circle-container:nth-child(12) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-12;
          animation-name: move-frames-12;
  -webkit-animation-duration: 30514ms;
          animation-duration: 30514ms;
  -webkit-animation-delay: 31646ms;
          animation-delay: 31646ms;
}
@-webkit-keyframes move-frames-12 {
  from {
    transform: translate3d(26vw, 103vh, 0);
  }
  to {
    transform: translate3d(28vw, -105vh, 0);
  }
}
@keyframes move-frames-12 {
  from {
    transform: translate3d(26vw, 103vh, 0);
  }
  to {
    transform: translate3d(28vw, -105vh, 0);
  }
}
.circle-container:nth-child(12) .circle {
  -webkit-animation-delay: 632ms;
          animation-delay: 632ms;
}
.circle-container:nth-child(13) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-13;
          animation-name: move-frames-13;
  -webkit-animation-duration: 30350ms;
          animation-duration: 30350ms;
  -webkit-animation-delay: 28598ms;
          animation-delay: 28598ms;
}
@-webkit-keyframes move-frames-13 {
  from {
    transform: translate3d(14vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -129vh, 0);
  }
}
@keyframes move-frames-13 {
  from {
    transform: translate3d(14vw, 103vh, 0);
  }
  to {
    transform: translate3d(11vw, -129vh, 0);
  }
}
.circle-container:nth-child(13) .circle {
  -webkit-animation-delay: 3688ms;
          animation-delay: 3688ms;
}
.circle-container:nth-child(14) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-14;
          animation-name: move-frames-14;
  -webkit-animation-duration: 30550ms;
          animation-duration: 30550ms;
  -webkit-animation-delay: 24563ms;
          animation-delay: 24563ms;
}
@-webkit-keyframes move-frames-14 {
  from {
    transform: translate3d(79vw, 110vh, 0);
  }
  to {
    transform: translate3d(38vw, -135vh, 0);
  }
}
@keyframes move-frames-14 {
  from {
    transform: translate3d(79vw, 110vh, 0);
  }
  to {
    transform: translate3d(38vw, -135vh, 0);
  }
}
.circle-container:nth-child(14) .circle {
  -webkit-animation-delay: 785ms;
          animation-delay: 785ms;
}
.circle-container:nth-child(15) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-15;
          animation-name: move-frames-15;
  -webkit-animation-duration: 33035ms;
          animation-duration: 33035ms;
  -webkit-animation-delay: 2880ms;
          animation-delay: 2880ms;
}
@-webkit-keyframes move-frames-15 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }
  to {
    transform: translate3d(33vw, -126vh, 0);
  }
}
@keyframes move-frames-15 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }
  to {
    transform: translate3d(33vw, -126vh, 0);
  }
}
.circle-container:nth-child(15) .circle {
  -webkit-animation-delay: 2438ms;
          animation-delay: 2438ms;
}
.circle-container:nth-child(16) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-16;
          animation-name: move-frames-16;
  -webkit-animation-duration: 30272ms;
          animation-duration: 30272ms;
  -webkit-animation-delay: 12724ms;
          animation-delay: 12724ms;
}
@-webkit-keyframes move-frames-16 {
  from {
    transform: translate3d(48vw, 104vh, 0);
  }
  to {
    transform: translate3d(34vw, -122vh, 0);
  }
}
@keyframes move-frames-16 {
  from {
    transform: translate3d(48vw, 104vh, 0);
  }
  to {
    transform: translate3d(34vw, -122vh, 0);
  }
}
.circle-container:nth-child(16) .circle {
  -webkit-animation-delay: 1861ms;
          animation-delay: 1861ms;
}
.circle-container:nth-child(17) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-17;
          animation-name: move-frames-17;
  -webkit-animation-duration: 28340ms;
          animation-duration: 28340ms;
  -webkit-animation-delay: 13363ms;
          animation-delay: 13363ms;
}
@-webkit-keyframes move-frames-17 {
  from {
    transform: translate3d(8vw, 107vh, 0);
  }
  to {
    transform: translate3d(90vw, -124vh, 0);
  }
}
@keyframes move-frames-17 {
  from {
    transform: translate3d(8vw, 107vh, 0);
  }
  to {
    transform: translate3d(90vw, -124vh, 0);
  }
}
.circle-container:nth-child(17) .circle {
  -webkit-animation-delay: 2386ms;
          animation-delay: 2386ms;
}
.circle-container:nth-child(18) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-18;
          animation-name: move-frames-18;
  -webkit-animation-duration: 36271ms;
          animation-duration: 36271ms;
  -webkit-animation-delay: 20631ms;
          animation-delay: 20631ms;
}
@-webkit-keyframes move-frames-18 {
  from {
    transform: translate3d(43vw, 102vh, 0);
  }
  to {
    transform: translate3d(42vw, -121vh, 0);
  }
}
@keyframes move-frames-18 {
  from {
    transform: translate3d(43vw, 102vh, 0);
  }
  to {
    transform: translate3d(42vw, -121vh, 0);
  }
}
.circle-container:nth-child(18) .circle {
  -webkit-animation-delay: 3878ms;
          animation-delay: 3878ms;
}
.circle-container:nth-child(19) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-19;
          animation-name: move-frames-19;
  -webkit-animation-duration: 30137ms;
          animation-duration: 30137ms;
  -webkit-animation-delay: 14240ms;
          animation-delay: 14240ms;
}
@-webkit-keyframes move-frames-19 {
  from {
    transform: translate3d(8vw, 108vh, 0);
  }
  to {
    transform: translate3d(70vw, -109vh, 0);
  }
}
@keyframes move-frames-19 {
  from {
    transform: translate3d(8vw, 108vh, 0);
  }
  to {
    transform: translate3d(70vw, -109vh, 0);
  }
}
.circle-container:nth-child(19) .circle {
  -webkit-animation-delay: 846ms;
          animation-delay: 846ms;
}
.circle-container:nth-child(20) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-20;
          animation-name: move-frames-20;
  -webkit-animation-duration: 36666ms;
          animation-duration: 36666ms;
  -webkit-animation-delay: 30301ms;
          animation-delay: 30301ms;
}
@-webkit-keyframes move-frames-20 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(96vw, -133vh, 0);
  }
}
@keyframes move-frames-20 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(96vw, -133vh, 0);
  }
}
.circle-container:nth-child(20) .circle {
  -webkit-animation-delay: 74ms;
          animation-delay: 74ms;
}
.circle-container:nth-child(21) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-21;
          animation-name: move-frames-21;
  -webkit-animation-duration: 31487ms;
          animation-duration: 31487ms;
  -webkit-animation-delay: 18754ms;
          animation-delay: 18754ms;
}
@-webkit-keyframes move-frames-21 {
  from {
    transform: translate3d(60vw, 106vh, 0);
  }
  to {
    transform: translate3d(25vw, -127vh, 0);
  }
}
@keyframes move-frames-21 {
  from {
    transform: translate3d(60vw, 106vh, 0);
  }
  to {
    transform: translate3d(25vw, -127vh, 0);
  }
}
.circle-container:nth-child(21) .circle {
  -webkit-animation-delay: 970ms;
          animation-delay: 970ms;
}
.circle-container:nth-child(22) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-22;
          animation-name: move-frames-22;
  -webkit-animation-duration: 29674ms;
          animation-duration: 29674ms;
  -webkit-animation-delay: 28872ms;
          animation-delay: 28872ms;
}
@-webkit-keyframes move-frames-22 {
  from {
    transform: translate3d(93vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -134vh, 0);
  }
}
@keyframes move-frames-22 {
  from {
    transform: translate3d(93vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -134vh, 0);
  }
}
.circle-container:nth-child(22) .circle {
  -webkit-animation-delay: 3941ms;
          animation-delay: 3941ms;
}
.circle-container:nth-child(23) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-23;
          animation-name: move-frames-23;
  -webkit-animation-duration: 36609ms;
          animation-duration: 36609ms;
  -webkit-animation-delay: 26759ms;
          animation-delay: 26759ms;
}
@-webkit-keyframes move-frames-23 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(87vw, -122vh, 0);
  }
}
@keyframes move-frames-23 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(87vw, -122vh, 0);
  }
}
.circle-container:nth-child(23) .circle {
  -webkit-animation-delay: 3476ms;
          animation-delay: 3476ms;
}
.circle-container:nth-child(24) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-24;
          animation-name: move-frames-24;
  -webkit-animation-duration: 28289ms;
          animation-duration: 28289ms;
  -webkit-animation-delay: 28531ms;
          animation-delay: 28531ms;
}
@-webkit-keyframes move-frames-24 {
  from {
    transform: translate3d(44vw, 107vh, 0);
  }
  to {
    transform: translate3d(84vw, -125vh, 0);
  }
}
@keyframes move-frames-24 {
  from {
    transform: translate3d(44vw, 107vh, 0);
  }
  to {
    transform: translate3d(84vw, -125vh, 0);
  }
}
.circle-container:nth-child(24) .circle {
  -webkit-animation-delay: 3929ms;
          animation-delay: 3929ms;
}
.circle-container:nth-child(25) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-25;
          animation-name: move-frames-25;
  -webkit-animation-duration: 30459ms;
          animation-duration: 30459ms;
  -webkit-animation-delay: 565ms;
          animation-delay: 565ms;
}
@-webkit-keyframes move-frames-25 {
  from {
    transform: translate3d(99vw, 105vh, 0);
  }
  to {
    transform: translate3d(91vw, -131vh, 0);
  }
}
@keyframes move-frames-25 {
  from {
    transform: translate3d(99vw, 105vh, 0);
  }
  to {
    transform: translate3d(91vw, -131vh, 0);
  }
}
.circle-container:nth-child(25) .circle {
  -webkit-animation-delay: 828ms;
          animation-delay: 828ms;
}
.circle-container:nth-child(26) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-26;
          animation-name: move-frames-26;
  -webkit-animation-duration: 31617ms;
          animation-duration: 31617ms;
  -webkit-animation-delay: 28945ms;
          animation-delay: 28945ms;
}
@-webkit-keyframes move-frames-26 {
  from {
    transform: translate3d(73vw, 108vh, 0);
  }
  to {
    transform: translate3d(39vw, -138vh, 0);
  }
}
@keyframes move-frames-26 {
  from {
    transform: translate3d(73vw, 108vh, 0);
  }
  to {
    transform: translate3d(39vw, -138vh, 0);
  }
}
.circle-container:nth-child(26) .circle {
  -webkit-animation-delay: 2466ms;
          animation-delay: 2466ms;
}
.circle-container:nth-child(27) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-27;
          animation-name: move-frames-27;
  -webkit-animation-duration: 29007ms;
          animation-duration: 29007ms;
  -webkit-animation-delay: 3845ms;
          animation-delay: 3845ms;
}
@-webkit-keyframes move-frames-27 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(49vw, -130vh, 0);
  }
}
@keyframes move-frames-27 {
  from {
    transform: translate3d(5vw, 107vh, 0);
  }
  to {
    transform: translate3d(49vw, -130vh, 0);
  }
}
.circle-container:nth-child(27) .circle {
  -webkit-animation-delay: 1728ms;
          animation-delay: 1728ms;
}
.circle-container:nth-child(28) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-28;
          animation-name: move-frames-28;
  -webkit-animation-duration: 34859ms;
          animation-duration: 34859ms;
  -webkit-animation-delay: 15988ms;
          animation-delay: 15988ms;
}
@-webkit-keyframes move-frames-28 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(56vw, -124vh, 0);
  }
}
@keyframes move-frames-28 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(56vw, -124vh, 0);
  }
}
.circle-container:nth-child(28) .circle {
  -webkit-animation-delay: 2655ms;
          animation-delay: 2655ms;
}
.circle-container:nth-child(29) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-29;
          animation-name: move-frames-29;
  -webkit-animation-duration: 36616ms;
          animation-duration: 36616ms;
  -webkit-animation-delay: 34537ms;
          animation-delay: 34537ms;
}
@-webkit-keyframes move-frames-29 {
  from {
    transform: translate3d(71vw, 103vh, 0);
  }
  to {
    transform: translate3d(91vw, -108vh, 0);
  }
}
@keyframes move-frames-29 {
  from {
    transform: translate3d(71vw, 103vh, 0);
  }
  to {
    transform: translate3d(91vw, -108vh, 0);
  }
}
.circle-container:nth-child(29) .circle {
  -webkit-animation-delay: 3612ms;
          animation-delay: 3612ms;
}
.circle-container:nth-child(30) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-30;
          animation-name: move-frames-30;
  -webkit-animation-duration: 31308ms;
          animation-duration: 31308ms;
  -webkit-animation-delay: 20547ms;
          animation-delay: 20547ms;
}
@-webkit-keyframes move-frames-30 {
  from {
    transform: translate3d(3vw, 104vh, 0);
  }
  to {
    transform: translate3d(26vw, -127vh, 0);
  }
}
@keyframes move-frames-30 {
  from {
    transform: translate3d(3vw, 104vh, 0);
  }
  to {
    transform: translate3d(26vw, -127vh, 0);
  }
}
.circle-container:nth-child(30) .circle {
  -webkit-animation-delay: 3883ms;
          animation-delay: 3883ms;
}
.circle-container:nth-child(31) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-31;
          animation-name: move-frames-31;
  -webkit-animation-duration: 30460ms;
          animation-duration: 30460ms;
  -webkit-animation-delay: 30386ms;
          animation-delay: 30386ms;
}
@-webkit-keyframes move-frames-31 {
  from {
    transform: translate3d(39vw, 103vh, 0);
  }
  to {
    transform: translate3d(49vw, -110vh, 0);
  }
}
@keyframes move-frames-31 {
  from {
    transform: translate3d(39vw, 103vh, 0);
  }
  to {
    transform: translate3d(49vw, -110vh, 0);
  }
}
.circle-container:nth-child(31) .circle {
  -webkit-animation-delay: 3335ms;
          animation-delay: 3335ms;
}
.circle-container:nth-child(32) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-32;
          animation-name: move-frames-32;
  -webkit-animation-duration: 34707ms;
          animation-duration: 34707ms;
  -webkit-animation-delay: 19470ms;
          animation-delay: 19470ms;
}
@-webkit-keyframes move-frames-32 {
  from {
    transform: translate3d(34vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -111vh, 0);
  }
}
@keyframes move-frames-32 {
  from {
    transform: translate3d(34vw, 110vh, 0);
  }
  to {
    transform: translate3d(56vw, -111vh, 0);
  }
}
.circle-container:nth-child(32) .circle {
  -webkit-animation-delay: 3742ms;
          animation-delay: 3742ms;
}
.circle-container:nth-child(33) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-33;
          animation-name: move-frames-33;
  -webkit-animation-duration: 31189ms;
          animation-duration: 31189ms;
  -webkit-animation-delay: 6131ms;
          animation-delay: 6131ms;
}
@-webkit-keyframes move-frames-33 {
  from {
    transform: translate3d(92vw, 102vh, 0);
  }
  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}
@keyframes move-frames-33 {
  from {
    transform: translate3d(92vw, 102vh, 0);
  }
  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}
.circle-container:nth-child(33) .circle {
  -webkit-animation-delay: 1957ms;
          animation-delay: 1957ms;
}
.circle-container:nth-child(34) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-34;
          animation-name: move-frames-34;
  -webkit-animation-duration: 28060ms;
          animation-duration: 28060ms;
  -webkit-animation-delay: 35825ms;
          animation-delay: 35825ms;
}
@-webkit-keyframes move-frames-34 {
  from {
    transform: translate3d(83vw, 104vh, 0);
  }
  to {
    transform: translate3d(79vw, -109vh, 0);
  }
}
@keyframes move-frames-34 {
  from {
    transform: translate3d(83vw, 104vh, 0);
  }
  to {
    transform: translate3d(79vw, -109vh, 0);
  }
}
.circle-container:nth-child(34) .circle {
  -webkit-animation-delay: 2126ms;
          animation-delay: 2126ms;
}
.circle-container:nth-child(35) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-35;
          animation-name: move-frames-35;
  -webkit-animation-duration: 28539ms;
          animation-duration: 28539ms;
  -webkit-animation-delay: 32327ms;
          animation-delay: 32327ms;
}
@-webkit-keyframes move-frames-35 {
  from {
    transform: translate3d(46vw, 109vh, 0);
  }
  to {
    transform: translate3d(86vw, -138vh, 0);
  }
}
@keyframes move-frames-35 {
  from {
    transform: translate3d(46vw, 109vh, 0);
  }
  to {
    transform: translate3d(86vw, -138vh, 0);
  }
}
.circle-container:nth-child(35) .circle {
  -webkit-animation-delay: 3469ms;
          animation-delay: 3469ms;
}
.circle-container:nth-child(36) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-36;
          animation-name: move-frames-36;
  -webkit-animation-duration: 28438ms;
          animation-duration: 28438ms;
  -webkit-animation-delay: 27158ms;
          animation-delay: 27158ms;
}
@-webkit-keyframes move-frames-36 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }
  to {
    transform: translate3d(43vw, -129vh, 0);
  }
}
@keyframes move-frames-36 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }
  to {
    transform: translate3d(43vw, -129vh, 0);
  }
}
.circle-container:nth-child(36) .circle {
  -webkit-animation-delay: 3119ms;
          animation-delay: 3119ms;
}
.circle-container:nth-child(37) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-37;
          animation-name: move-frames-37;
  -webkit-animation-duration: 35777ms;
          animation-duration: 35777ms;
  -webkit-animation-delay: 27129ms;
          animation-delay: 27129ms;
}
@-webkit-keyframes move-frames-37 {
  from {
    transform: translate3d(76vw, 108vh, 0);
  }
  to {
    transform: translate3d(28vw, -134vh, 0);
  }
}
@keyframes move-frames-37 {
  from {
    transform: translate3d(76vw, 108vh, 0);
  }
  to {
    transform: translate3d(28vw, -134vh, 0);
  }
}
.circle-container:nth-child(37) .circle {
  -webkit-animation-delay: 473ms;
          animation-delay: 473ms;
}
.circle-container:nth-child(38) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-38;
          animation-name: move-frames-38;
  -webkit-animation-duration: 33752ms;
          animation-duration: 33752ms;
  -webkit-animation-delay: 20321ms;
          animation-delay: 20321ms;
}
@-webkit-keyframes move-frames-38 {
  from {
    transform: translate3d(91vw, 108vh, 0);
  }
  to {
    transform: translate3d(37vw, -129vh, 0);
  }
}
@keyframes move-frames-38 {
  from {
    transform: translate3d(91vw, 108vh, 0);
  }
  to {
    transform: translate3d(37vw, -129vh, 0);
  }
}
.circle-container:nth-child(38) .circle {
  -webkit-animation-delay: 1607ms;
          animation-delay: 1607ms;
}
.circle-container:nth-child(39) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-39;
          animation-name: move-frames-39;
  -webkit-animation-duration: 34859ms;
          animation-duration: 34859ms;
  -webkit-animation-delay: 1599ms;
          animation-delay: 1599ms;
}
@-webkit-keyframes move-frames-39 {
  from {
    transform: translate3d(32vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -138vh, 0);
  }
}
@keyframes move-frames-39 {
  from {
    transform: translate3d(32vw, 109vh, 0);
  }
  to {
    transform: translate3d(28vw, -138vh, 0);
  }
}
.circle-container:nth-child(39) .circle {
  -webkit-animation-delay: 1744ms;
          animation-delay: 1744ms;
}
.circle-container:nth-child(40) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-40;
          animation-name: move-frames-40;
  -webkit-animation-duration: 33249ms;
          animation-duration: 33249ms;
  -webkit-animation-delay: 21974ms;
          animation-delay: 21974ms;
}
@-webkit-keyframes move-frames-40 {
  from {
    transform: translate3d(82vw, 107vh, 0);
  }
  to {
    transform: translate3d(1vw, -116vh, 0);
  }
}
@keyframes move-frames-40 {
  from {
    transform: translate3d(82vw, 107vh, 0);
  }
  to {
    transform: translate3d(1vw, -116vh, 0);
  }
}
.circle-container:nth-child(40) .circle {
  -webkit-animation-delay: 3318ms;
          animation-delay: 3318ms;
}
.circle-container:nth-child(41) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-41;
          animation-name: move-frames-41;
  -webkit-animation-duration: 30897ms;
          animation-duration: 30897ms;
  -webkit-animation-delay: 3548ms;
          animation-delay: 3548ms;
}
@-webkit-keyframes move-frames-41 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(38vw, -117vh, 0);
  }
}
@keyframes move-frames-41 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(38vw, -117vh, 0);
  }
}
.circle-container:nth-child(41) .circle {
  -webkit-animation-delay: 967ms;
          animation-delay: 967ms;
}
.circle-container:nth-child(42) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-42;
          animation-name: move-frames-42;
  -webkit-animation-duration: 31198ms;
          animation-duration: 31198ms;
  -webkit-animation-delay: 5054ms;
          animation-delay: 5054ms;
}
@-webkit-keyframes move-frames-42 {
  from {
    transform: translate3d(89vw, 105vh, 0);
  }
  to {
    transform: translate3d(69vw, -131vh, 0);
  }
}
@keyframes move-frames-42 {
  from {
    transform: translate3d(89vw, 105vh, 0);
  }
  to {
    transform: translate3d(69vw, -131vh, 0);
  }
}
.circle-container:nth-child(42) .circle {
  -webkit-animation-delay: 3703ms;
          animation-delay: 3703ms;
}
.circle-container:nth-child(43) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-43;
          animation-name: move-frames-43;
  -webkit-animation-duration: 35195ms;
          animation-duration: 35195ms;
  -webkit-animation-delay: 33448ms;
          animation-delay: 33448ms;
}
@-webkit-keyframes move-frames-43 {
  from {
    transform: translate3d(46vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -125vh, 0);
  }
}
@keyframes move-frames-43 {
  from {
    transform: translate3d(46vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -125vh, 0);
  }
}
.circle-container:nth-child(43) .circle {
  -webkit-animation-delay: 483ms;
          animation-delay: 483ms;
}
.circle-container:nth-child(44) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-44;
          animation-name: move-frames-44;
  -webkit-animation-duration: 29608ms;
          animation-duration: 29608ms;
  -webkit-animation-delay: 32361ms;
          animation-delay: 32361ms;
}
@-webkit-keyframes move-frames-44 {
  from {
    transform: translate3d(59vw, 101vh, 0);
  }
  to {
    transform: translate3d(95vw, -125vh, 0);
  }
}
@keyframes move-frames-44 {
  from {
    transform: translate3d(59vw, 101vh, 0);
  }
  to {
    transform: translate3d(95vw, -125vh, 0);
  }
}
.circle-container:nth-child(44) .circle {
  -webkit-animation-delay: 1020ms;
          animation-delay: 1020ms;
}
.circle-container:nth-child(45) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-45;
          animation-name: move-frames-45;
  -webkit-animation-duration: 28887ms;
          animation-duration: 28887ms;
  -webkit-animation-delay: 8766ms;
          animation-delay: 8766ms;
}
@-webkit-keyframes move-frames-45 {
  from {
    transform: translate3d(31vw, 108vh, 0);
  }
  to {
    transform: translate3d(86vw, -126vh, 0);
  }
}
@keyframes move-frames-45 {
  from {
    transform: translate3d(31vw, 108vh, 0);
  }
  to {
    transform: translate3d(86vw, -126vh, 0);
  }
}
.circle-container:nth-child(45) .circle {
  -webkit-animation-delay: 1525ms;
          animation-delay: 1525ms;
}
.circle-container:nth-child(46) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-46;
          animation-name: move-frames-46;
  -webkit-animation-duration: 29227ms;
          animation-duration: 29227ms;
  -webkit-animation-delay: 20075ms;
          animation-delay: 20075ms;
}
@-webkit-keyframes move-frames-46 {
  from {
    transform: translate3d(76vw, 101vh, 0);
  }
  to {
    transform: translate3d(48vw, -115vh, 0);
  }
}
@keyframes move-frames-46 {
  from {
    transform: translate3d(76vw, 101vh, 0);
  }
  to {
    transform: translate3d(48vw, -115vh, 0);
  }
}
.circle-container:nth-child(46) .circle {
  -webkit-animation-delay: 3350ms;
          animation-delay: 3350ms;
}
.circle-container:nth-child(47) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-47;
          animation-name: move-frames-47;
  -webkit-animation-duration: 30024ms;
          animation-duration: 30024ms;
  -webkit-animation-delay: 16852ms;
          animation-delay: 16852ms;
}
@-webkit-keyframes move-frames-47 {
  from {
    transform: translate3d(86vw, 101vh, 0);
  }
  to {
    transform: translate3d(57vw, -121vh, 0);
  }
}
@keyframes move-frames-47 {
  from {
    transform: translate3d(86vw, 101vh, 0);
  }
  to {
    transform: translate3d(57vw, -121vh, 0);
  }
}
.circle-container:nth-child(47) .circle {
  -webkit-animation-delay: 2018ms;
          animation-delay: 2018ms;
}
.circle-container:nth-child(48) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-48;
          animation-name: move-frames-48;
  -webkit-animation-duration: 36403ms;
          animation-duration: 36403ms;
  -webkit-animation-delay: 5315ms;
          animation-delay: 5315ms;
}
@-webkit-keyframes move-frames-48 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -111vh, 0);
  }
}
@keyframes move-frames-48 {
  from {
    transform: translate3d(35vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -111vh, 0);
  }
}
.circle-container:nth-child(48) .circle {
  -webkit-animation-delay: 2978ms;
          animation-delay: 2978ms;
}
.circle-container:nth-child(49) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-49;
          animation-name: move-frames-49;
  -webkit-animation-duration: 35036ms;
          animation-duration: 35036ms;
  -webkit-animation-delay: 32653ms;
          animation-delay: 32653ms;
}
@-webkit-keyframes move-frames-49 {
  from {
    transform: translate3d(84vw, 109vh, 0);
  }
  to {
    transform: translate3d(52vw, -126vh, 0);
  }
}
@keyframes move-frames-49 {
  from {
    transform: translate3d(84vw, 109vh, 0);
  }
  to {
    transform: translate3d(52vw, -126vh, 0);
  }
}
.circle-container:nth-child(49) .circle {
  -webkit-animation-delay: 1739ms;
          animation-delay: 1739ms;
}
.circle-container:nth-child(50) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-50;
          animation-name: move-frames-50;
  -webkit-animation-duration: 31742ms;
          animation-duration: 31742ms;
  -webkit-animation-delay: 23443ms;
          animation-delay: 23443ms;
}
@-webkit-keyframes move-frames-50 {
  from {
    transform: translate3d(69vw, 108vh, 0);
  }
  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}
@keyframes move-frames-50 {
  from {
    transform: translate3d(69vw, 108vh, 0);
  }
  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}
.circle-container:nth-child(50) .circle {
  -webkit-animation-delay: 1425ms;
          animation-delay: 1425ms;
}
.circle-container:nth-child(51) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-51;
          animation-name: move-frames-51;
  -webkit-animation-duration: 35653ms;
          animation-duration: 35653ms;
  -webkit-animation-delay: 19158ms;
          animation-delay: 19158ms;
}
@-webkit-keyframes move-frames-51 {
  from {
    transform: translate3d(68vw, 107vh, 0);
  }
  to {
    transform: translate3d(79vw, -123vh, 0);
  }
}
@keyframes move-frames-51 {
  from {
    transform: translate3d(68vw, 107vh, 0);
  }
  to {
    transform: translate3d(79vw, -123vh, 0);
  }
}
.circle-container:nth-child(51) .circle {
  -webkit-animation-delay: 1749ms;
          animation-delay: 1749ms;
}
.circle-container:nth-child(52) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-52;
          animation-name: move-frames-52;
  -webkit-animation-duration: 36544ms;
          animation-duration: 36544ms;
  -webkit-animation-delay: 13666ms;
          animation-delay: 13666ms;
}
@-webkit-keyframes move-frames-52 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }
  to {
    transform: translate3d(90vw, -132vh, 0);
  }
}
@keyframes move-frames-52 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }
  to {
    transform: translate3d(90vw, -132vh, 0);
  }
}
.circle-container:nth-child(52) .circle {
  -webkit-animation-delay: 2447ms;
          animation-delay: 2447ms;
}
.circle-container:nth-child(53) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-53;
          animation-name: move-frames-53;
  -webkit-animation-duration: 30027ms;
          animation-duration: 30027ms;
  -webkit-animation-delay: 30ms;
          animation-delay: 30ms;
}
@-webkit-keyframes move-frames-53 {
  from {
    transform: translate3d(31vw, 104vh, 0);
  }
  to {
    transform: translate3d(21vw, -107vh, 0);
  }
}
@keyframes move-frames-53 {
  from {
    transform: translate3d(31vw, 104vh, 0);
  }
  to {
    transform: translate3d(21vw, -107vh, 0);
  }
}
.circle-container:nth-child(53) .circle {
  -webkit-animation-delay: 1030ms;
          animation-delay: 1030ms;
}
.circle-container:nth-child(54) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-54;
          animation-name: move-frames-54;
  -webkit-animation-duration: 33428ms;
          animation-duration: 33428ms;
  -webkit-animation-delay: 23195ms;
          animation-delay: 23195ms;
}
@-webkit-keyframes move-frames-54 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(19vw, -113vh, 0);
  }
}
@keyframes move-frames-54 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(19vw, -113vh, 0);
  }
}
.circle-container:nth-child(54) .circle {
  -webkit-animation-delay: 1903ms;
          animation-delay: 1903ms;
}
.circle-container:nth-child(55) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-55;
          animation-name: move-frames-55;
  -webkit-animation-duration: 33454ms;
          animation-duration: 33454ms;
  -webkit-animation-delay: 34820ms;
          animation-delay: 34820ms;
}
@-webkit-keyframes move-frames-55 {
  from {
    transform: translate3d(66vw, 108vh, 0);
  }
  to {
    transform: translate3d(98vw, -125vh, 0);
  }
}
@keyframes move-frames-55 {
  from {
    transform: translate3d(66vw, 108vh, 0);
  }
  to {
    transform: translate3d(98vw, -125vh, 0);
  }
}
.circle-container:nth-child(55) .circle {
  -webkit-animation-delay: 3080ms;
          animation-delay: 3080ms;
}
.circle-container:nth-child(56) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-56;
          animation-name: move-frames-56;
  -webkit-animation-duration: 28018ms;
          animation-duration: 28018ms;
  -webkit-animation-delay: 6144ms;
          animation-delay: 6144ms;
}
@-webkit-keyframes move-frames-56 {
  from {
    transform: translate3d(33vw, 101vh, 0);
  }
  to {
    transform: translate3d(96vw, -114vh, 0);
  }
}
@keyframes move-frames-56 {
  from {
    transform: translate3d(33vw, 101vh, 0);
  }
  to {
    transform: translate3d(96vw, -114vh, 0);
  }
}
.circle-container:nth-child(56) .circle {
  -webkit-animation-delay: 1469ms;
          animation-delay: 1469ms;
}
.circle-container:nth-child(57) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-57;
          animation-name: move-frames-57;
  -webkit-animation-duration: 34223ms;
          animation-duration: 34223ms;
  -webkit-animation-delay: 2169ms;
          animation-delay: 2169ms;
}
@-webkit-keyframes move-frames-57 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }
  to {
    transform: translate3d(25vw, -123vh, 0);
  }
}
@keyframes move-frames-57 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }
  to {
    transform: translate3d(25vw, -123vh, 0);
  }
}
.circle-container:nth-child(57) .circle {
  -webkit-animation-delay: 116ms;
          animation-delay: 116ms;
}
.circle-container:nth-child(58) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-58;
          animation-name: move-frames-58;
  -webkit-animation-duration: 29732ms;
          animation-duration: 29732ms;
  -webkit-animation-delay: 10504ms;
          animation-delay: 10504ms;
}
@-webkit-keyframes move-frames-58 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(56vw, -127vh, 0);
  }
}
@keyframes move-frames-58 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(56vw, -127vh, 0);
  }
}
.circle-container:nth-child(58) .circle {
  -webkit-animation-delay: 1863ms;
          animation-delay: 1863ms;
}
.circle-container:nth-child(59) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-59;
          animation-name: move-frames-59;
  -webkit-animation-duration: 33590ms;
          animation-duration: 33590ms;
  -webkit-animation-delay: 21970ms;
          animation-delay: 21970ms;
}
@-webkit-keyframes move-frames-59 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }
  to {
    transform: translate3d(52vw, -109vh, 0);
  }
}
@keyframes move-frames-59 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }
  to {
    transform: translate3d(52vw, -109vh, 0);
  }
}
.circle-container:nth-child(59) .circle {
  -webkit-animation-delay: 401ms;
          animation-delay: 401ms;
}
.circle-container:nth-child(60) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-60;
          animation-name: move-frames-60;
  -webkit-animation-duration: 35829ms;
          animation-duration: 35829ms;
  -webkit-animation-delay: 6034ms;
          animation-delay: 6034ms;
}
@-webkit-keyframes move-frames-60 {
  from {
    transform: translate3d(90vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -110vh, 0);
  }
}
@keyframes move-frames-60 {
  from {
    transform: translate3d(90vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -110vh, 0);
  }
}
.circle-container:nth-child(60) .circle {
  -webkit-animation-delay: 1928ms;
          animation-delay: 1928ms;
}
.circle-container:nth-child(61) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-61;
          animation-name: move-frames-61;
  -webkit-animation-duration: 31259ms;
          animation-duration: 31259ms;
  -webkit-animation-delay: 3250ms;
          animation-delay: 3250ms;
}
@-webkit-keyframes move-frames-61 {
  from {
    transform: translate3d(55vw, 101vh, 0);
  }
  to {
    transform: translate3d(54vw, -118vh, 0);
  }
}
@keyframes move-frames-61 {
  from {
    transform: translate3d(55vw, 101vh, 0);
  }
  to {
    transform: translate3d(54vw, -118vh, 0);
  }
}
.circle-container:nth-child(61) .circle {
  -webkit-animation-delay: 885ms;
          animation-delay: 885ms;
}
.circle-container:nth-child(62) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-62;
          animation-name: move-frames-62;
  -webkit-animation-duration: 35053ms;
          animation-duration: 35053ms;
  -webkit-animation-delay: 28707ms;
          animation-delay: 28707ms;
}
@-webkit-keyframes move-frames-62 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(48vw, -132vh, 0);
  }
}
@keyframes move-frames-62 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(48vw, -132vh, 0);
  }
}
.circle-container:nth-child(62) .circle {
  -webkit-animation-delay: 979ms;
          animation-delay: 979ms;
}
.circle-container:nth-child(63) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-63;
          animation-name: move-frames-63;
  -webkit-animation-duration: 28256ms;
          animation-duration: 28256ms;
  -webkit-animation-delay: 10373ms;
          animation-delay: 10373ms;
}
@-webkit-keyframes move-frames-63 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(50vw, -132vh, 0);
  }
}
@keyframes move-frames-63 {
  from {
    transform: translate3d(41vw, 109vh, 0);
  }
  to {
    transform: translate3d(50vw, -132vh, 0);
  }
}
.circle-container:nth-child(63) .circle {
  -webkit-animation-delay: 2312ms;
          animation-delay: 2312ms;
}
.circle-container:nth-child(64) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-64;
          animation-name: move-frames-64;
  -webkit-animation-duration: 31529ms;
          animation-duration: 31529ms;
  -webkit-animation-delay: 20486ms;
          animation-delay: 20486ms;
}
@-webkit-keyframes move-frames-64 {
  from {
    transform: translate3d(71vw, 101vh, 0);
  }
  to {
    transform: translate3d(22vw, -104vh, 0);
  }
}
@keyframes move-frames-64 {
  from {
    transform: translate3d(71vw, 101vh, 0);
  }
  to {
    transform: translate3d(22vw, -104vh, 0);
  }
}
.circle-container:nth-child(64) .circle {
  -webkit-animation-delay: 2765ms;
          animation-delay: 2765ms;
}
.circle-container:nth-child(65) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-65;
          animation-name: move-frames-65;
  -webkit-animation-duration: 29896ms;
          animation-duration: 29896ms;
  -webkit-animation-delay: 36773ms;
          animation-delay: 36773ms;
}
@-webkit-keyframes move-frames-65 {
  from {
    transform: translate3d(6vw, 101vh, 0);
  }
  to {
    transform: translate3d(29vw, -106vh, 0);
  }
}
@keyframes move-frames-65 {
  from {
    transform: translate3d(6vw, 101vh, 0);
  }
  to {
    transform: translate3d(29vw, -106vh, 0);
  }
}
.circle-container:nth-child(65) .circle {
  -webkit-animation-delay: 752ms;
          animation-delay: 752ms;
}
.circle-container:nth-child(66) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-66;
          animation-name: move-frames-66;
  -webkit-animation-duration: 35574ms;
          animation-duration: 35574ms;
  -webkit-animation-delay: 12011ms;
          animation-delay: 12011ms;
}
@-webkit-keyframes move-frames-66 {
  from {
    transform: translate3d(22vw, 108vh, 0);
  }
  to {
    transform: translate3d(51vw, -112vh, 0);
  }
}
@keyframes move-frames-66 {
  from {
    transform: translate3d(22vw, 108vh, 0);
  }
  to {
    transform: translate3d(51vw, -112vh, 0);
  }
}
.circle-container:nth-child(66) .circle {
  -webkit-animation-delay: 2047ms;
          animation-delay: 2047ms;
}
.circle-container:nth-child(67) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-67;
          animation-name: move-frames-67;
  -webkit-animation-duration: 30224ms;
          animation-duration: 30224ms;
  -webkit-animation-delay: 22191ms;
          animation-delay: 22191ms;
}
@-webkit-keyframes move-frames-67 {
  from {
    transform: translate3d(84vw, 101vh, 0);
  }
  to {
    transform: translate3d(97vw, -109vh, 0);
  }
}
@keyframes move-frames-67 {
  from {
    transform: translate3d(84vw, 101vh, 0);
  }
  to {
    transform: translate3d(97vw, -109vh, 0);
  }
}
.circle-container:nth-child(67) .circle {
  -webkit-animation-delay: 3057ms;
          animation-delay: 3057ms;
}
.circle-container:nth-child(68) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-68;
          animation-name: move-frames-68;
  -webkit-animation-duration: 33792ms;
          animation-duration: 33792ms;
  -webkit-animation-delay: 35526ms;
          animation-delay: 35526ms;
}
@-webkit-keyframes move-frames-68 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(78vw, -128vh, 0);
  }
}
@keyframes move-frames-68 {
  from {
    transform: translate3d(9vw, 103vh, 0);
  }
  to {
    transform: translate3d(78vw, -128vh, 0);
  }
}
.circle-container:nth-child(68) .circle {
  -webkit-animation-delay: 2676ms;
          animation-delay: 2676ms;
}
.circle-container:nth-child(69) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-69;
          animation-name: move-frames-69;
  -webkit-animation-duration: 30898ms;
          animation-duration: 30898ms;
  -webkit-animation-delay: 24406ms;
          animation-delay: 24406ms;
}
@-webkit-keyframes move-frames-69 {
  from {
    transform: translate3d(66vw, 106vh, 0);
  }
  to {
    transform: translate3d(14vw, -123vh, 0);
  }
}
@keyframes move-frames-69 {
  from {
    transform: translate3d(66vw, 106vh, 0);
  }
  to {
    transform: translate3d(14vw, -123vh, 0);
  }
}
.circle-container:nth-child(69) .circle {
  -webkit-animation-delay: 248ms;
          animation-delay: 248ms;
}
.circle-container:nth-child(70) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-70;
          animation-name: move-frames-70;
  -webkit-animation-duration: 30216ms;
          animation-duration: 30216ms;
  -webkit-animation-delay: 32650ms;
          animation-delay: 32650ms;
}
@-webkit-keyframes move-frames-70 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(37vw, -118vh, 0);
  }
}
@keyframes move-frames-70 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(37vw, -118vh, 0);
  }
}
.circle-container:nth-child(70) .circle {
  -webkit-animation-delay: 2935ms;
          animation-delay: 2935ms;
}
.circle-container:nth-child(71) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-71;
          animation-name: move-frames-71;
  -webkit-animation-duration: 32580ms;
          animation-duration: 32580ms;
  -webkit-animation-delay: 17279ms;
          animation-delay: 17279ms;
}
@-webkit-keyframes move-frames-71 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
@keyframes move-frames-71 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
.circle-container:nth-child(71) .circle {
  -webkit-animation-delay: 3771ms;
          animation-delay: 3771ms;
}
.circle-container:nth-child(72) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-72;
          animation-name: move-frames-72;
  -webkit-animation-duration: 36575ms;
          animation-duration: 36575ms;
  -webkit-animation-delay: 13328ms;
          animation-delay: 13328ms;
}
@-webkit-keyframes move-frames-72 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(69vw, -129vh, 0);
  }
}
@keyframes move-frames-72 {
  from {
    transform: translate3d(13vw, 107vh, 0);
  }
  to {
    transform: translate3d(69vw, -129vh, 0);
  }
}
.circle-container:nth-child(72) .circle {
  -webkit-animation-delay: 776ms;
          animation-delay: 776ms;
}
.circle-container:nth-child(73) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-73;
          animation-name: move-frames-73;
  -webkit-animation-duration: 33906ms;
          animation-duration: 33906ms;
  -webkit-animation-delay: 265ms;
          animation-delay: 265ms;
}
@-webkit-keyframes move-frames-73 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(25vw, -119vh, 0);
  }
}
@keyframes move-frames-73 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(25vw, -119vh, 0);
  }
}
.circle-container:nth-child(73) .circle {
  -webkit-animation-delay: 2986ms;
          animation-delay: 2986ms;
}
.circle-container:nth-child(74) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-74;
          animation-name: move-frames-74;
  -webkit-animation-duration: 30787ms;
          animation-duration: 30787ms;
  -webkit-animation-delay: 23365ms;
          animation-delay: 23365ms;
}
@-webkit-keyframes move-frames-74 {
  from {
    transform: translate3d(29vw, 105vh, 0);
  }
  to {
    transform: translate3d(18vw, -124vh, 0);
  }
}
@keyframes move-frames-74 {
  from {
    transform: translate3d(29vw, 105vh, 0);
  }
  to {
    transform: translate3d(18vw, -124vh, 0);
  }
}
.circle-container:nth-child(74) .circle {
  -webkit-animation-delay: 3542ms;
          animation-delay: 3542ms;
}
.circle-container:nth-child(75) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-75;
          animation-name: move-frames-75;
  -webkit-animation-duration: 33173ms;
          animation-duration: 33173ms;
  -webkit-animation-delay: 32188ms;
          animation-delay: 32188ms;
}
@-webkit-keyframes move-frames-75 {
  from {
    transform: translate3d(65vw, 109vh, 0);
  }
  to {
    transform: translate3d(5vw, -117vh, 0);
  }
}
@keyframes move-frames-75 {
  from {
    transform: translate3d(65vw, 109vh, 0);
  }
  to {
    transform: translate3d(5vw, -117vh, 0);
  }
}
.circle-container:nth-child(75) .circle {
  -webkit-animation-delay: 3578ms;
          animation-delay: 3578ms;
}
.circle-container:nth-child(76) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-76;
          animation-name: move-frames-76;
  -webkit-animation-duration: 31296ms;
          animation-duration: 31296ms;
  -webkit-animation-delay: 15074ms;
          animation-delay: 15074ms;
}
@-webkit-keyframes move-frames-76 {
  from {
    transform: translate3d(67vw, 101vh, 0);
  }
  to {
    transform: translate3d(84vw, -109vh, 0);
  }
}
@keyframes move-frames-76 {
  from {
    transform: translate3d(67vw, 101vh, 0);
  }
  to {
    transform: translate3d(84vw, -109vh, 0);
  }
}
.circle-container:nth-child(76) .circle {
  -webkit-animation-delay: 3626ms;
          animation-delay: 3626ms;
}
.circle-container:nth-child(77) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-77;
          animation-name: move-frames-77;
  -webkit-animation-duration: 35352ms;
          animation-duration: 35352ms;
  -webkit-animation-delay: 32643ms;
          animation-delay: 32643ms;
}
@-webkit-keyframes move-frames-77 {
  from {
    transform: translate3d(20vw, 101vh, 0);
  }
  to {
    transform: translate3d(2vw, -106vh, 0);
  }
}
@keyframes move-frames-77 {
  from {
    transform: translate3d(20vw, 101vh, 0);
  }
  to {
    transform: translate3d(2vw, -106vh, 0);
  }
}
.circle-container:nth-child(77) .circle {
  -webkit-animation-delay: 3160ms;
          animation-delay: 3160ms;
}
.circle-container:nth-child(78) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-78;
          animation-name: move-frames-78;
  -webkit-animation-duration: 33470ms;
          animation-duration: 33470ms;
  -webkit-animation-delay: 5480ms;
          animation-delay: 5480ms;
}
@-webkit-keyframes move-frames-78 {
  from {
    transform: translate3d(77vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -110vh, 0);
  }
}
@keyframes move-frames-78 {
  from {
    transform: translate3d(77vw, 105vh, 0);
  }
  to {
    transform: translate3d(50vw, -110vh, 0);
  }
}
.circle-container:nth-child(78) .circle {
  -webkit-animation-delay: 460ms;
          animation-delay: 460ms;
}
.circle-container:nth-child(79) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-79;
          animation-name: move-frames-79;
  -webkit-animation-duration: 36776ms;
          animation-duration: 36776ms;
  -webkit-animation-delay: 33301ms;
          animation-delay: 33301ms;
}
@-webkit-keyframes move-frames-79 {
  from {
    transform: translate3d(25vw, 105vh, 0);
  }
  to {
    transform: translate3d(72vw, -112vh, 0);
  }
}
@keyframes move-frames-79 {
  from {
    transform: translate3d(25vw, 105vh, 0);
  }
  to {
    transform: translate3d(72vw, -112vh, 0);
  }
}
.circle-container:nth-child(79) .circle {
  -webkit-animation-delay: 413ms;
          animation-delay: 413ms;
}
.circle-container:nth-child(80) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-80;
          animation-name: move-frames-80;
  -webkit-animation-duration: 33101ms;
          animation-duration: 33101ms;
  -webkit-animation-delay: 16510ms;
          animation-delay: 16510ms;
}
@-webkit-keyframes move-frames-80 {
  from {
    transform: translate3d(5vw, 109vh, 0);
  }
  to {
    transform: translate3d(14vw, -127vh, 0);
  }
}
@keyframes move-frames-80 {
  from {
    transform: translate3d(5vw, 109vh, 0);
  }
  to {
    transform: translate3d(14vw, -127vh, 0);
  }
}
.circle-container:nth-child(80) .circle {
  -webkit-animation-delay: 793ms;
          animation-delay: 793ms;
}
.circle-container:nth-child(81) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-81;
          animation-name: move-frames-81;
  -webkit-animation-duration: 28644ms;
          animation-duration: 28644ms;
  -webkit-animation-delay: 8615ms;
          animation-delay: 8615ms;
}
@-webkit-keyframes move-frames-81 {
  from {
    transform: translate3d(43vw, 104vh, 0);
  }
  to {
    transform: translate3d(4vw, -115vh, 0);
  }
}
@keyframes move-frames-81 {
  from {
    transform: translate3d(43vw, 104vh, 0);
  }
  to {
    transform: translate3d(4vw, -115vh, 0);
  }
}
.circle-container:nth-child(81) .circle {
  -webkit-animation-delay: 1533ms;
          animation-delay: 1533ms;
}
.circle-container:nth-child(82) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-82;
          animation-name: move-frames-82;
  -webkit-animation-duration: 29725ms;
          animation-duration: 29725ms;
  -webkit-animation-delay: 7835ms;
          animation-delay: 7835ms;
}
@-webkit-keyframes move-frames-82 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }
  to {
    transform: translate3d(68vw, -133vh, 0);
  }
}
@keyframes move-frames-82 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }
  to {
    transform: translate3d(68vw, -133vh, 0);
  }
}
.circle-container:nth-child(82) .circle {
  -webkit-animation-delay: 3452ms;
          animation-delay: 3452ms;
}
.circle-container:nth-child(83) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-83;
          animation-name: move-frames-83;
  -webkit-animation-duration: 36861ms;
          animation-duration: 36861ms;
  -webkit-animation-delay: 29593ms;
          animation-delay: 29593ms;
}
@-webkit-keyframes move-frames-83 {
  from {
    transform: translate3d(17vw, 110vh, 0);
  }
  to {
    transform: translate3d(27vw, -112vh, 0);
  }
}
@keyframes move-frames-83 {
  from {
    transform: translate3d(17vw, 110vh, 0);
  }
  to {
    transform: translate3d(27vw, -112vh, 0);
  }
}
.circle-container:nth-child(83) .circle {
  -webkit-animation-delay: 1445ms;
          animation-delay: 1445ms;
}
.circle-container:nth-child(84) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-84;
          animation-name: move-frames-84;
  -webkit-animation-duration: 36394ms;
          animation-duration: 36394ms;
  -webkit-animation-delay: 20654ms;
          animation-delay: 20654ms;
}
@-webkit-keyframes move-frames-84 {
  from {
    transform: translate3d(12vw, 104vh, 0);
  }
  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}
@keyframes move-frames-84 {
  from {
    transform: translate3d(12vw, 104vh, 0);
  }
  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}
.circle-container:nth-child(84) .circle {
  -webkit-animation-delay: 1523ms;
          animation-delay: 1523ms;
}
.circle-container:nth-child(85) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-85;
          animation-name: move-frames-85;
  -webkit-animation-duration: 36739ms;
          animation-duration: 36739ms;
  -webkit-animation-delay: 7716ms;
          animation-delay: 7716ms;
}
@-webkit-keyframes move-frames-85 {
  from {
    transform: translate3d(23vw, 106vh, 0);
  }
  to {
    transform: translate3d(3vw, -109vh, 0);
  }
}
@keyframes move-frames-85 {
  from {
    transform: translate3d(23vw, 106vh, 0);
  }
  to {
    transform: translate3d(3vw, -109vh, 0);
  }
}
.circle-container:nth-child(85) .circle {
  -webkit-animation-delay: 1038ms;
          animation-delay: 1038ms;
}
.circle-container:nth-child(86) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-86;
          animation-name: move-frames-86;
  -webkit-animation-duration: 30476ms;
          animation-duration: 30476ms;
  -webkit-animation-delay: 1242ms;
          animation-delay: 1242ms;
}
@-webkit-keyframes move-frames-86 {
  from {
    transform: translate3d(17vw, 105vh, 0);
  }
  to {
    transform: translate3d(74vw, -130vh, 0);
  }
}
@keyframes move-frames-86 {
  from {
    transform: translate3d(17vw, 105vh, 0);
  }
  to {
    transform: translate3d(74vw, -130vh, 0);
  }
}
.circle-container:nth-child(86) .circle {
  -webkit-animation-delay: 157ms;
          animation-delay: 157ms;
}
.circle-container:nth-child(87) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-87;
          animation-name: move-frames-87;
  -webkit-animation-duration: 29100ms;
          animation-duration: 29100ms;
  -webkit-animation-delay: 16859ms;
          animation-delay: 16859ms;
}
@-webkit-keyframes move-frames-87 {
  from {
    transform: translate3d(12vw, 109vh, 0);
  }
  to {
    transform: translate3d(43vw, -123vh, 0);
  }
}
@keyframes move-frames-87 {
  from {
    transform: translate3d(12vw, 109vh, 0);
  }
  to {
    transform: translate3d(43vw, -123vh, 0);
  }
}
.circle-container:nth-child(87) .circle {
  -webkit-animation-delay: 1676ms;
          animation-delay: 1676ms;
}
.circle-container:nth-child(88) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-88;
          animation-name: move-frames-88;
  -webkit-animation-duration: 30746ms;
          animation-duration: 30746ms;
  -webkit-animation-delay: 26439ms;
          animation-delay: 26439ms;
}
@-webkit-keyframes move-frames-88 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }
  to {
    transform: translate3d(81vw, -107vh, 0);
  }
}
@keyframes move-frames-88 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }
  to {
    transform: translate3d(81vw, -107vh, 0);
  }
}
.circle-container:nth-child(88) .circle {
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
}
.circle-container:nth-child(89) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-89;
          animation-name: move-frames-89;
  -webkit-animation-duration: 35918ms;
          animation-duration: 35918ms;
  -webkit-animation-delay: 35924ms;
          animation-delay: 35924ms;
}
@-webkit-keyframes move-frames-89 {
  from {
    transform: translate3d(13vw, 105vh, 0);
  }
  to {
    transform: translate3d(81vw, -125vh, 0);
  }
}
@keyframes move-frames-89 {
  from {
    transform: translate3d(13vw, 105vh, 0);
  }
  to {
    transform: translate3d(81vw, -125vh, 0);
  }
}
.circle-container:nth-child(89) .circle {
  -webkit-animation-delay: 2579ms;
          animation-delay: 2579ms;
}
.circle-container:nth-child(90) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-90;
          animation-name: move-frames-90;
  -webkit-animation-duration: 34653ms;
          animation-duration: 34653ms;
  -webkit-animation-delay: 26813ms;
          animation-delay: 26813ms;
}
@-webkit-keyframes move-frames-90 {
  from {
    transform: translate3d(51vw, 109vh, 0);
  }
  to {
    transform: translate3d(83vw, -134vh, 0);
  }
}
@keyframes move-frames-90 {
  from {
    transform: translate3d(51vw, 109vh, 0);
  }
  to {
    transform: translate3d(83vw, -134vh, 0);
  }
}
.circle-container:nth-child(90) .circle {
  -webkit-animation-delay: 3117ms;
          animation-delay: 3117ms;
}
.circle-container:nth-child(91) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-91;
          animation-name: move-frames-91;
  -webkit-animation-duration: 34629ms;
          animation-duration: 34629ms;
  -webkit-animation-delay: 29525ms;
          animation-delay: 29525ms;
}
@-webkit-keyframes move-frames-91 {
  from {
    transform: translate3d(90vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -118vh, 0);
  }
}
@keyframes move-frames-91 {
  from {
    transform: translate3d(90vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -118vh, 0);
  }
}
.circle-container:nth-child(91) .circle {
  -webkit-animation-delay: 2183ms;
          animation-delay: 2183ms;
}
.circle-container:nth-child(92) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-92;
          animation-name: move-frames-92;
  -webkit-animation-duration: 33327ms;
          animation-duration: 33327ms;
  -webkit-animation-delay: 1278ms;
          animation-delay: 1278ms;
}
@-webkit-keyframes move-frames-92 {
  from {
    transform: translate3d(68vw, 102vh, 0);
  }
  to {
    transform: translate3d(45vw, -110vh, 0);
  }
}
@keyframes move-frames-92 {
  from {
    transform: translate3d(68vw, 102vh, 0);
  }
  to {
    transform: translate3d(45vw, -110vh, 0);
  }
}
.circle-container:nth-child(92) .circle {
  -webkit-animation-delay: 3611ms;
          animation-delay: 3611ms;
}
.circle-container:nth-child(93) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-93;
          animation-name: move-frames-93;
  -webkit-animation-duration: 30830ms;
          animation-duration: 30830ms;
  -webkit-animation-delay: 18847ms;
          animation-delay: 18847ms;
}
@-webkit-keyframes move-frames-93 {
  from {
    transform: translate3d(62vw, 107vh, 0);
  }
  to {
    transform: translate3d(11vw, -132vh, 0);
  }
}
@keyframes move-frames-93 {
  from {
    transform: translate3d(62vw, 107vh, 0);
  }
  to {
    transform: translate3d(11vw, -132vh, 0);
  }
}
.circle-container:nth-child(93) .circle {
  -webkit-animation-delay: 2319ms;
          animation-delay: 2319ms;
}
.circle-container:nth-child(94) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-94;
          animation-name: move-frames-94;
  -webkit-animation-duration: 30039ms;
          animation-duration: 30039ms;
  -webkit-animation-delay: 32456ms;
          animation-delay: 32456ms;
}
@-webkit-keyframes move-frames-94 {
  from {
    transform: translate3d(28vw, 104vh, 0);
  }
  to {
    transform: translate3d(71vw, -117vh, 0);
  }
}
@keyframes move-frames-94 {
  from {
    transform: translate3d(28vw, 104vh, 0);
  }
  to {
    transform: translate3d(71vw, -117vh, 0);
  }
}
.circle-container:nth-child(94) .circle {
  -webkit-animation-delay: 2099ms;
          animation-delay: 2099ms;
}
.circle-container:nth-child(95) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-95;
          animation-name: move-frames-95;
  -webkit-animation-duration: 35042ms;
          animation-duration: 35042ms;
  -webkit-animation-delay: 14683ms;
          animation-delay: 14683ms;
}
@-webkit-keyframes move-frames-95 {
  from {
    transform: translate3d(14vw, 107vh, 0);
  }
  to {
    transform: translate3d(89vw, -119vh, 0);
  }
}
@keyframes move-frames-95 {
  from {
    transform: translate3d(14vw, 107vh, 0);
  }
  to {
    transform: translate3d(89vw, -119vh, 0);
  }
}
.circle-container:nth-child(95) .circle {
  -webkit-animation-delay: 3167ms;
          animation-delay: 3167ms;
}
.circle-container:nth-child(96) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-96;
          animation-name: move-frames-96;
  -webkit-animation-duration: 30596ms;
          animation-duration: 30596ms;
  -webkit-animation-delay: 25606ms;
          animation-delay: 25606ms;
}
@-webkit-keyframes move-frames-96 {
  from {
    transform: translate3d(79vw, 107vh, 0);
  }
  to {
    transform: translate3d(16vw, -123vh, 0);
  }
}
@keyframes move-frames-96 {
  from {
    transform: translate3d(79vw, 107vh, 0);
  }
  to {
    transform: translate3d(16vw, -123vh, 0);
  }
}
.circle-container:nth-child(96) .circle {
  -webkit-animation-delay: 3171ms;
          animation-delay: 3171ms;
}
.circle-container:nth-child(97) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-97;
          animation-name: move-frames-97;
  -webkit-animation-duration: 35889ms;
          animation-duration: 35889ms;
  -webkit-animation-delay: 9788ms;
          animation-delay: 9788ms;
}
@-webkit-keyframes move-frames-97 {
  from {
    transform: translate3d(56vw, 106vh, 0);
  }
  to {
    transform: translate3d(84vw, -126vh, 0);
  }
}
@keyframes move-frames-97 {
  from {
    transform: translate3d(56vw, 106vh, 0);
  }
  to {
    transform: translate3d(84vw, -126vh, 0);
  }
}
.circle-container:nth-child(97) .circle {
  -webkit-animation-delay: 934ms;
          animation-delay: 934ms;
}
.circle-container:nth-child(98) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-98;
          animation-name: move-frames-98;
  -webkit-animation-duration: 35983ms;
          animation-duration: 35983ms;
  -webkit-animation-delay: 5664ms;
          animation-delay: 5664ms;
}
@-webkit-keyframes move-frames-98 {
  from {
    transform: translate3d(98vw, 105vh, 0);
  }
  to {
    transform: translate3d(64vw, -115vh, 0);
  }
}
@keyframes move-frames-98 {
  from {
    transform: translate3d(98vw, 105vh, 0);
  }
  to {
    transform: translate3d(64vw, -115vh, 0);
  }
}
.circle-container:nth-child(98) .circle {
  -webkit-animation-delay: 3069ms;
          animation-delay: 3069ms;
}
.circle-container:nth-child(99) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-99;
          animation-name: move-frames-99;
  -webkit-animation-duration: 35627ms;
          animation-duration: 35627ms;
  -webkit-animation-delay: 19496ms;
          animation-delay: 19496ms;
}
@-webkit-keyframes move-frames-99 {
  from {
    transform: translate3d(96vw, 103vh, 0);
  }
  to {
    transform: translate3d(13vw, -113vh, 0);
  }
}
@keyframes move-frames-99 {
  from {
    transform: translate3d(96vw, 103vh, 0);
  }
  to {
    transform: translate3d(13vw, -113vh, 0);
  }
}
.circle-container:nth-child(99) .circle {
  -webkit-animation-delay: 262ms;
          animation-delay: 262ms;
}
.circle-container:nth-child(100) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-100;
          animation-name: move-frames-100;
  -webkit-animation-duration: 31298ms;
          animation-duration: 31298ms;
  -webkit-animation-delay: 9191ms;
          animation-delay: 9191ms;
}
@-webkit-keyframes move-frames-100 {
  from {
    transform: translate3d(94vw, 109vh, 0);
  }
  to {
    transform: translate3d(32vw, -112vh, 0);
  }
}
@keyframes move-frames-100 {
  from {
    transform: translate3d(94vw, 109vh, 0);
  }
  to {
    transform: translate3d(32vw, -112vh, 0);
  }
}
.circle-container:nth-child(100) .circle {
  -webkit-animation-delay: 457ms;
          animation-delay: 457ms;
}
.circle-container:nth-child(101) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-101;
          animation-name: move-frames-101;
  -webkit-animation-duration: 32806ms;
          animation-duration: 32806ms;
  -webkit-animation-delay: 36642ms;
          animation-delay: 36642ms;
}
@-webkit-keyframes move-frames-101 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(86vw, -109vh, 0);
  }
}
@keyframes move-frames-101 {
  from {
    transform: translate3d(76vw, 106vh, 0);
  }
  to {
    transform: translate3d(86vw, -109vh, 0);
  }
}
.circle-container:nth-child(101) .circle {
  -webkit-animation-delay: 3620ms;
          animation-delay: 3620ms;
}
.circle-container:nth-child(102) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-102;
          animation-name: move-frames-102;
  -webkit-animation-duration: 34794ms;
          animation-duration: 34794ms;
  -webkit-animation-delay: 4790ms;
          animation-delay: 4790ms;
}
@-webkit-keyframes move-frames-102 {
  from {
    transform: translate3d(82vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -108vh, 0);
  }
}
@keyframes move-frames-102 {
  from {
    transform: translate3d(82vw, 103vh, 0);
  }
  to {
    transform: translate3d(62vw, -108vh, 0);
  }
}
.circle-container:nth-child(102) .circle {
  -webkit-animation-delay: 3558ms;
          animation-delay: 3558ms;
}
.circle-container:nth-child(103) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-103;
          animation-name: move-frames-103;
  -webkit-animation-duration: 32273ms;
          animation-duration: 32273ms;
  -webkit-animation-delay: 36344ms;
          animation-delay: 36344ms;
}
@-webkit-keyframes move-frames-103 {
  from {
    transform: translate3d(49vw, 103vh, 0);
  }
  to {
    transform: translate3d(90vw, -110vh, 0);
  }
}
@keyframes move-frames-103 {
  from {
    transform: translate3d(49vw, 103vh, 0);
  }
  to {
    transform: translate3d(90vw, -110vh, 0);
  }
}
.circle-container:nth-child(103) .circle {
  -webkit-animation-delay: 78ms;
          animation-delay: 78ms;
}
.circle-container:nth-child(104) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-104;
          animation-name: move-frames-104;
  -webkit-animation-duration: 36727ms;
          animation-duration: 36727ms;
  -webkit-animation-delay: 32419ms;
          animation-delay: 32419ms;
}
@-webkit-keyframes move-frames-104 {
  from {
    transform: translate3d(21vw, 102vh, 0);
  }
  to {
    transform: translate3d(58vw, -120vh, 0);
  }
}
@keyframes move-frames-104 {
  from {
    transform: translate3d(21vw, 102vh, 0);
  }
  to {
    transform: translate3d(58vw, -120vh, 0);
  }
}
.circle-container:nth-child(104) .circle {
  -webkit-animation-delay: 191ms;
          animation-delay: 191ms;
}
.circle-container:nth-child(105) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-105;
          animation-name: move-frames-105;
  -webkit-animation-duration: 35021ms;
          animation-duration: 35021ms;
  -webkit-animation-delay: 22566ms;
          animation-delay: 22566ms;
}
@-webkit-keyframes move-frames-105 {
  from {
    transform: translate3d(48vw, 104vh, 0);
  }
  to {
    transform: translate3d(48vw, -109vh, 0);
  }
}
@keyframes move-frames-105 {
  from {
    transform: translate3d(48vw, 104vh, 0);
  }
  to {
    transform: translate3d(48vw, -109vh, 0);
  }
}
.circle-container:nth-child(105) .circle {
  -webkit-animation-delay: 3044ms;
          animation-delay: 3044ms;
}
.circle-container:nth-child(106) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-106;
          animation-name: move-frames-106;
  -webkit-animation-duration: 34807ms;
          animation-duration: 34807ms;
  -webkit-animation-delay: 24797ms;
          animation-delay: 24797ms;
}
@-webkit-keyframes move-frames-106 {
  from {
    transform: translate3d(59vw, 108vh, 0);
  }
  to {
    transform: translate3d(79vw, -124vh, 0);
  }
}
@keyframes move-frames-106 {
  from {
    transform: translate3d(59vw, 108vh, 0);
  }
  to {
    transform: translate3d(79vw, -124vh, 0);
  }
}
.circle-container:nth-child(106) .circle {
  -webkit-animation-delay: 226ms;
          animation-delay: 226ms;
}
.circle-container:nth-child(107) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-107;
          animation-name: move-frames-107;
  -webkit-animation-duration: 32052ms;
          animation-duration: 32052ms;
  -webkit-animation-delay: 7227ms;
          animation-delay: 7227ms;
}
@-webkit-keyframes move-frames-107 {
  from {
    transform: translate3d(56vw, 102vh, 0);
  }
  to {
    transform: translate3d(1vw, -116vh, 0);
  }
}
@keyframes move-frames-107 {
  from {
    transform: translate3d(56vw, 102vh, 0);
  }
  to {
    transform: translate3d(1vw, -116vh, 0);
  }
}
.circle-container:nth-child(107) .circle {
  -webkit-animation-delay: 3768ms;
          animation-delay: 3768ms;
}
.circle-container:nth-child(108) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-108;
          animation-name: move-frames-108;
  -webkit-animation-duration: 32403ms;
          animation-duration: 32403ms;
  -webkit-animation-delay: 36237ms;
          animation-delay: 36237ms;
}
@-webkit-keyframes move-frames-108 {
  from {
    transform: translate3d(21vw, 103vh, 0);
  }
  to {
    transform: translate3d(51vw, -108vh, 0);
  }
}
@keyframes move-frames-108 {
  from {
    transform: translate3d(21vw, 103vh, 0);
  }
  to {
    transform: translate3d(51vw, -108vh, 0);
  }
}
.circle-container:nth-child(108) .circle {
  -webkit-animation-delay: 673ms;
          animation-delay: 673ms;
}
.circle-container:nth-child(109) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-109;
          animation-name: move-frames-109;
  -webkit-animation-duration: 31764ms;
          animation-duration: 31764ms;
  -webkit-animation-delay: 27936ms;
          animation-delay: 27936ms;
}
@-webkit-keyframes move-frames-109 {
  from {
    transform: translate3d(77vw, 108vh, 0);
  }
  to {
    transform: translate3d(19vw, -135vh, 0);
  }
}
@keyframes move-frames-109 {
  from {
    transform: translate3d(77vw, 108vh, 0);
  }
  to {
    transform: translate3d(19vw, -135vh, 0);
  }
}
.circle-container:nth-child(109) .circle {
  -webkit-animation-delay: 3819ms;
          animation-delay: 3819ms;
}
.circle-container:nth-child(110) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-110;
          animation-name: move-frames-110;
  -webkit-animation-duration: 33961ms;
          animation-duration: 33961ms;
  -webkit-animation-delay: 30019ms;
          animation-delay: 30019ms;
}
@-webkit-keyframes move-frames-110 {
  from {
    transform: translate3d(2vw, 104vh, 0);
  }
  to {
    transform: translate3d(2vw, -117vh, 0);
  }
}
@keyframes move-frames-110 {
  from {
    transform: translate3d(2vw, 104vh, 0);
  }
  to {
    transform: translate3d(2vw, -117vh, 0);
  }
}
.circle-container:nth-child(110) .circle {
  -webkit-animation-delay: 3572ms;
          animation-delay: 3572ms;
}
.circle-container:nth-child(111) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-111;
          animation-name: move-frames-111;
  -webkit-animation-duration: 35304ms;
          animation-duration: 35304ms;
  -webkit-animation-delay: 24053ms;
          animation-delay: 24053ms;
}
@-webkit-keyframes move-frames-111 {
  from {
    transform: translate3d(9vw, 105vh, 0);
  }
  to {
    transform: translate3d(40vw, -118vh, 0);
  }
}
@keyframes move-frames-111 {
  from {
    transform: translate3d(9vw, 105vh, 0);
  }
  to {
    transform: translate3d(40vw, -118vh, 0);
  }
}
.circle-container:nth-child(111) .circle {
  -webkit-animation-delay: 2914ms;
          animation-delay: 2914ms;
}
.circle-container:nth-child(112) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-112;
          animation-name: move-frames-112;
  -webkit-animation-duration: 33778ms;
          animation-duration: 33778ms;
  -webkit-animation-delay: 35021ms;
          animation-delay: 35021ms;
}
@-webkit-keyframes move-frames-112 {
  from {
    transform: translate3d(55vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -112vh, 0);
  }
}
@keyframes move-frames-112 {
  from {
    transform: translate3d(55vw, 105vh, 0);
  }
  to {
    transform: translate3d(5vw, -112vh, 0);
  }
}
.circle-container:nth-child(112) .circle {
  -webkit-animation-delay: 770ms;
          animation-delay: 770ms;
}
.circle-container:nth-child(113) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-113;
          animation-name: move-frames-113;
  -webkit-animation-duration: 33297ms;
          animation-duration: 33297ms;
  -webkit-animation-delay: 7019ms;
          animation-delay: 7019ms;
}
@-webkit-keyframes move-frames-113 {
  from {
    transform: translate3d(15vw, 102vh, 0);
  }
  to {
    transform: translate3d(92vw, -107vh, 0);
  }
}
@keyframes move-frames-113 {
  from {
    transform: translate3d(15vw, 102vh, 0);
  }
  to {
    transform: translate3d(92vw, -107vh, 0);
  }
}
.circle-container:nth-child(113) .circle {
  -webkit-animation-delay: 1945ms;
          animation-delay: 1945ms;
}
.circle-container:nth-child(114) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-114;
          animation-name: move-frames-114;
  -webkit-animation-duration: 30134ms;
          animation-duration: 30134ms;
  -webkit-animation-delay: 19510ms;
          animation-delay: 19510ms;
}
@-webkit-keyframes move-frames-114 {
  from {
    transform: translate3d(10vw, 109vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
@keyframes move-frames-114 {
  from {
    transform: translate3d(10vw, 109vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
.circle-container:nth-child(114) .circle {
  -webkit-animation-delay: 2739ms;
          animation-delay: 2739ms;
}
.circle-container:nth-child(115) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-115;
          animation-name: move-frames-115;
  -webkit-animation-duration: 34127ms;
          animation-duration: 34127ms;
  -webkit-animation-delay: 31543ms;
          animation-delay: 31543ms;
}
@-webkit-keyframes move-frames-115 {
  from {
    transform: translate3d(39vw, 108vh, 0);
  }
  to {
    transform: translate3d(16vw, -130vh, 0);
  }
}
@keyframes move-frames-115 {
  from {
    transform: translate3d(39vw, 108vh, 0);
  }
  to {
    transform: translate3d(16vw, -130vh, 0);
  }
}
.circle-container:nth-child(115) .circle {
  -webkit-animation-delay: 2113ms;
          animation-delay: 2113ms;
}
.circle-container:nth-child(116) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-116;
          animation-name: move-frames-116;
  -webkit-animation-duration: 32705ms;
          animation-duration: 32705ms;
  -webkit-animation-delay: 9971ms;
          animation-delay: 9971ms;
}
@-webkit-keyframes move-frames-116 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }
  to {
    transform: translate3d(60vw, -110vh, 0);
  }
}
@keyframes move-frames-116 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }
  to {
    transform: translate3d(60vw, -110vh, 0);
  }
}
.circle-container:nth-child(116) .circle {
  -webkit-animation-delay: 737ms;
          animation-delay: 737ms;
}
.circle-container:nth-child(117) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-117;
          animation-name: move-frames-117;
  -webkit-animation-duration: 30581ms;
          animation-duration: 30581ms;
  -webkit-animation-delay: 5663ms;
          animation-delay: 5663ms;
}
@-webkit-keyframes move-frames-117 {
  from {
    transform: translate3d(56vw, 108vh, 0);
  }
  to {
    transform: translate3d(12vw, -120vh, 0);
  }
}
@keyframes move-frames-117 {
  from {
    transform: translate3d(56vw, 108vh, 0);
  }
  to {
    transform: translate3d(12vw, -120vh, 0);
  }
}
.circle-container:nth-child(117) .circle {
  -webkit-animation-delay: 2971ms;
          animation-delay: 2971ms;
}
.circle-container:nth-child(118) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-118;
          animation-name: move-frames-118;
  -webkit-animation-duration: 31572ms;
          animation-duration: 31572ms;
  -webkit-animation-delay: 7668ms;
          animation-delay: 7668ms;
}
@-webkit-keyframes move-frames-118 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -133vh, 0);
  }
}
@keyframes move-frames-118 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(47vw, -133vh, 0);
  }
}
.circle-container:nth-child(118) .circle {
  -webkit-animation-delay: 1474ms;
          animation-delay: 1474ms;
}
.circle-container:nth-child(119) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-119;
          animation-name: move-frames-119;
  -webkit-animation-duration: 28231ms;
          animation-duration: 28231ms;
  -webkit-animation-delay: 10078ms;
          animation-delay: 10078ms;
}
@-webkit-keyframes move-frames-119 {
  from {
    transform: translate3d(13vw, 110vh, 0);
  }
  to {
    transform: translate3d(59vw, -132vh, 0);
  }
}
@keyframes move-frames-119 {
  from {
    transform: translate3d(13vw, 110vh, 0);
  }
  to {
    transform: translate3d(59vw, -132vh, 0);
  }
}
.circle-container:nth-child(119) .circle {
  -webkit-animation-delay: 1246ms;
          animation-delay: 1246ms;
}
.circle-container:nth-child(120) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-120;
          animation-name: move-frames-120;
  -webkit-animation-duration: 32014ms;
          animation-duration: 32014ms;
  -webkit-animation-delay: 31660ms;
          animation-delay: 31660ms;
}
@-webkit-keyframes move-frames-120 {
  from {
    transform: translate3d(54vw, 101vh, 0);
  }
  to {
    transform: translate3d(51vw, -108vh, 0);
  }
}
@keyframes move-frames-120 {
  from {
    transform: translate3d(54vw, 101vh, 0);
  }
  to {
    transform: translate3d(51vw, -108vh, 0);
  }
}
.circle-container:nth-child(120) .circle {
  -webkit-animation-delay: 2513ms;
          animation-delay: 2513ms;
}
.circle-container:nth-child(121) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-121;
          animation-name: move-frames-121;
  -webkit-animation-duration: 33562ms;
          animation-duration: 33562ms;
  -webkit-animation-delay: 3707ms;
          animation-delay: 3707ms;
}
@-webkit-keyframes move-frames-121 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(46vw, -127vh, 0);
  }
}
@keyframes move-frames-121 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(46vw, -127vh, 0);
  }
}
.circle-container:nth-child(121) .circle {
  -webkit-animation-delay: 2991ms;
          animation-delay: 2991ms;
}
.circle-container:nth-child(122) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-122;
          animation-name: move-frames-122;
  -webkit-animation-duration: 35907ms;
          animation-duration: 35907ms;
  -webkit-animation-delay: 14480ms;
          animation-delay: 14480ms;
}
@-webkit-keyframes move-frames-122 {
  from {
    transform: translate3d(21vw, 106vh, 0);
  }
  to {
    transform: translate3d(61vw, -136vh, 0);
  }
}
@keyframes move-frames-122 {
  from {
    transform: translate3d(21vw, 106vh, 0);
  }
  to {
    transform: translate3d(61vw, -136vh, 0);
  }
}
.circle-container:nth-child(122) .circle {
  -webkit-animation-delay: 103ms;
          animation-delay: 103ms;
}
.circle-container:nth-child(123) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-123;
          animation-name: move-frames-123;
  -webkit-animation-duration: 32346ms;
          animation-duration: 32346ms;
  -webkit-animation-delay: 4030ms;
          animation-delay: 4030ms;
}
@-webkit-keyframes move-frames-123 {
  from {
    transform: translate3d(10vw, 106vh, 0);
  }
  to {
    transform: translate3d(42vw, -110vh, 0);
  }
}
@keyframes move-frames-123 {
  from {
    transform: translate3d(10vw, 106vh, 0);
  }
  to {
    transform: translate3d(42vw, -110vh, 0);
  }
}
.circle-container:nth-child(123) .circle {
  -webkit-animation-delay: 2421ms;
          animation-delay: 2421ms;
}
.circle-container:nth-child(124) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-124;
          animation-name: move-frames-124;
  -webkit-animation-duration: 36605ms;
          animation-duration: 36605ms;
  -webkit-animation-delay: 34089ms;
          animation-delay: 34089ms;
}
@-webkit-keyframes move-frames-124 {
  from {
    transform: translate3d(42vw, 107vh, 0);
  }
  to {
    transform: translate3d(53vw, -128vh, 0);
  }
}
@keyframes move-frames-124 {
  from {
    transform: translate3d(42vw, 107vh, 0);
  }
  to {
    transform: translate3d(53vw, -128vh, 0);
  }
}
.circle-container:nth-child(124) .circle {
  -webkit-animation-delay: 2359ms;
          animation-delay: 2359ms;
}
.circle-container:nth-child(125) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-125;
          animation-name: move-frames-125;
  -webkit-animation-duration: 33487ms;
          animation-duration: 33487ms;
  -webkit-animation-delay: 29870ms;
          animation-delay: 29870ms;
}
@-webkit-keyframes move-frames-125 {
  from {
    transform: translate3d(23vw, 109vh, 0);
  }
  to {
    transform: translate3d(46vw, -137vh, 0);
  }
}
@keyframes move-frames-125 {
  from {
    transform: translate3d(23vw, 109vh, 0);
  }
  to {
    transform: translate3d(46vw, -137vh, 0);
  }
}
.circle-container:nth-child(125) .circle {
  -webkit-animation-delay: 3027ms;
          animation-delay: 3027ms;
}
.circle-container:nth-child(126) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-126;
          animation-name: move-frames-126;
  -webkit-animation-duration: 33514ms;
          animation-duration: 33514ms;
  -webkit-animation-delay: 12126ms;
          animation-delay: 12126ms;
}
@-webkit-keyframes move-frames-126 {
  from {
    transform: translate3d(56vw, 108vh, 0);
  }
  to {
    transform: translate3d(89vw, -114vh, 0);
  }
}
@keyframes move-frames-126 {
  from {
    transform: translate3d(56vw, 108vh, 0);
  }
  to {
    transform: translate3d(89vw, -114vh, 0);
  }
}
.circle-container:nth-child(126) .circle {
  -webkit-animation-delay: 1164ms;
          animation-delay: 1164ms;
}
.circle-container:nth-child(127) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-127;
          animation-name: move-frames-127;
  -webkit-animation-duration: 33434ms;
          animation-duration: 33434ms;
  -webkit-animation-delay: 11204ms;
          animation-delay: 11204ms;
}
@-webkit-keyframes move-frames-127 {
  from {
    transform: translate3d(97vw, 101vh, 0);
  }
  to {
    transform: translate3d(6vw, -116vh, 0);
  }
}
@keyframes move-frames-127 {
  from {
    transform: translate3d(97vw, 101vh, 0);
  }
  to {
    transform: translate3d(6vw, -116vh, 0);
  }
}
.circle-container:nth-child(127) .circle {
  -webkit-animation-delay: 3036ms;
          animation-delay: 3036ms;
}
.circle-container:nth-child(128) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-128;
          animation-name: move-frames-128;
  -webkit-animation-duration: 31121ms;
          animation-duration: 31121ms;
  -webkit-animation-delay: 9932ms;
          animation-delay: 9932ms;
}
@-webkit-keyframes move-frames-128 {
  from {
    transform: translate3d(90vw, 103vh, 0);
  }
  to {
    transform: translate3d(99vw, -111vh, 0);
  }
}
@keyframes move-frames-128 {
  from {
    transform: translate3d(90vw, 103vh, 0);
  }
  to {
    transform: translate3d(99vw, -111vh, 0);
  }
}
.circle-container:nth-child(128) .circle {
  -webkit-animation-delay: 3065ms;
          animation-delay: 3065ms;
}
.circle-container:nth-child(129) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-129;
          animation-name: move-frames-129;
  -webkit-animation-duration: 29360ms;
          animation-duration: 29360ms;
  -webkit-animation-delay: 4553ms;
          animation-delay: 4553ms;
}
@-webkit-keyframes move-frames-129 {
  from {
    transform: translate3d(28vw, 110vh, 0);
  }
  to {
    transform: translate3d(15vw, -137vh, 0);
  }
}
@keyframes move-frames-129 {
  from {
    transform: translate3d(28vw, 110vh, 0);
  }
  to {
    transform: translate3d(15vw, -137vh, 0);
  }
}
.circle-container:nth-child(129) .circle {
  -webkit-animation-delay: 1022ms;
          animation-delay: 1022ms;
}
.circle-container:nth-child(130) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-130;
          animation-name: move-frames-130;
  -webkit-animation-duration: 36820ms;
          animation-duration: 36820ms;
  -webkit-animation-delay: 20024ms;
          animation-delay: 20024ms;
}
@-webkit-keyframes move-frames-130 {
  from {
    transform: translate3d(3vw, 108vh, 0);
  }
  to {
    transform: translate3d(84vw, -121vh, 0);
  }
}
@keyframes move-frames-130 {
  from {
    transform: translate3d(3vw, 108vh, 0);
  }
  to {
    transform: translate3d(84vw, -121vh, 0);
  }
}
.circle-container:nth-child(130) .circle {
  -webkit-animation-delay: 1349ms;
          animation-delay: 1349ms;
}
.circle-container:nth-child(131) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-131;
          animation-name: move-frames-131;
  -webkit-animation-duration: 36263ms;
          animation-duration: 36263ms;
  -webkit-animation-delay: 7730ms;
          animation-delay: 7730ms;
}
@-webkit-keyframes move-frames-131 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }
  to {
    transform: translate3d(19vw, -103vh, 0);
  }
}
@keyframes move-frames-131 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }
  to {
    transform: translate3d(19vw, -103vh, 0);
  }
}
.circle-container:nth-child(131) .circle {
  -webkit-animation-delay: 1684ms;
          animation-delay: 1684ms;
}
.circle-container:nth-child(132) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-132;
          animation-name: move-frames-132;
  -webkit-animation-duration: 35702ms;
          animation-duration: 35702ms;
  -webkit-animation-delay: 29446ms;
          animation-delay: 29446ms;
}
@-webkit-keyframes move-frames-132 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(62vw, -110vh, 0);
  }
}
@keyframes move-frames-132 {
  from {
    transform: translate3d(29vw, 106vh, 0);
  }
  to {
    transform: translate3d(62vw, -110vh, 0);
  }
}
.circle-container:nth-child(132) .circle {
  -webkit-animation-delay: 1162ms;
          animation-delay: 1162ms;
}
.circle-container:nth-child(133) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-133;
          animation-name: move-frames-133;
  -webkit-animation-duration: 36727ms;
          animation-duration: 36727ms;
  -webkit-animation-delay: 21261ms;
          animation-delay: 21261ms;
}
@-webkit-keyframes move-frames-133 {
  from {
    transform: translate3d(40vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -121vh, 0);
  }
}
@keyframes move-frames-133 {
  from {
    transform: translate3d(40vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -121vh, 0);
  }
}
.circle-container:nth-child(133) .circle {
  -webkit-animation-delay: 2802ms;
          animation-delay: 2802ms;
}
.circle-container:nth-child(134) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-134;
          animation-name: move-frames-134;
  -webkit-animation-duration: 30171ms;
          animation-duration: 30171ms;
  -webkit-animation-delay: 1903ms;
          animation-delay: 1903ms;
}
@-webkit-keyframes move-frames-134 {
  from {
    transform: translate3d(37vw, 104vh, 0);
  }
  to {
    transform: translate3d(91vw, -119vh, 0);
  }
}
@keyframes move-frames-134 {
  from {
    transform: translate3d(37vw, 104vh, 0);
  }
  to {
    transform: translate3d(91vw, -119vh, 0);
  }
}
.circle-container:nth-child(134) .circle {
  -webkit-animation-delay: 934ms;
          animation-delay: 934ms;
}
.circle-container:nth-child(135) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-135;
          animation-name: move-frames-135;
  -webkit-animation-duration: 30752ms;
          animation-duration: 30752ms;
  -webkit-animation-delay: 26203ms;
          animation-delay: 26203ms;
}
@-webkit-keyframes move-frames-135 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }
  to {
    transform: translate3d(7vw, -135vh, 0);
  }
}
@keyframes move-frames-135 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }
  to {
    transform: translate3d(7vw, -135vh, 0);
  }
}
.circle-container:nth-child(135) .circle {
  -webkit-animation-delay: 2757ms;
          animation-delay: 2757ms;
}
.circle-container:nth-child(136) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-136;
          animation-name: move-frames-136;
  -webkit-animation-duration: 29124ms;
          animation-duration: 29124ms;
  -webkit-animation-delay: 34221ms;
          animation-delay: 34221ms;
}
@-webkit-keyframes move-frames-136 {
  from {
    transform: translate3d(69vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -118vh, 0);
  }
}
@keyframes move-frames-136 {
  from {
    transform: translate3d(69vw, 106vh, 0);
  }
  to {
    transform: translate3d(24vw, -118vh, 0);
  }
}
.circle-container:nth-child(136) .circle {
  -webkit-animation-delay: 1617ms;
          animation-delay: 1617ms;
}
.circle-container:nth-child(137) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-137;
          animation-name: move-frames-137;
  -webkit-animation-duration: 28475ms;
          animation-duration: 28475ms;
  -webkit-animation-delay: 17746ms;
          animation-delay: 17746ms;
}
@-webkit-keyframes move-frames-137 {
  from {
    transform: translate3d(4vw, 103vh, 0);
  }
  to {
    transform: translate3d(35vw, -104vh, 0);
  }
}
@keyframes move-frames-137 {
  from {
    transform: translate3d(4vw, 103vh, 0);
  }
  to {
    transform: translate3d(35vw, -104vh, 0);
  }
}
.circle-container:nth-child(137) .circle {
  -webkit-animation-delay: 2070ms;
          animation-delay: 2070ms;
}
.circle-container:nth-child(138) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-138;
          animation-name: move-frames-138;
  -webkit-animation-duration: 29509ms;
          animation-duration: 29509ms;
  -webkit-animation-delay: 30357ms;
          animation-delay: 30357ms;
}
@-webkit-keyframes move-frames-138 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }
  to {
    transform: translate3d(66vw, -128vh, 0);
  }
}
@keyframes move-frames-138 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }
  to {
    transform: translate3d(66vw, -128vh, 0);
  }
}
.circle-container:nth-child(138) .circle {
  -webkit-animation-delay: 1235ms;
          animation-delay: 1235ms;
}
.circle-container:nth-child(139) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-139;
          animation-name: move-frames-139;
  -webkit-animation-duration: 28986ms;
          animation-duration: 28986ms;
  -webkit-animation-delay: 36174ms;
          animation-delay: 36174ms;
}
@-webkit-keyframes move-frames-139 {
  from {
    transform: translate3d(72vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -132vh, 0);
  }
}
@keyframes move-frames-139 {
  from {
    transform: translate3d(72vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -132vh, 0);
  }
}
.circle-container:nth-child(139) .circle {
  -webkit-animation-delay: 1540ms;
          animation-delay: 1540ms;
}
.circle-container:nth-child(140) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-140;
          animation-name: move-frames-140;
  -webkit-animation-duration: 36764ms;
          animation-duration: 36764ms;
  -webkit-animation-delay: 27362ms;
          animation-delay: 27362ms;
}
@-webkit-keyframes move-frames-140 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(9vw, -118vh, 0);
  }
}
@keyframes move-frames-140 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(9vw, -118vh, 0);
  }
}
.circle-container:nth-child(140) .circle {
  -webkit-animation-delay: 3563ms;
          animation-delay: 3563ms;
}
.circle-container:nth-child(141) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-141;
          animation-name: move-frames-141;
  -webkit-animation-duration: 35252ms;
          animation-duration: 35252ms;
  -webkit-animation-delay: 30694ms;
          animation-delay: 30694ms;
}
@-webkit-keyframes move-frames-141 {
  from {
    transform: translate3d(50vw, 107vh, 0);
  }
  to {
    transform: translate3d(68vw, -137vh, 0);
  }
}
@keyframes move-frames-141 {
  from {
    transform: translate3d(50vw, 107vh, 0);
  }
  to {
    transform: translate3d(68vw, -137vh, 0);
  }
}
.circle-container:nth-child(141) .circle {
  -webkit-animation-delay: 438ms;
          animation-delay: 438ms;
}
.circle-container:nth-child(142) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-142;
          animation-name: move-frames-142;
  -webkit-animation-duration: 31065ms;
          animation-duration: 31065ms;
  -webkit-animation-delay: 25027ms;
          animation-delay: 25027ms;
}
@-webkit-keyframes move-frames-142 {
  from {
    transform: translate3d(16vw, 105vh, 0);
  }
  to {
    transform: translate3d(71vw, -125vh, 0);
  }
}
@keyframes move-frames-142 {
  from {
    transform: translate3d(16vw, 105vh, 0);
  }
  to {
    transform: translate3d(71vw, -125vh, 0);
  }
}
.circle-container:nth-child(142) .circle {
  -webkit-animation-delay: 2784ms;
          animation-delay: 2784ms;
}
.circle-container:nth-child(143) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-143;
          animation-name: move-frames-143;
  -webkit-animation-duration: 29850ms;
          animation-duration: 29850ms;
  -webkit-animation-delay: 3647ms;
          animation-delay: 3647ms;
}
@-webkit-keyframes move-frames-143 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(93vw, -108vh, 0);
  }
}
@keyframes move-frames-143 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(93vw, -108vh, 0);
  }
}
.circle-container:nth-child(143) .circle {
  -webkit-animation-delay: 1819ms;
          animation-delay: 1819ms;
}
.circle-container:nth-child(144) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-144;
          animation-name: move-frames-144;
  -webkit-animation-duration: 34511ms;
          animation-duration: 34511ms;
  -webkit-animation-delay: 16341ms;
          animation-delay: 16341ms;
}
@-webkit-keyframes move-frames-144 {
  from {
    transform: translate3d(90vw, 104vh, 0);
  }
  to {
    transform: translate3d(18vw, -126vh, 0);
  }
}
@keyframes move-frames-144 {
  from {
    transform: translate3d(90vw, 104vh, 0);
  }
  to {
    transform: translate3d(18vw, -126vh, 0);
  }
}
.circle-container:nth-child(144) .circle {
  -webkit-animation-delay: 882ms;
          animation-delay: 882ms;
}
.circle-container:nth-child(145) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-145;
          animation-name: move-frames-145;
  -webkit-animation-duration: 35053ms;
          animation-duration: 35053ms;
  -webkit-animation-delay: 23288ms;
          animation-delay: 23288ms;
}
@-webkit-keyframes move-frames-145 {
  from {
    transform: translate3d(57vw, 108vh, 0);
  }
  to {
    transform: translate3d(32vw, -119vh, 0);
  }
}
@keyframes move-frames-145 {
  from {
    transform: translate3d(57vw, 108vh, 0);
  }
  to {
    transform: translate3d(32vw, -119vh, 0);
  }
}
.circle-container:nth-child(145) .circle {
  -webkit-animation-delay: 890ms;
          animation-delay: 890ms;
}
.circle-container:nth-child(146) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-146;
          animation-name: move-frames-146;
  -webkit-animation-duration: 35799ms;
          animation-duration: 35799ms;
  -webkit-animation-delay: 28218ms;
          animation-delay: 28218ms;
}
@-webkit-keyframes move-frames-146 {
  from {
    transform: translate3d(43vw, 110vh, 0);
  }
  to {
    transform: translate3d(76vw, -123vh, 0);
  }
}
@keyframes move-frames-146 {
  from {
    transform: translate3d(43vw, 110vh, 0);
  }
  to {
    transform: translate3d(76vw, -123vh, 0);
  }
}
.circle-container:nth-child(146) .circle {
  -webkit-animation-delay: 1696ms;
          animation-delay: 1696ms;
}
.circle-container:nth-child(147) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-147;
          animation-name: move-frames-147;
  -webkit-animation-duration: 31584ms;
          animation-duration: 31584ms;
  -webkit-animation-delay: 16302ms;
          animation-delay: 16302ms;
}
@-webkit-keyframes move-frames-147 {
  from {
    transform: translate3d(9vw, 110vh, 0);
  }
  to {
    transform: translate3d(65vw, -137vh, 0);
  }
}
@keyframes move-frames-147 {
  from {
    transform: translate3d(9vw, 110vh, 0);
  }
  to {
    transform: translate3d(65vw, -137vh, 0);
  }
}
.circle-container:nth-child(147) .circle {
  -webkit-animation-delay: 3229ms;
          animation-delay: 3229ms;
}
.circle-container:nth-child(148) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-148;
          animation-name: move-frames-148;
  -webkit-animation-duration: 28830ms;
          animation-duration: 28830ms;
  -webkit-animation-delay: 424ms;
          animation-delay: 424ms;
}
@-webkit-keyframes move-frames-148 {
  from {
    transform: translate3d(3vw, 110vh, 0);
  }
  to {
    transform: translate3d(24vw, -135vh, 0);
  }
}
@keyframes move-frames-148 {
  from {
    transform: translate3d(3vw, 110vh, 0);
  }
  to {
    transform: translate3d(24vw, -135vh, 0);
  }
}
.circle-container:nth-child(148) .circle {
  -webkit-animation-delay: 2408ms;
          animation-delay: 2408ms;
}
.circle-container:nth-child(149) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-149;
          animation-name: move-frames-149;
  -webkit-animation-duration: 34207ms;
          animation-duration: 34207ms;
  -webkit-animation-delay: 22155ms;
          animation-delay: 22155ms;
}
@-webkit-keyframes move-frames-149 {
  from {
    transform: translate3d(94vw, 110vh, 0);
  }
  to {
    transform: translate3d(88vw, -126vh, 0);
  }
}
@keyframes move-frames-149 {
  from {
    transform: translate3d(94vw, 110vh, 0);
  }
  to {
    transform: translate3d(88vw, -126vh, 0);
  }
}
.circle-container:nth-child(149) .circle {
  -webkit-animation-delay: 1050ms;
          animation-delay: 1050ms;
}
.circle-container:nth-child(150) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-150;
          animation-name: move-frames-150;
  -webkit-animation-duration: 31412ms;
          animation-duration: 31412ms;
  -webkit-animation-delay: 31637ms;
          animation-delay: 31637ms;
}
@-webkit-keyframes move-frames-150 {
  from {
    transform: translate3d(63vw, 102vh, 0);
  }
  to {
    transform: translate3d(46vw, -116vh, 0);
  }
}
@keyframes move-frames-150 {
  from {
    transform: translate3d(63vw, 102vh, 0);
  }
  to {
    transform: translate3d(46vw, -116vh, 0);
  }
}
.circle-container:nth-child(150) .circle {
  -webkit-animation-delay: 1878ms;
          animation-delay: 1878ms;
}
.circle-container:nth-child(151) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-151;
          animation-name: move-frames-151;
  -webkit-animation-duration: 35477ms;
          animation-duration: 35477ms;
  -webkit-animation-delay: 327ms;
          animation-delay: 327ms;
}
@-webkit-keyframes move-frames-151 {
  from {
    transform: translate3d(79vw, 105vh, 0);
  }
  to {
    transform: translate3d(67vw, -108vh, 0);
  }
}
@keyframes move-frames-151 {
  from {
    transform: translate3d(79vw, 105vh, 0);
  }
  to {
    transform: translate3d(67vw, -108vh, 0);
  }
}
.circle-container:nth-child(151) .circle {
  -webkit-animation-delay: 3444ms;
          animation-delay: 3444ms;
}
.circle-container:nth-child(152) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-152;
          animation-name: move-frames-152;
  -webkit-animation-duration: 32085ms;
          animation-duration: 32085ms;
  -webkit-animation-delay: 10440ms;
          animation-delay: 10440ms;
}
@-webkit-keyframes move-frames-152 {
  from {
    transform: translate3d(82vw, 110vh, 0);
  }
  to {
    transform: translate3d(58vw, -134vh, 0);
  }
}
@keyframes move-frames-152 {
  from {
    transform: translate3d(82vw, 110vh, 0);
  }
  to {
    transform: translate3d(58vw, -134vh, 0);
  }
}
.circle-container:nth-child(152) .circle {
  -webkit-animation-delay: 503ms;
          animation-delay: 503ms;
}
.circle-container:nth-child(153) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-153;
          animation-name: move-frames-153;
  -webkit-animation-duration: 33251ms;
          animation-duration: 33251ms;
  -webkit-animation-delay: 5213ms;
          animation-delay: 5213ms;
}
@-webkit-keyframes move-frames-153 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(33vw, -113vh, 0);
  }
}
@keyframes move-frames-153 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(33vw, -113vh, 0);
  }
}
.circle-container:nth-child(153) .circle {
  -webkit-animation-delay: 1589ms;
          animation-delay: 1589ms;
}
.circle-container:nth-child(154) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-154;
          animation-name: move-frames-154;
  -webkit-animation-duration: 31428ms;
          animation-duration: 31428ms;
  -webkit-animation-delay: 33933ms;
          animation-delay: 33933ms;
}
@-webkit-keyframes move-frames-154 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -127vh, 0);
  }
}
@keyframes move-frames-154 {
  from {
    transform: translate3d(26vw, 102vh, 0);
  }
  to {
    transform: translate3d(50vw, -127vh, 0);
  }
}
.circle-container:nth-child(154) .circle {
  -webkit-animation-delay: 1790ms;
          animation-delay: 1790ms;
}
.circle-container:nth-child(155) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-155;
          animation-name: move-frames-155;
  -webkit-animation-duration: 35956ms;
          animation-duration: 35956ms;
  -webkit-animation-delay: 11137ms;
          animation-delay: 11137ms;
}
@-webkit-keyframes move-frames-155 {
  from {
    transform: translate3d(7vw, 101vh, 0);
  }
  to {
    transform: translate3d(75vw, -124vh, 0);
  }
}
@keyframes move-frames-155 {
  from {
    transform: translate3d(7vw, 101vh, 0);
  }
  to {
    transform: translate3d(75vw, -124vh, 0);
  }
}
.circle-container:nth-child(155) .circle {
  -webkit-animation-delay: 1061ms;
          animation-delay: 1061ms;
}
.circle-container:nth-child(156) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-156;
          animation-name: move-frames-156;
  -webkit-animation-duration: 32867ms;
          animation-duration: 32867ms;
  -webkit-animation-delay: 18692ms;
          animation-delay: 18692ms;
}
@-webkit-keyframes move-frames-156 {
  from {
    transform: translate3d(95vw, 101vh, 0);
  }
  to {
    transform: translate3d(58vw, -119vh, 0);
  }
}
@keyframes move-frames-156 {
  from {
    transform: translate3d(95vw, 101vh, 0);
  }
  to {
    transform: translate3d(58vw, -119vh, 0);
  }
}
.circle-container:nth-child(156) .circle {
  -webkit-animation-delay: 2984ms;
          animation-delay: 2984ms;
}
.circle-container:nth-child(157) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-157;
          animation-name: move-frames-157;
  -webkit-animation-duration: 33034ms;
          animation-duration: 33034ms;
  -webkit-animation-delay: 1339ms;
          animation-delay: 1339ms;
}
@-webkit-keyframes move-frames-157 {
  from {
    transform: translate3d(60vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -123vh, 0);
  }
}
@keyframes move-frames-157 {
  from {
    transform: translate3d(60vw, 110vh, 0);
  }
  to {
    transform: translate3d(54vw, -123vh, 0);
  }
}
.circle-container:nth-child(157) .circle {
  -webkit-animation-delay: 1971ms;
          animation-delay: 1971ms;
}
.circle-container:nth-child(158) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-158;
          animation-name: move-frames-158;
  -webkit-animation-duration: 33395ms;
          animation-duration: 33395ms;
  -webkit-animation-delay: 19506ms;
          animation-delay: 19506ms;
}
@-webkit-keyframes move-frames-158 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(33vw, -112vh, 0);
  }
}
@keyframes move-frames-158 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(33vw, -112vh, 0);
  }
}
.circle-container:nth-child(158) .circle {
  -webkit-animation-delay: 219ms;
          animation-delay: 219ms;
}
.circle-container:nth-child(159) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-159;
          animation-name: move-frames-159;
  -webkit-animation-duration: 30989ms;
          animation-duration: 30989ms;
  -webkit-animation-delay: 346ms;
          animation-delay: 346ms;
}
@-webkit-keyframes move-frames-159 {
  from {
    transform: translate3d(24vw, 109vh, 0);
  }
  to {
    transform: translate3d(88vw, -129vh, 0);
  }
}
@keyframes move-frames-159 {
  from {
    transform: translate3d(24vw, 109vh, 0);
  }
  to {
    transform: translate3d(88vw, -129vh, 0);
  }
}
.circle-container:nth-child(159) .circle {
  -webkit-animation-delay: 466ms;
          animation-delay: 466ms;
}
.circle-container:nth-child(160) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-160;
          animation-name: move-frames-160;
  -webkit-animation-duration: 32514ms;
          animation-duration: 32514ms;
  -webkit-animation-delay: 12899ms;
          animation-delay: 12899ms;
}
@-webkit-keyframes move-frames-160 {
  from {
    transform: translate3d(25vw, 108vh, 0);
  }
  to {
    transform: translate3d(36vw, -137vh, 0);
  }
}
@keyframes move-frames-160 {
  from {
    transform: translate3d(25vw, 108vh, 0);
  }
  to {
    transform: translate3d(36vw, -137vh, 0);
  }
}
.circle-container:nth-child(160) .circle {
  -webkit-animation-delay: 2684ms;
          animation-delay: 2684ms;
}
.circle-container:nth-child(161) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-161;
          animation-name: move-frames-161;
  -webkit-animation-duration: 28681ms;
          animation-duration: 28681ms;
  -webkit-animation-delay: 9536ms;
          animation-delay: 9536ms;
}
@-webkit-keyframes move-frames-161 {
  from {
    transform: translate3d(60vw, 102vh, 0);
  }
  to {
    transform: translate3d(32vw, -121vh, 0);
  }
}
@keyframes move-frames-161 {
  from {
    transform: translate3d(60vw, 102vh, 0);
  }
  to {
    transform: translate3d(32vw, -121vh, 0);
  }
}
.circle-container:nth-child(161) .circle {
  -webkit-animation-delay: 2044ms;
          animation-delay: 2044ms;
}
.circle-container:nth-child(162) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-162;
          animation-name: move-frames-162;
  -webkit-animation-duration: 30731ms;
          animation-duration: 30731ms;
  -webkit-animation-delay: 10531ms;
          animation-delay: 10531ms;
}
@-webkit-keyframes move-frames-162 {
  from {
    transform: translate3d(84vw, 104vh, 0);
  }
  to {
    transform: translate3d(63vw, -113vh, 0);
  }
}
@keyframes move-frames-162 {
  from {
    transform: translate3d(84vw, 104vh, 0);
  }
  to {
    transform: translate3d(63vw, -113vh, 0);
  }
}
.circle-container:nth-child(162) .circle {
  -webkit-animation-delay: 1213ms;
          animation-delay: 1213ms;
}
.circle-container:nth-child(163) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-163;
          animation-name: move-frames-163;
  -webkit-animation-duration: 31120ms;
          animation-duration: 31120ms;
  -webkit-animation-delay: 4244ms;
          animation-delay: 4244ms;
}
@-webkit-keyframes move-frames-163 {
  from {
    transform: translate3d(64vw, 107vh, 0);
  }
  to {
    transform: translate3d(96vw, -110vh, 0);
  }
}
@keyframes move-frames-163 {
  from {
    transform: translate3d(64vw, 107vh, 0);
  }
  to {
    transform: translate3d(96vw, -110vh, 0);
  }
}
.circle-container:nth-child(163) .circle {
  -webkit-animation-delay: 1876ms;
          animation-delay: 1876ms;
}
.circle-container:nth-child(164) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-164;
          animation-name: move-frames-164;
  -webkit-animation-duration: 32266ms;
          animation-duration: 32266ms;
  -webkit-animation-delay: 22527ms;
          animation-delay: 22527ms;
}
@-webkit-keyframes move-frames-164 {
  from {
    transform: translate3d(51vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -114vh, 0);
  }
}
@keyframes move-frames-164 {
  from {
    transform: translate3d(51vw, 105vh, 0);
  }
  to {
    transform: translate3d(70vw, -114vh, 0);
  }
}
.circle-container:nth-child(164) .circle {
  -webkit-animation-delay: 216ms;
          animation-delay: 216ms;
}
.circle-container:nth-child(165) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-165;
          animation-name: move-frames-165;
  -webkit-animation-duration: 29539ms;
          animation-duration: 29539ms;
  -webkit-animation-delay: 29968ms;
          animation-delay: 29968ms;
}
@-webkit-keyframes move-frames-165 {
  from {
    transform: translate3d(50vw, 110vh, 0);
  }
  to {
    transform: translate3d(26vw, -116vh, 0);
  }
}
@keyframes move-frames-165 {
  from {
    transform: translate3d(50vw, 110vh, 0);
  }
  to {
    transform: translate3d(26vw, -116vh, 0);
  }
}
.circle-container:nth-child(165) .circle {
  -webkit-animation-delay: 3657ms;
          animation-delay: 3657ms;
}
.circle-container:nth-child(166) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-166;
          animation-name: move-frames-166;
  -webkit-animation-duration: 32568ms;
          animation-duration: 32568ms;
  -webkit-animation-delay: 8496ms;
          animation-delay: 8496ms;
}
@-webkit-keyframes move-frames-166 {
  from {
    transform: translate3d(64vw, 107vh, 0);
  }
  to {
    transform: translate3d(60vw, -113vh, 0);
  }
}
@keyframes move-frames-166 {
  from {
    transform: translate3d(64vw, 107vh, 0);
  }
  to {
    transform: translate3d(60vw, -113vh, 0);
  }
}
.circle-container:nth-child(166) .circle {
  -webkit-animation-delay: 325ms;
          animation-delay: 325ms;
}
.circle-container:nth-child(167) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-167;
          animation-name: move-frames-167;
  -webkit-animation-duration: 29955ms;
          animation-duration: 29955ms;
  -webkit-animation-delay: 25970ms;
          animation-delay: 25970ms;
}
@-webkit-keyframes move-frames-167 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(93vw, -123vh, 0);
  }
}
@keyframes move-frames-167 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(93vw, -123vh, 0);
  }
}
.circle-container:nth-child(167) .circle {
  -webkit-animation-delay: 3746ms;
          animation-delay: 3746ms;
}
.circle-container:nth-child(168) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-168;
          animation-name: move-frames-168;
  -webkit-animation-duration: 34360ms;
          animation-duration: 34360ms;
  -webkit-animation-delay: 7889ms;
          animation-delay: 7889ms;
}
@-webkit-keyframes move-frames-168 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -129vh, 0);
  }
}
@keyframes move-frames-168 {
  from {
    transform: translate3d(2vw, 106vh, 0);
  }
  to {
    transform: translate3d(39vw, -129vh, 0);
  }
}
.circle-container:nth-child(168) .circle {
  -webkit-animation-delay: 2569ms;
          animation-delay: 2569ms;
}
.circle-container:nth-child(169) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-169;
          animation-name: move-frames-169;
  -webkit-animation-duration: 33678ms;
          animation-duration: 33678ms;
  -webkit-animation-delay: 27555ms;
          animation-delay: 27555ms;
}
@-webkit-keyframes move-frames-169 {
  from {
    transform: translate3d(74vw, 106vh, 0);
  }
  to {
    transform: translate3d(29vw, -133vh, 0);
  }
}
@keyframes move-frames-169 {
  from {
    transform: translate3d(74vw, 106vh, 0);
  }
  to {
    transform: translate3d(29vw, -133vh, 0);
  }
}
.circle-container:nth-child(169) .circle {
  -webkit-animation-delay: 1450ms;
          animation-delay: 1450ms;
}
.circle-container:nth-child(170) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-170;
          animation-name: move-frames-170;
  -webkit-animation-duration: 36695ms;
          animation-duration: 36695ms;
  -webkit-animation-delay: 36225ms;
          animation-delay: 36225ms;
}
@-webkit-keyframes move-frames-170 {
  from {
    transform: translate3d(59vw, 103vh, 0);
  }
  to {
    transform: translate3d(12vw, -119vh, 0);
  }
}
@keyframes move-frames-170 {
  from {
    transform: translate3d(59vw, 103vh, 0);
  }
  to {
    transform: translate3d(12vw, -119vh, 0);
  }
}
.circle-container:nth-child(170) .circle {
  -webkit-animation-delay: 3520ms;
          animation-delay: 3520ms;
}
.circle-container:nth-child(171) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-171;
          animation-name: move-frames-171;
  -webkit-animation-duration: 36182ms;
          animation-duration: 36182ms;
  -webkit-animation-delay: 7707ms;
          animation-delay: 7707ms;
}
@-webkit-keyframes move-frames-171 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -109vh, 0);
  }
}
@keyframes move-frames-171 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }
  to {
    transform: translate3d(34vw, -109vh, 0);
  }
}
.circle-container:nth-child(171) .circle {
  -webkit-animation-delay: 2602ms;
          animation-delay: 2602ms;
}
.circle-container:nth-child(172) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-172;
          animation-name: move-frames-172;
  -webkit-animation-duration: 29005ms;
          animation-duration: 29005ms;
  -webkit-animation-delay: 13973ms;
          animation-delay: 13973ms;
}
@-webkit-keyframes move-frames-172 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }
  to {
    transform: translate3d(88vw, -119vh, 0);
  }
}
@keyframes move-frames-172 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }
  to {
    transform: translate3d(88vw, -119vh, 0);
  }
}
.circle-container:nth-child(172) .circle {
  -webkit-animation-delay: 321ms;
          animation-delay: 321ms;
}
.circle-container:nth-child(173) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-173;
          animation-name: move-frames-173;
  -webkit-animation-duration: 33915ms;
          animation-duration: 33915ms;
  -webkit-animation-delay: 28528ms;
          animation-delay: 28528ms;
}
@-webkit-keyframes move-frames-173 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(7vw, -114vh, 0);
  }
}
@keyframes move-frames-173 {
  from {
    transform: translate3d(24vw, 102vh, 0);
  }
  to {
    transform: translate3d(7vw, -114vh, 0);
  }
}
.circle-container:nth-child(173) .circle {
  -webkit-animation-delay: 2021ms;
          animation-delay: 2021ms;
}
.circle-container:nth-child(174) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-174;
          animation-name: move-frames-174;
  -webkit-animation-duration: 31430ms;
          animation-duration: 31430ms;
  -webkit-animation-delay: 26152ms;
          animation-delay: 26152ms;
}
@-webkit-keyframes move-frames-174 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(92vw, -124vh, 0);
  }
}
@keyframes move-frames-174 {
  from {
    transform: translate3d(42vw, 101vh, 0);
  }
  to {
    transform: translate3d(92vw, -124vh, 0);
  }
}
.circle-container:nth-child(174) .circle {
  -webkit-animation-delay: 2492ms;
          animation-delay: 2492ms;
}
.circle-container:nth-child(175) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-175;
          animation-name: move-frames-175;
  -webkit-animation-duration: 31708ms;
          animation-duration: 31708ms;
  -webkit-animation-delay: 2483ms;
          animation-delay: 2483ms;
}
@-webkit-keyframes move-frames-175 {
  from {
    transform: translate3d(5vw, 108vh, 0);
  }
  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}
@keyframes move-frames-175 {
  from {
    transform: translate3d(5vw, 108vh, 0);
  }
  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}
.circle-container:nth-child(175) .circle {
  -webkit-animation-delay: 3636ms;
          animation-delay: 3636ms;
}
.circle-container:nth-child(176) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-176;
          animation-name: move-frames-176;
  -webkit-animation-duration: 31344ms;
          animation-duration: 31344ms;
  -webkit-animation-delay: 14260ms;
          animation-delay: 14260ms;
}
@-webkit-keyframes move-frames-176 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(59vw, -119vh, 0);
  }
}
@keyframes move-frames-176 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(59vw, -119vh, 0);
  }
}
.circle-container:nth-child(176) .circle {
  -webkit-animation-delay: 2959ms;
          animation-delay: 2959ms;
}
.circle-container:nth-child(177) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-177;
          animation-name: move-frames-177;
  -webkit-animation-duration: 36411ms;
          animation-duration: 36411ms;
  -webkit-animation-delay: 3495ms;
          animation-delay: 3495ms;
}
@-webkit-keyframes move-frames-177 {
  from {
    transform: translate3d(68vw, 105vh, 0);
  }
  to {
    transform: translate3d(17vw, -118vh, 0);
  }
}
@keyframes move-frames-177 {
  from {
    transform: translate3d(68vw, 105vh, 0);
  }
  to {
    transform: translate3d(17vw, -118vh, 0);
  }
}
.circle-container:nth-child(177) .circle {
  -webkit-animation-delay: 3325ms;
          animation-delay: 3325ms;
}
.circle-container:nth-child(178) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-178;
          animation-name: move-frames-178;
  -webkit-animation-duration: 36271ms;
          animation-duration: 36271ms;
  -webkit-animation-delay: 18545ms;
          animation-delay: 18545ms;
}
@-webkit-keyframes move-frames-178 {
  from {
    transform: translate3d(3vw, 110vh, 0);
  }
  to {
    transform: translate3d(52vw, -125vh, 0);
  }
}
@keyframes move-frames-178 {
  from {
    transform: translate3d(3vw, 110vh, 0);
  }
  to {
    transform: translate3d(52vw, -125vh, 0);
  }
}
.circle-container:nth-child(178) .circle {
  -webkit-animation-delay: 1561ms;
          animation-delay: 1561ms;
}
.circle-container:nth-child(179) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-179;
          animation-name: move-frames-179;
  -webkit-animation-duration: 32331ms;
          animation-duration: 32331ms;
  -webkit-animation-delay: 12986ms;
          animation-delay: 12986ms;
}
@-webkit-keyframes move-frames-179 {
  from {
    transform: translate3d(59vw, 109vh, 0);
  }
  to {
    transform: translate3d(37vw, -137vh, 0);
  }
}
@keyframes move-frames-179 {
  from {
    transform: translate3d(59vw, 109vh, 0);
  }
  to {
    transform: translate3d(37vw, -137vh, 0);
  }
}
.circle-container:nth-child(179) .circle {
  -webkit-animation-delay: 1929ms;
          animation-delay: 1929ms;
}
.circle-container:nth-child(180) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-180;
          animation-name: move-frames-180;
  -webkit-animation-duration: 32749ms;
          animation-duration: 32749ms;
  -webkit-animation-delay: 688ms;
          animation-delay: 688ms;
}
@-webkit-keyframes move-frames-180 {
  from {
    transform: translate3d(75vw, 108vh, 0);
  }
  to {
    transform: translate3d(48vw, -133vh, 0);
  }
}
@keyframes move-frames-180 {
  from {
    transform: translate3d(75vw, 108vh, 0);
  }
  to {
    transform: translate3d(48vw, -133vh, 0);
  }
}
.circle-container:nth-child(180) .circle {
  -webkit-animation-delay: 295ms;
          animation-delay: 295ms;
}
.circle-container:nth-child(181) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-181;
          animation-name: move-frames-181;
  -webkit-animation-duration: 30605ms;
          animation-duration: 30605ms;
  -webkit-animation-delay: 35661ms;
          animation-delay: 35661ms;
}
@-webkit-keyframes move-frames-181 {
  from {
    transform: translate3d(75vw, 101vh, 0);
  }
  to {
    transform: translate3d(47vw, -112vh, 0);
  }
}
@keyframes move-frames-181 {
  from {
    transform: translate3d(75vw, 101vh, 0);
  }
  to {
    transform: translate3d(47vw, -112vh, 0);
  }
}
.circle-container:nth-child(181) .circle {
  -webkit-animation-delay: 1656ms;
          animation-delay: 1656ms;
}
.circle-container:nth-child(182) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-182;
          animation-name: move-frames-182;
  -webkit-animation-duration: 31423ms;
          animation-duration: 31423ms;
  -webkit-animation-delay: 25086ms;
          animation-delay: 25086ms;
}
@-webkit-keyframes move-frames-182 {
  from {
    transform: translate3d(50vw, 109vh, 0);
  }
  to {
    transform: translate3d(11vw, -125vh, 0);
  }
}
@keyframes move-frames-182 {
  from {
    transform: translate3d(50vw, 109vh, 0);
  }
  to {
    transform: translate3d(11vw, -125vh, 0);
  }
}
.circle-container:nth-child(182) .circle {
  -webkit-animation-delay: 1463ms;
          animation-delay: 1463ms;
}
.circle-container:nth-child(183) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-183;
          animation-name: move-frames-183;
  -webkit-animation-duration: 30725ms;
          animation-duration: 30725ms;
  -webkit-animation-delay: 26106ms;
          animation-delay: 26106ms;
}
@-webkit-keyframes move-frames-183 {
  from {
    transform: translate3d(99vw, 109vh, 0);
  }
  to {
    transform: translate3d(58vw, -128vh, 0);
  }
}
@keyframes move-frames-183 {
  from {
    transform: translate3d(99vw, 109vh, 0);
  }
  to {
    transform: translate3d(58vw, -128vh, 0);
  }
}
.circle-container:nth-child(183) .circle {
  -webkit-animation-delay: 767ms;
          animation-delay: 767ms;
}
.circle-container:nth-child(184) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-184;
          animation-name: move-frames-184;
  -webkit-animation-duration: 31622ms;
          animation-duration: 31622ms;
  -webkit-animation-delay: 11044ms;
          animation-delay: 11044ms;
}
@-webkit-keyframes move-frames-184 {
  from {
    transform: translate3d(43vw, 103vh, 0);
  }
  to {
    transform: translate3d(18vw, -106vh, 0);
  }
}
@keyframes move-frames-184 {
  from {
    transform: translate3d(43vw, 103vh, 0);
  }
  to {
    transform: translate3d(18vw, -106vh, 0);
  }
}
.circle-container:nth-child(184) .circle {
  -webkit-animation-delay: 713ms;
          animation-delay: 713ms;
}
.circle-container:nth-child(185) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-185;
          animation-name: move-frames-185;
  -webkit-animation-duration: 36521ms;
          animation-duration: 36521ms;
  -webkit-animation-delay: 35567ms;
          animation-delay: 35567ms;
}
@-webkit-keyframes move-frames-185 {
  from {
    transform: translate3d(47vw, 107vh, 0);
  }
  to {
    transform: translate3d(26vw, -124vh, 0);
  }
}
@keyframes move-frames-185 {
  from {
    transform: translate3d(47vw, 107vh, 0);
  }
  to {
    transform: translate3d(26vw, -124vh, 0);
  }
}
.circle-container:nth-child(185) .circle {
  -webkit-animation-delay: 2424ms;
          animation-delay: 2424ms;
}
.circle-container:nth-child(186) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-186;
          animation-name: move-frames-186;
  -webkit-animation-duration: 31507ms;
          animation-duration: 31507ms;
  -webkit-animation-delay: 28351ms;
          animation-delay: 28351ms;
}
@-webkit-keyframes move-frames-186 {
  from {
    transform: translate3d(10vw, 106vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
@keyframes move-frames-186 {
  from {
    transform: translate3d(10vw, 106vh, 0);
  }
  to {
    transform: translate3d(80vw, -129vh, 0);
  }
}
.circle-container:nth-child(186) .circle {
  -webkit-animation-delay: 763ms;
          animation-delay: 763ms;
}
.circle-container:nth-child(187) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-187;
          animation-name: move-frames-187;
  -webkit-animation-duration: 30925ms;
          animation-duration: 30925ms;
  -webkit-animation-delay: 6229ms;
          animation-delay: 6229ms;
}
@-webkit-keyframes move-frames-187 {
  from {
    transform: translate3d(15vw, 105vh, 0);
  }
  to {
    transform: translate3d(3vw, -121vh, 0);
  }
}
@keyframes move-frames-187 {
  from {
    transform: translate3d(15vw, 105vh, 0);
  }
  to {
    transform: translate3d(3vw, -121vh, 0);
  }
}
.circle-container:nth-child(187) .circle {
  -webkit-animation-delay: 1578ms;
          animation-delay: 1578ms;
}
.circle-container:nth-child(188) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-188;
          animation-name: move-frames-188;
  -webkit-animation-duration: 35079ms;
          animation-duration: 35079ms;
  -webkit-animation-delay: 3601ms;
          animation-delay: 3601ms;
}
@-webkit-keyframes move-frames-188 {
  from {
    transform: translate3d(40vw, 105vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
@keyframes move-frames-188 {
  from {
    transform: translate3d(40vw, 105vh, 0);
  }
  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}
.circle-container:nth-child(188) .circle {
  -webkit-animation-delay: 3632ms;
          animation-delay: 3632ms;
}
.circle-container:nth-child(189) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-189;
          animation-name: move-frames-189;
  -webkit-animation-duration: 28054ms;
          animation-duration: 28054ms;
  -webkit-animation-delay: 21434ms;
          animation-delay: 21434ms;
}
@-webkit-keyframes move-frames-189 {
  from {
    transform: translate3d(28vw, 105vh, 0);
  }
  to {
    transform: translate3d(45vw, -135vh, 0);
  }
}
@keyframes move-frames-189 {
  from {
    transform: translate3d(28vw, 105vh, 0);
  }
  to {
    transform: translate3d(45vw, -135vh, 0);
  }
}
.circle-container:nth-child(189) .circle {
  -webkit-animation-delay: 3316ms;
          animation-delay: 3316ms;
}
.circle-container:nth-child(190) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-190;
          animation-name: move-frames-190;
  -webkit-animation-duration: 34427ms;
          animation-duration: 34427ms;
  -webkit-animation-delay: 7991ms;
          animation-delay: 7991ms;
}
@-webkit-keyframes move-frames-190 {
  from {
    transform: translate3d(70vw, 107vh, 0);
  }
  to {
    transform: translate3d(18vw, -115vh, 0);
  }
}
@keyframes move-frames-190 {
  from {
    transform: translate3d(70vw, 107vh, 0);
  }
  to {
    transform: translate3d(18vw, -115vh, 0);
  }
}
.circle-container:nth-child(190) .circle {
  -webkit-animation-delay: 3656ms;
          animation-delay: 3656ms;
}
.circle-container:nth-child(191) {
  width: 4px;
  height: 4px;
  -webkit-animation-name: move-frames-191;
          animation-name: move-frames-191;
  -webkit-animation-duration: 29405ms;
          animation-duration: 29405ms;
  -webkit-animation-delay: 9248ms;
          animation-delay: 9248ms;
}
@-webkit-keyframes move-frames-191 {
  from {
    transform: translate3d(6vw, 107vh, 0);
  }
  to {
    transform: translate3d(39vw, -120vh, 0);
  }
}
@keyframes move-frames-191 {
  from {
    transform: translate3d(6vw, 107vh, 0);
  }
  to {
    transform: translate3d(39vw, -120vh, 0);
  }
}
.circle-container:nth-child(191) .circle {
  -webkit-animation-delay: 1842ms;
          animation-delay: 1842ms;
}
.circle-container:nth-child(192) {
  width: 6px;
  height: 6px;
  -webkit-animation-name: move-frames-192;
          animation-name: move-frames-192;
  -webkit-animation-duration: 35927ms;
          animation-duration: 35927ms;
  -webkit-animation-delay: 28403ms;
          animation-delay: 28403ms;
}
@-webkit-keyframes move-frames-192 {
  from {
    transform: translate3d(51vw, 108vh, 0);
  }
  to {
    transform: translate3d(82vw, -120vh, 0);
  }
}
@keyframes move-frames-192 {
  from {
    transform: translate3d(51vw, 108vh, 0);
  }
  to {
    transform: translate3d(82vw, -120vh, 0);
  }
}
.circle-container:nth-child(192) .circle {
  -webkit-animation-delay: 1682ms;
          animation-delay: 1682ms;
}
.circle-container:nth-child(193) {
  width: 2px;
  height: 2px;
  -webkit-animation-name: move-frames-193;
          animation-name: move-frames-193;
  -webkit-animation-duration: 33953ms;
          animation-duration: 33953ms;
  -webkit-animation-delay: 23166ms;
          animation-delay: 23166ms;
}
@-webkit-keyframes move-frames-193 {
  from {
    transform: translate3d(37vw, 107vh, 0);
  }
  to {
    transform: translate3d(69vw, -120vh, 0);
  }
}
@keyframes move-frames-193 {
  from {
    transform: translate3d(37vw, 107vh, 0);
  }
  to {
    transform: translate3d(69vw, -120vh, 0);
  }
}
.circle-container:nth-child(193) .circle {
  -webkit-animation-delay: 1410ms;
          animation-delay: 1410ms;
}
.circle-container:nth-child(194) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-194;
          animation-name: move-frames-194;
  -webkit-animation-duration: 34842ms;
          animation-duration: 34842ms;
  -webkit-animation-delay: 8590ms;
          animation-delay: 8590ms;
}
@-webkit-keyframes move-frames-194 {
  from {
    transform: translate3d(36vw, 110vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
@keyframes move-frames-194 {
  from {
    transform: translate3d(36vw, 110vh, 0);
  }
  to {
    transform: translate3d(59vw, -133vh, 0);
  }
}
.circle-container:nth-child(194) .circle {
  -webkit-animation-delay: 2118ms;
          animation-delay: 2118ms;
}
.circle-container:nth-child(195) {
  width: 5px;
  height: 5px;
  -webkit-animation-name: move-frames-195;
          animation-name: move-frames-195;
  -webkit-animation-duration: 29574ms;
          animation-duration: 29574ms;
  -webkit-animation-delay: 2506ms;
          animation-delay: 2506ms;
}
@-webkit-keyframes move-frames-195 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(21vw, -125vh, 0);
  }
}
@keyframes move-frames-195 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }
  to {
    transform: translate3d(21vw, -125vh, 0);
  }
}
.circle-container:nth-child(195) .circle {
  -webkit-animation-delay: 2245ms;
          animation-delay: 2245ms;
}
.circle-container:nth-child(196) {
  width: 7px;
  height: 7px;
  -webkit-animation-name: move-frames-196;
          animation-name: move-frames-196;
  -webkit-animation-duration: 31999ms;
          animation-duration: 31999ms;
  -webkit-animation-delay: 17148ms;
          animation-delay: 17148ms;
}
@-webkit-keyframes move-frames-196 {
  from {
    transform: translate3d(65vw, 110vh, 0);
  }
  to {
    transform: translate3d(32vw, -133vh, 0);
  }
}
@keyframes move-frames-196 {
  from {
    transform: translate3d(65vw, 110vh, 0);
  }
  to {
    transform: translate3d(32vw, -133vh, 0);
  }
}
.circle-container:nth-child(196) .circle {
  -webkit-animation-delay: 507ms;
          animation-delay: 507ms;
}
.circle-container:nth-child(197) {
  width: 3px;
  height: 3px;
  -webkit-animation-name: move-frames-197;
          animation-name: move-frames-197;
  -webkit-animation-duration: 28019ms;
          animation-duration: 28019ms;
  -webkit-animation-delay: 30775ms;
          animation-delay: 30775ms;
}
@-webkit-keyframes move-frames-197 {
  from {
    transform: translate3d(12vw, 103vh, 0);
  }
  to {
    transform: translate3d(23vw, -125vh, 0);
  }
}
@keyframes move-frames-197 {
  from {
    transform: translate3d(12vw, 103vh, 0);
  }
  to {
    transform: translate3d(23vw, -125vh, 0);
  }
}
.circle-container:nth-child(197) .circle {
  -webkit-animation-delay: 2935ms;
          animation-delay: 2935ms;
}
.circle-container:nth-child(198) {
  width: 1px;
  height: 1px;
  -webkit-animation-name: move-frames-198;
          animation-name: move-frames-198;
  -webkit-animation-duration: 29017ms;
          animation-duration: 29017ms;
  -webkit-animation-delay: 8183ms;
          animation-delay: 8183ms;
}
@-webkit-keyframes move-frames-198 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(18vw, -121vh, 0);
  }
}
@keyframes move-frames-198 {
  from {
    transform: translate3d(26vw, 106vh, 0);
  }
  to {
    transform: translate3d(18vw, -121vh, 0);
  }
}
.circle-container:nth-child(198) .circle {
  -webkit-animation-delay: 1155ms;
          animation-delay: 1155ms;
}
.circle-container:nth-child(199) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-199;
          animation-name: move-frames-199;
  -webkit-animation-duration: 36060ms;
          animation-duration: 36060ms;
  -webkit-animation-delay: 34040ms;
          animation-delay: 34040ms;
}
@-webkit-keyframes move-frames-199 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(60vw, -125vh, 0);
  }
}
@keyframes move-frames-199 {
  from {
    transform: translate3d(6vw, 105vh, 0);
  }
  to {
    transform: translate3d(60vw, -125vh, 0);
  }
}
.circle-container:nth-child(199) .circle {
  -webkit-animation-delay: 395ms;
          animation-delay: 395ms;
}
.circle-container:nth-child(200) {
  width: 8px;
  height: 8px;
  -webkit-animation-name: move-frames-200;
          animation-name: move-frames-200;
  -webkit-animation-duration: 35811ms;
          animation-duration: 35811ms;
  -webkit-animation-delay: 3623ms;
          animation-delay: 3623ms;
}
@-webkit-keyframes move-frames-200 {
  from {
    transform: translate3d(70vw, 110vh, 0);
  }
  to {
    transform: translate3d(98vw, -111vh, 0);
  }
}
@keyframes move-frames-200 {
  from {
    transform: translate3d(70vw, 110vh, 0);
  }
  to {
    transform: translate3d(98vw, -111vh, 0);
  }
}
.circle-container:nth-child(200) .circle {
  -webkit-animation-delay: 1153ms;
          animation-delay: 1153ms;
}
@-webkit-keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes message-frames {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.wallet_add {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #32a8df;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;
}
.after_login_dropdown {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  width: 100%;
}
.after_login_dropdown ul {
  list-style: none;
  padding: 0;
}
.after_login_dropdown ul li {
  color: #000;
  font-size: 14px;
  padding: 5px 0;
  font-weight: 500;
}
.after_login_dropdown ul li a {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
button.token_btn select.custom-select,
button.token_btn select.custom-select:focus {
  background: transparent url("../src/assets/images/dropdown_arrow.png") no-repeat right 0.75rem center !important;
  border: none;
  box-shadow: none;
  height: unset;
  padding-top: 0;
  padding-bottom: 0;
}
.short_address {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
}
.unlock_icon img {
  width: 30px;
}
.unlock_icon {
  width: 64px;
  border: 4px solid #32a8df;
  height: 64px;
  display: flex;
  background: #32a8df;
  box-shadow: rgb(9 22 46 / 10%) 0px 2px 5px;
  box-sizing: border-box;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  margin: 0 auto 15px;
}
button.primary_btn.blue_btn.Mui-disabled {
  color: #b7becb;
  background-color: #eff3fb;
  border-color: #eff3fb;
}
.p2p_order_tab {
  padding: 0;
}
.nav-tabs.p2p_order_tab .nav-item .nav-link { 
  text-transform: capitalize;
  font-size: 15px;
}
button.auth_btn.p2p_btn {
    margin-top: 15px;
    padding: 10px 50px;
}


/* Media Queries */
@media screen and (max-width: 1279px) {
  .home_page_menu ul {
    display: block;
  }
  .home_page_menu ul li {
    float: none;
  }
  .home_page_menu ul li a {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
  }
  .home_page_menu ul li a i {
    font-size: 24px;
  }
  .home_page_menu ul li a.active,
  .home_page_menu ul li a:hover {
    color: #32a8df;
  }
  li.menu_dropdown {
    padding: 8px 12px;
  }
  .theme_switch_btn {
    margin: 16px 12px;
  }
  .noti_ul button {
    padding: 12px 15px !important;
  }
  button.auth_btn {
    margin: 10px 16px;
  }
  .page-header {
    height: 100%;
    padding: 50px 0;
  }
  .sticky_left_nav {
    display: none;
  }
  .brand_logo_mobile {
    display: block;
  }
  .exchange_div::before,
  .liquidity_div::before {
    right: 0;
  }
  .whitebox.swap_box {
    width: 80%;
    margin: 30px auto;
  }
  .banner_ticker_panel .slick-prev {
    left: -20px
  }
  .banner_ticker_panel .slick-next {
    right: -20px;
  }
}
@media screen and (max-width: 1199px) {
  .banner_ticker_panel .slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .mobile_app_banner {
    margin-top: 80px;
  }
  .media{
    flex-wrap:wrap;
  }
}
@media screen and (max-width: 991px) {
  .list_view_single {
    display: block;
  }
  .list_view_single_first {
    margin-bottom: 10px;
    justify-content: center;
  }
  .list_view_single_third .accordian_link {
    justify-content: center;
  }
  .referral_steps h2 {
    padding: 0;
    font-size: 28px;
  }
  .referral_arrow {
    display: none;
  }
  .referral_steps_single h3 {
    font-size: 18px;
  }
  .referral_steps_single p {    
    font-size: 14px;
  }
  .getstarted_section h2 {
    font-size: 32px;
  }
}
@media screen and (max-width: 959px) {
  .exchange_div{
    width: 100%;
  }
  .whitebox.swap_box{
    width: 100%;
  }
  .features_section::before{
    display: none;
  }
  .page-header h1 {
    font-size: 36px;
  }
  .page-header p {
    margin-bottom: 30px;
  }
  .advantages_section_first {
    height: 100%;
  }
  .main_content {
    color: #202020;
  }
  .footer_top_home .subscribe_label {
    text-align: left;
    color: #fff;
    margin: 20px 0 10px;
  }
  .footer_social_links {
    text-align: left;
  }
  .footer_top_home h2 {
    margin: 15px 0 0;
  }
  .footer_menu_list li a {    
    display: inline-block;
  }
  .inner_vertical_menu_slider .slick-slide {
    float: left;
  }
  .inner_vertical_menu {
    padding: 0 40px;
    min-height: initial;
  }
  .inner_pageheader {
    min-height: initial;
  }
  .inner_vertical_menu.nav-pills .nav-link {
    padding: 15px 10px 15px 10px;    
    text-align: center;
  }  
  .footer_bottom_home {
    display: block;
  }
  .copyryt_txt {
    margin-top: 15px;
  }
  .pools_filter {
    display: block;
  }
  .pools_filter_left {
    margin-bottom: 15px;
    justify-content: center;
  }
  .list_view_single_card_body {
    display: block;
  }
  .list_view_single_card_body_left {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .features_section {
    text-align: center;
    overflow: hidden;
  }
  .feature_panel_single .media {
    display: block;
  }
  .feature_panel_single .media-body {
    padding: 10px;
  }
  .feature_panel_single .media img {
    margin-top: 15px;
  }  
  .main_title {
    font-size: 24px;
  }
  .rewards_section h2 {    
    font-size: 18px;
  }
  .page-header h1 {
    font-size: 26px;
    line-height: 36px;
  }
  .page-header h3 {
    font-size: 18px;
  }
  .banner_form_card .tab-content {
    padding: 25px 20px 20px;
  }
  .banner_form_card .card-body .nav-tabs {  
    padding-left: 20px;
  }
  .banner_form_card h2.card-title {    
    font-size: 20px;
    padding-left: 25px;
  }
  .history_table_filter {
    display: block;
  }
  .history_table_filter_left {
    display: block;
  }
  .search_grp_inp {
    width: 100%;
    margin: 15px 0;
  }  
  .input_group_prepend {
    top: 11px;
  }
  .launchPadCoinDetails {
		display: block;
	}
	.cdTeamDetail ul {
		flex-wrap: wrap;
	}
	.cdTeamDetail ul li {
		margin: 15px 0 0;
	}
	.social h4 {
		justify-content: center;
	}
	.launchPadCoinDetails h4 {
		font-size: 14px;		
		line-height: 22px;
		text-align: center;
	}
  .exchange_div::before,
  .liquidity_div::before {
    display: none;
  }
  .whitebox.swap_box {
    width: 100%;    
    margin: 0 auto !important;
  }
  .pools_filter_left {
    display: block;
    text-align: center;
  }
  .pools_filter_right {
    display: block;
    text-align: center;
  }
  .pools_filter_right .custom-select {
    margin:0 0 10px;
  }
  .pool_search {
    margin-bottom: 10px;
    height: 40px;
    width: 100%;
  }
  .view_btn_grp {
    justify-content: center;
  }
  .list_view_single_second {
    display: block;
  }
  .list_view_single_second > div {
    margin: 15px 0 0;
  }
  .list_view_single_first,
  .list_view_single_third .accordian_link {
    justify-content: flex-start;
  }
  .list_view_single_third {
    margin-top: 15px;
  }
  .list_view_single_card_body_right {
    display: block;
  }
  .list_view_start_stake {
    margin: 15px 0 0 0;
    width: 100%;
  }
  .list_view_single_card_body_right .input_panel div.d-flex:nth-child(2) {
    display: block !important;
  }
  .list_view_single_card_body_right button.harvest_btn {
    margin-top: 10px;
  }
  .inner_heading_wrapper h2 {
    font-size: 36px;
  }
  .inner_heading_wrapper p {
    font-size: 16px;
  }
  .total_referral {
    font-size: 36px;
  }
  .referral_steps_panel {
    justify-content: center;
  }
  .swap_info_end > div {
    display: block;
  }
  .starts_section .d-flex.align-items-center {
    display: block !important;
    text-align: center;
  }
  .starts_sec_btn {
    text-align: center;
    margin-top: 10px;
  }
  .starts_section h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .getstarted_section .d-flex {
    display: block !important;
  }
  .page-header .d-flex {
    display: block !important;
  }
  .banner_ticker_panel .slick-next {
    right: -10px;
  }
  .banner_ticker_panel .slick-prev {
    left: -10px;
  }
  .banner_ticker_single {
    width:100% !important;
  }
}
@media screen and (max-width: 575px) { 
  .history_table_div h2{
    padding-left: 20px;
  }
  .projectIntroduction li span {
		width: 100%;
		display: block;
	}
  .socialMediaCoinDetails ul {
		flex-wrap: wrap;
	}
	.socialMediaCoinDetails ul li {
		margin-top: 15px;
	}
  .whitebox > .d-flex {
    display: block !important;
  }
  .chart_btns button {
    padding: 5px 6px;
    font-size: 12px;
  }
  input.custom_inp {
    width: 140px;
  }
  .trade_wrap_title {
    display: block;
  }
  .trade_wrap_title > div {
    margin-bottom: 10px;
  }
  .trade_notes > div span {
    font-size: 13px;
  }
}
@media screen and (max-width:360px) {
  .history_table_div h2{
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
  }
 }
 

 .loading{  
  fill: #32a8df !important;
 }

 button.primary_btn.blue_btn {
  padding: 10px;
 }
 .butnchg {
  flex-wrap: wrap;
 }
 .loader{
  margin: 10px auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
 }
 .butnchg .loader{
  margin: 10px auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
 }
 .butnchg  button.primary_btn.blue_btn {
  width: 48%;
 }
 .tablechg{
  justify-content: space-between;
  width: 100%;
 }
 .headchg{
  position: relative;
 }
 .headchg .fa-arrow-left{
position: absolute;
color: #000;
cursor:pointer;
 }
 .headchg .importpoolh2{
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  color: #292f45;
  margin: 0;
  width: 100%;
  text-align: center;
 }
 .headchg .tooltip_icon{
  position: absolute;
  right:0;
 }
 .balancechg{
  margin-bottom: 0;
 }
.modalchg{
  padding: 20px;
}
.modalchg .arrow{  
  background: black;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  top:37%;
  left: 48%;
}
.modalchg  .modal-header h5{
  font-size: 24px;
}
.modalchg .modal-header .close{
  color: #000;
}
.modalchg  
.modalbelow{
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}
.modalchg  
.modalbelow .custome-swap-data{
margin: 8px 0;
}
.MuiSlider-markLabel:nth-child(1){
  left: 2% !important;
}
.slide{
  padding: 0 10px;
}
.closeicon{  
  background: #32a8df;
  border-radius: 2px;
}
.closeicon i{
  color: #fff;
}
.stake_btn_grps button {
  color: black;
  line-height: 15px;
  font-size: 30px;
  background-color: #c0e2f2;
  margin: 5px;
  padding: 10px;
  min-width: auto;
}
.stake_btn_grps button:hover {
  text-decoration: none;
  background-color: #c0e2f2;
}
 @media screen and (max-width: 574px)
{
  .headchg{
    display: flex !important;
  }
  .modalchg .modalbelow .custome-swap-data {
    margin: 8px 0;
    flex-direction: column;
    align-items: baseline !important;
}
}
 @media screen and (max-width:400px) {
  .butnchg {
    flex-direction: column;
   }
   .butnchg .spacing{
    margin-top: 15px;
   }
 }